import { LogOut, Settings, User } from 'react-feather'
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  UncontrolledDropdown,
} from 'reactstrap'
import { Link } from 'react-router-dom'
import { persistor } from 'app/store'

const UserDropdown = (args) => {
  return (
    <Nav className="nav navbar-nav align-items-center ml-auto">
      <UncontrolledDropdown tag="li" className="me-2" direction="down">
        <DropdownToggle
          href="/"
          tag="a"
          className="nav-link dropdown-user-link"
          onClick={(e) => e.preventDefault()}
        >
          {args?.profile?.isSuccess && (
            <div
              className="rounded-circle flex-centered"
              style={{
                width: '40px',
                height: '40px',
                backgroundColor: '#ECFAFF',
              }}
            >
              <span>
                {args?.profile?.data?.makerData?.profilepicUrl !== null ? (
                  <img
                    src={args?.profile?.data?.makerData?.profilepicUrl}
                    alt="user avatar"
                    className="rounded-circle"
                    width={39}
                    height={39}
                  />
                ) : (
                  <span>
                    <User size={25} className="text-primary" />
                  </span>
                )}
              </span>
            </div>
          )}
        </DropdownToggle>

        <DropdownMenu>
          <DropdownItem tag={Link} to="/pengaturan-profile">
            <Settings size={14} className="me-2" />
            <span className="align-middle">Pengaturan</span>
          </DropdownItem>

          <DropdownItem
            tag="a"
            href="/login"
            onClick={() => {
              persistor.purge()
            }}
          >
            <LogOut size={14} className="me-2" />
            <span className="align-middle">Logout</span>
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </Nav>
  )
}

export default UserDropdown
