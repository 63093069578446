import ICDefaultPhoto from 'assets/icons/default-ava-profile-user.svg'
import ICTransaction from 'assets/icons/icon-transaksi.svg'
import SidebarDetails from 'components/SidebarDetails'
import moment from 'moment-timezone'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import {
  Button,
  Card,
  CardBody,
  CardText,
  Collapse,
  Container,
  Input,
} from 'reactstrap'

import ICThunder from 'assets/icons/thunder-icon.svg'
import ICPhone from 'assets/icons/ic-mobile.png'
import ICUangBelanja from 'assets/icons/uang-belanja.png'
import ICUangUsaha from 'assets/icons/uang-usaha.png'
import RoundedComponent from 'components/Rounded'
import { setTotal } from 'private-views/transactions/commodityPurchaseSlice'
import { ChevronDown, ChevronUp } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ModalInfo from '../ModalDashboard'
import {
  getDetailProfile,
  getPricing,
  postConfirmTransaction,
} from '../dashboardAction'
import {
  setBannerType,
  setMessageBanner,
  toggleBanner,
} from '../dashboardSlice'
import './style.scss'

const SidebarDashboard = (props) => {
  const {
    isOpen,
    toggle,
    toggleModal,
    row,
    isOpenModal,
    handleCallback,
    options,
  } = props
  const { role } = useSelector((s) => s.auth)
  const { pricing, detail, balance } = useSelector((s) => s.dashboard)
  const intl = useIntl()
  const dispatch = useDispatch()
  const [isInstant, setIsInstant] = useState(false)
  const [selectedPricing, setSelectedPricing] = useState(4)
  const [isOpenSidebar, setIsOpenSidebar] = useState(false)
  const [isOpenCollapse, setIsOpenCollapse] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [errMsg, setErrMsg] = useState('')
  const toggleCollapse = () => {
    setIsOpenCollapse(!isOpenCollapse)
  }
  const toggleSidebar = () => {
    setIsOpenSidebar(!isOpenSidebar)
  }
  const navigate = useNavigate()

  const handleConfirm = async () => {
    try {
      setIsLoading(true)
      const obj = {
        escrowPaymentId: row?.escrowpaymentId,
        useDisbursementpricingId: selectedPricing,
      }

      const res = await dispatch(postConfirmTransaction(obj))
      if (res.payload.status === 200) {
        toggle()
        toggleModal()
        handleCallback()
        navigate('/')
        dispatch(setBannerType('success'))
        dispatch(
          setMessageBanner(
            `Konfirmasi diterima. Pembelian ke ${row?.sellerDetail?.fullName} telah selesai.`,
          ),
        )
        dispatch(toggleBanner())
      } else {
        throw res
      }
    } catch (error) {
      console.log(error)
      setIsLoading(false)
      setErrMsg(error?.payload)
    } finally {
      setIsLoading(false)
    }
  }

  const handleIsInstant = () => {
    setIsInstant(!isInstant)
    setSelectedPricing({})
  }

  useEffect(() => {
    if (isOpen && row?.status === 'Diproses') {
      dispatch(getPricing({ escrowpaymentId: row?.escrowpaymentId }))
    }

    if (row && isOpen) {
      dispatch(getDetailProfile({ id: row?.id, type: row?.orderDetail?.type }))
    }
  }, [isOpen, row, dispatch])

  useEffect(() => {
    if (!isOpen) {
      setIsInstant(false)
      setSelectedPricing({})
      setIsOpenCollapse(false)
      setErrMsg('')
    }
    if (!isOpenModal) {
      setErrMsg('')
    }
  }, [isOpen, isOpenModal])

  return (
    <>
      <ModalInfo
        isOpen={isOpenModal}
        toggle={toggleModal}
        toggleSidebar={toggleSidebar}
        handleConfirm={handleConfirm}
        errMsg={errMsg}
        isLoading={isLoading}
      />
      <SidebarDetails
        width={400}
        open={isOpen}
        title="Data Transaksi"
        headerClassName="mb-1 justify-content-between"
        contentClassName="pt-0 w-100"
        toggleSidebar={toggle}
      >
        <Container className="h-100 w-100">
          {/* PO */}
          {detail?.items?.[0]?.orderDetail?.poNumber && (
            <Card className="mb-3">
              <CardBody>
                <span className="fw-bold flex-centered">
                  {detail?.items?.[0]?.orderDetail?.poNumber}
                </span>
              </CardBody>
            </Card>
          )}
          {/* Alur Transaksi */}

          <Card
            onClick={() => {
              toggleCollapse()
            }}
            body
            className="mb-3"
          >
            <div className="d-flex gap-2 flex-row align-items-center justify-content-between">
              <div className="d-flex flex-row gap-2">
                <div className="flex-centered justify-content-start gap-1 align-items-center">
                  <img src={ICTransaction} alt="ava" width={40} height={40} />
                </div>
                <div>
                  <CardText tag="h6" className="fw-bold text-primary">
                    Alur Transaksi
                  </CardText>
                  <CardText tag="p" className="m-0 fw-light">
                    Pembeli membuat transaksi:
                  </CardText>
                  <CardText className="fw-light" tag="p">
                    {`(${moment(detail?.items?.[0]?.createdAt).format('Do MMMM YYYY, hh:mm')})`}
                  </CardText>
                </div>
              </div>
              {detail?.items?.[0]?.history &&
                (isOpenCollapse ? (
                  <ChevronUp size={15} />
                ) : (
                  <ChevronDown size={15} />
                ))}
            </div>
            {detail?.items?.[0]?.history && (
              <Collapse isOpen={isOpenCollapse}>
                <CardBody>
                  <div>
                    {detail?.items?.[0]?.history?.createdDate && (
                      <div className="d-flex flex-row justify-content-start align-items-center gap-2">
                        <RoundedComponent
                          childern="1"
                          style={{
                            backgroundColor: '#C0E5F2',
                            width: '20px',
                            height: '20px',
                          }}
                        />
                        <div className="d-flex flex-column">
                          <span>Pesanan dibuat oleh pembeli</span>
                          <small className="text-secondary">{`(${moment(detail?.items?.[0]?.history?.createdDate).format('Do MMMM YYYY, hh:mm')})`}</small>
                        </div>
                      </div>
                    )}
                    {detail?.items?.[0]?.history?.sellerConfirmDate && (
                      <div className="d-flex flex-row justify-content-start align-items-center gap-2">
                        <RoundedComponent
                          childern="2"
                          style={{
                            backgroundColor: '#C0E5F2',
                            width: '20px',
                            height: '20px',
                          }}
                        />
                        <div className="d-flex flex-column">
                          <span>Pesanan diproses oleh penjual</span>
                          <small className="text-secondary">{`(${moment(detail?.items?.[0]?.history?.sellerConfirmDate).format('Do MMMM YYYY, hh:mm')})`}</small>
                        </div>
                      </div>
                    )}
                    {detail?.items?.[0]?.history?.releaseDate && (
                      <>
                        <div className="d-flex flex-row justify-content-start align-items-center gap-2">
                          <RoundedComponent
                            childern="3"
                            style={{
                              backgroundColor: '#C0E5F2',
                              width: '20px',
                              height: '20px',
                            }}
                          />
                          <div className="d-flex flex-column">
                            <span>Pembeli konfirmasi barang sampai</span>
                            <small className="text-secondary">{`(${moment(detail?.items?.[0]?.history?.releaseDate).format('Do MMMM YYYY, hh:mm')})`}</small>
                          </div>
                        </div>
                        <div className="d-flex flex-row justify-content-start align-items-center gap-2">
                          <RoundedComponent
                            childern="4"
                            style={{
                              backgroundColor: '#C0E5F2',
                              width: '20px',
                              height: '20px',
                            }}
                          />
                          <div className="d-flex flex-column">
                            <span>Transaksi selesai</span>
                            <small className="text-secondary">{`(${moment(detail?.items?.[0]?.history?.updatedAt).format('Do MMMM YYYY, hh:mm')})`}</small>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </CardBody>
              </Collapse>
            )}
          </Card>

          {/* STATUS */}
          {!role === 'Staff' &&
            detail?.items?.[0]?.status === 'Menunggu Pembayaran Pembeli' && (
              <div className="badge-warning p-3 text-center mb-3 d-block w-100">
                {row.status}
              </div>
            )}

          {role === 'Staff' &&
            detail?.items?.[0]?.status === 'Menunggu Pembayaran Pembeli' &&
            detail?.items?.[0].buyerDetail.isLogin && (
              <div className="badge-warning p-3 text-center mb-3 d-block w-100">
                {row.status}
              </div>
            )}
          {/* STATUS */}
          {(role === 'Super Admin' || role === 'Super Admin') &&
            (detail?.items?.[0]?.status === 'Menunggu Pembayaran Pembeli' ||
              detail?.items?.[0]?.status.toLowerCase() === 'diproses') && (
              <div className="badge-warning p-3 text-center mb-3 d-block w-100">
                {row.status}
              </div>
            )}

          {detail?.items?.[0]?.status === 'Menunggu Proses Penjual' && (
            <div className="badge-warning p-3 text-center mb-3 d-block w-100">
              {row.status}
            </div>
          )}
          {detail?.items?.[0]?.status === 'Dibatalkan' && (
            <div className="badge-warning p-3 text-center mb-3 d-block w-100">
              {row.status}
            </div>
          )}
          {detail?.items?.[0]?.status === 'Menunggu Approval Admin' && (
            <div className="badge-warning p-3 text-center mb-3 d-block w-100">
              {row.status}
            </div>
          )}
          {/* penjual */}
          <Card body className="mb-3">
            <CardText tag="h6" className="fw-bold">
              {options?.type === 'SELL' ? 'Pembeli' : 'Penjual'}
            </CardText>
            <div className="flex-centered justify-content-start gap-1">
              {options?.type === 'BUY' && (
                <>
                  <img
                    src={row?.sellerOrBuyAvatar ?? ICDefaultPhoto}
                    className="rounded-circle"
                    alt="pak tani"
                    width={32}
                    height={32}
                  />
                  <span>{row.sellerDetail?.fullName}</span>
                </>
              )}
              {options?.type === 'SELL' && (
                <>
                  <img
                    src={row?.sellerOrBuyAvatar ?? ICDefaultPhoto}
                    className="rounded-circle"
                    alt="pak tani"
                    width={32}
                    height={32}
                  />
                  <span>{row.buyerDetail?.fullName}</span>
                </>
              )}
            </div>
          </Card>

          {/* Rincian Pesanan */}
          <Card body className="mb-3">
            <CardText tag="h6" className="fw-bold">
              Rincian Pesanan
            </CardText>
            <div className="gap-1">
              {detail?.items?.[0]?.itemDetail?.map((e, i) => {
                return (
                  <div
                    key={i}
                    className="d-flex justify-content-between align-items-center"
                  >
                    <div className="d-flex flex-column mb-2">
                      <span>{e?.nameItem}</span>
                      <span>
                        <span className="fw-light">
                          {intl.formatNumber(e?.amount, {
                            useGrouping: 'always',
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          })}{' '}
                          {e?.unit} •{' '}
                        </span>{' '}
                        <span className="text-primary">
                          Rp{' '}
                          {intl.formatNumber(e?.price, {
                            useGrouping: 'always',
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          })}
                        </span>
                      </span>
                    </div>
                    <span>
                      Rp
                      {intl.formatNumber(e?.price * e?.amount ?? 0, {
                        useGrouping: 'always',
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}
                    </span>
                  </div>
                )
              })}
              <hr></hr>
              <div className="d-flex justify-content-between align-items-center">
                <span className="fw-bold">Total yang dibayarkan</span>
                <span className="fw-bold">
                  Rp
                  {intl.formatNumber(detail?.items?.[0]?.totalAmount, {
                    useGrouping: 'always',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                </span>
              </div>
            </div>
          </Card>

          {/* Pembayaran */}
          {detail?.items?.[0]?.escrowpayment?.status === 'ON_GOING' &&
            detail?.items?.[0]?.orderDetail?.type === 'BUY' && (
              <Card body className="mb-3">
                <div className="d-flex justify-content-between">
                  <CardText tag="h6" className="fw-bold">
                    Pembayaran Instan
                  </CardText>
                  <Input onChange={handleIsInstant} type="checkbox" />
                </div>
                <div className="flex-centered justify-content-between gap-1">
                  {isInstant && (
                    <div className="d-flex flex-column gap-3">
                      {pricing?.items?.map((e, i) => {
                        if (e?.fee > 0) {
                          return (
                            <div key={i}>
                              <Card
                                style={{
                                  backgroundColor:
                                    selectedPricing === e?.id
                                      ? '#ECFAFF'
                                      : e?.disable
                                        ? '#F9FAFB'
                                        : '',
                                }}
                                className={
                                  selectedPricing === e?.id
                                    ? 'border-primary'
                                    : e?.disable
                                      ? 'opacity-50'
                                      : ''
                                }
                                onClick={() => {
                                  if (!e?.disable) {
                                    setSelectedPricing(e?.id)
                                    setErrMsg('')
                                  }
                                }}
                              >
                                <CardBody>
                                  <div className="d-flex flex-row gap-2">
                                    <div>
                                      <img src={ICThunder} alt="kilat" />
                                    </div>
                                    <div>
                                      <span style={{ fontWeight: '500' }}>
                                        Instan {e?.title} (Rp{' '}
                                        {intl.formatNumber(e?.fee, {
                                          useGrouping: 'always',
                                          minimumFractionDigits: 0,
                                          maximumFractionDigits: 0,
                                        })}
                                        )
                                      </span>
                                      <p
                                        style={{
                                          color:
                                            selectedPricing === e?.id
                                              ? '#006386'
                                              : '',
                                        }}
                                      >
                                        {e?.description}
                                      </p>
                                    </div>
                                    <Input
                                      type="checkbox"
                                      disabled={e?.disable}
                                      checked={selectedPricing === e?.id}
                                    />
                                  </div>
                                </CardBody>
                              </Card>
                              {e?.disable ? (
                                <span className="text-danger">
                                  {e?.disableReason}
                                </span>
                              ) : (
                                ''
                              )}
                            </div>
                          )
                        }
                        return <></>
                      })}
                    </div>
                  )}
                </div>
              </Card>
            )}

          {/* Details Dibuat oleh */}
          <Card body className="mb-3">
            <CardText tag="h6" className="fw-bold">
              Dibuat Oleh
            </CardText>
            <div className="flex-centered justify-content-between gap-1">
              <div className="d-flex gap-2 align-items-center">
                <img
                  src={row?.requesterAvatar ?? ICDefaultPhoto}
                  alt="ava"
                  width={32}
                  height={32}
                  className="rounded-circle"
                />
                <span>
                  {row?.requesterName ?? row?.orderDetail?.type === 'BUY'
                    ? row?.buyerDetail?.fullName
                    : row?.sellerDetail?.fullName}
                </span>
              </div>
              {detail?.items?.[0]?.orderDetail?.source ? (
                <></>
              ) : (
                <img src={ICPhone} alt="Mobile" width={24} height={24} />
              )}
            </div>
          </Card>

          {/* Details Dibuat Approve oleh */}

          {/* Approval Detail  */}
          {row?.pmbmakerchecker?.checkerName && (
            <Card body className="mb-3">
              <CardText tag="h6" className="fw-bold text-success">
                Di-approve Oleh
              </CardText>
              <div className="flex-centered justify-content-start gap-1">
                <img
                  src={row?.checkerAvatar ?? ICDefaultPhoto}
                  alt="ava"
                  width={32}
                  height={32}
                  className="rounded-circle"
                />
                <span>{row?.pmbmakerchecker?.checkerName}</span>
              </div>
            </Card>
          )}

          {/* Metode Pembayaran */}
          {detail?.items?.[0].escrowpayment ? (
            <Card className="mb-3">
              <CardBody>
                <CardText tag="h6" className="fw-bold">
                  Metode Pembayaran
                </CardText>
                <div className="d-flex justify-content-center align-items-center flex-wrap">
                  {detail?.items?.[0]?.credit?.upfront ? (
                    <div
                      className="rounded p-2 d-flex w-100 gap-2"
                      style={{ backgroundColor: '#ECF9FE' }}
                    >
                      <img
                        style={{ width: '24px', height: '24px' }}
                        alt="Uang Belanja"
                        src={ICUangBelanja}
                      />
                      <span className="fw-bold">Uang Belanja</span>
                    </div>
                  ) : (
                    <></>
                  )}
                  {detail?.items?.[0]?.credit?.upfront &&
                  detail?.items?.[0].escrowpayment?.userpinjamanId ? (
                    <span>+</span>
                  ) : (
                    <></>
                  )}
                  {detail?.items?.[0].escrowpayment?.userpinjamanId ? (
                    <div
                      className="rounded p-2 d-flex w-100 gap-2"
                      style={{ backgroundColor: '#FFFAEB' }}
                    >
                      <img
                        style={{ width: '24px', height: '24px' }}
                        alt="Uang Usaha"
                        src={ICUangUsaha}
                      />
                      <span className="fw-bold">Talangin</span>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </CardBody>
            </Card>
          ) : (
            <></>
          )}

          {/* Start Tombol Lanjut Pembayaran  */}
          {role === 'Staff' &&
            detail?.items?.[0]?.status === 'Menunggu Pembayaran Pembeli' &&
            !detail?.items?.[0].escrowpaymentId &&
            detail?.items?.[0].orderDetail.type === 'BUY' && (
              <Button
                block
                color="primary"
                size="sm"
                onClick={() => {
                  let dataComodities = []
                  detail.items.forEach((e) => {
                    e.itemDetail.forEach((i) => {
                      dataComodities.push({
                        comodity: {
                          value: i.id,
                          label: i.nameItem,
                          id: i.id,
                          img: i.imageUrl,
                          sku: i.SKU,
                        },
                        totalPrice:
                          i.totalPrice ??
                          parseInt(i.price) * parseInt(i.amount),
                        quantity: i.amount,
                        unit: {
                          value: i.unit,
                          label: i.unit,
                        },
                        price: i.price,
                      })
                    })
                  })

                  localStorage.setItem(
                    'userData',
                    JSON.stringify({
                      dataComodities: dataComodities,
                      dataSeller: detail.items[0].sellerDetail,
                      talangin: balance.talangin,
                      wallet1: balance.uangBelanja,
                      wallet2: balance.uangUsaha,
                      trxId: detail.items[0].id,
                    }),
                  )

                  dispatch(
                    setTotal(
                      dataComodities?.reduce((sum, a) => sum + a.totalPrice, 0),
                    ),
                  )

                  navigate('/transaksi-pembelian-pembayaran')
                }}
              >
                Lanjut Pembayaran
              </Button>
            )}
          {/* End Tombol Lanjut Pembayaran  */}

          {detail?.items?.[0]?.escrowpayment?.status === 'ON_GOING' &&
            detail?.items?.[0]?.orderDetail?.type === 'BUY' && (
              <div className="d-flex justify-content-between gap-3 w-100">
                <Button
                  block
                  color="primary"
                  size="sm"
                  outline
                  onClick={toggle}
                >
                  Penyesuaian Pesanan
                </Button>
                <Button
                  block
                  color="primary"
                  size="sm"
                  onClick={() => {
                    toggleModal()
                  }}
                >
                  Konfirmasi
                </Button>
              </div>
            )}
        </Container>
      </SidebarDetails>
    </>
  )
}

export default SidebarDashboard
