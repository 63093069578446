//available subjects
export const BUSINESS_ALL = 'business_all'
export const BUSINESS_MYAPPROVAL = 'business_myapproval' // approval
export const BUSINESS_COMPANY = 'business_company' // setting
export const BUSINESS_WITHDRAWAL = 'business_withdrawal' // withdrawal
export const BUSINESS_TRANSACTION_PURCHASE = 'business_transaction_purchase' // trxBuy
export const BUSINESS_TRANSACTION_SELL = 'business_transaction_sell' // trxSell
export const BUSINESS_TOPUP = 'business_topup' // topUp
export const BUSINESS_TALANGIN = 'business_talangin' // talanginInvoice
export const BUSINESS_FARMERS = 'business_farmers' // farmers

export const ALL = 'all'
export const ABILITIES = [
  ALL,
  BUSINESS_COMPANY,
  BUSINESS_WITHDRAWAL,
  BUSINESS_TRANSACTION_PURCHASE,
  BUSINESS_TRANSACTION_SELL,
  BUSINESS_TOPUP,
  BUSINESS_TALANGIN,
  BUSINESS_FARMERS,
]

// Available actions
export const READ = 'read'
export const UPDATE = 'update'
export const MANAGE = 'manage'
export const MAKE = 'make'
export const CREATE = 'create'

export const ACTIONS = [READ, UPDATE, MANAGE, MAKE]

// Pages private use value snake case ^^
export const APPROVAL_SAYA = 'approval_saya'
export const FORGET_PASSWORD = 'forget_password'
