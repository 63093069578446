import BcaLogo from 'assets/images/BCA-01.png'
import BriLogo from 'assets/images/BRI-01.png'
import AmountInput from 'components/AmountInput'
import LoadingAnimation from 'components/LoadingAnimation'
import { useEffect, useState } from 'react'
import { AlertCircle } from 'react-feather'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Row,
} from 'reactstrap'
import {
  setBannerType,
  setMessageBanner,
  toggleBanner,
} from '../dashboard/dashboardSlice'
import {
  useFetchTopUpQuery,
  usePostBcaUniqueCodeMutation,
  usePostBcaVaMutation,
  usePostBriVaMutation,
  usePostDurianPayMutation,
} from './topupApiSlice'
import { useAbilityAccess } from 'hooks/useAbilityAccess'
import { URL_406 } from 'utils/pathUrl'
import { pendingBca, pendingBcaVa, pendingBri } from './topupAction'

const Topup = ({ action, subject }) => {
  const abilityManage = useAbilityAccess({ action, subject })

  const navigate = useNavigate()
  const location = useLocation()
  const intl = useIntl()
  const dispatch = useDispatch()
  const dataState = location.state
  const [topupMethod, setTopupMethod] = useState('')
  const [loading, setLoading] = useState(false)
  const [errMsg, setErrMsg] = useState('')
  const [amount, setAmount] = useState(0)
  const [isAmountChanged, setIsAmountChanged] = useState(false)
  const [isModalShow, setIsModalShow] = useState(false)
  const { data, isSuccess } = useFetchTopUpQuery()
  const [postDurianPay, result] = usePostDurianPayMutation()
  const [postBcaUniqueCode, resultBcaUc] = usePostBcaUniqueCodeMutation()
  const [postBriVa, resultBriVa] = usePostBriVaMutation()
  const [postBcaVa, resultBcaVa] = usePostBcaVaMutation()
  const { pendingBcaData, pendingBriData, pendingBcaVaData, isLoading } =
    useSelector((s) => s.topup)

  const handleChangePayMethod = (value) => {
    setTopupMethod(value)
    setErrMsg('')
  }

  const handleTotalAmount = (amount) => {
    setAmount(amount)
    if (
      Number(
        amount?.replace?.(/\./g, '')?.replace?.(/\,/g, '.')?.slice?.(0, 15),
      ) > 0
    ) {
      setIsAmountChanged(true)
    } else {
      setIsAmountChanged(false)
    }
  }

  /* global Durianpay */
  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      setLoading(true)
      let obj = {
        amount: Number(
          amount?.replace?.(/\./g, '')?.replace?.(/\,/g, '.')?.slice?.(0, 15),
        ),
      }
      if (topupMethod === 'dpVa') {
        obj = {
          ...obj,
          gateway: 'DURIANPAY-PAYMENTLINK',
        }
        const res = await postDurianPay(obj).unwrap()
        if (res) {
          const data = JSON.parse(res?.dumpRequest)?.data
          const script = document.createElement('script')
          script.src = 'https://js.durianpay.id/0.1.48/durianpay.min.js'
          script.async = true
          document.body.appendChild(script)

          script.onload = () => {
            const dpay = Durianpay.init({
              name: 'dpaysdk',
              environment: 'production',
              locale: 'id',
              access_key: data.access_token,
              method: 'getCheckoutButton',
              redirect_url: dataState?.trx
                ? `${window.location.origin}/transaksi-pembelian-pembayaran`
                : dataState?.talangin
                  ? `${window.location.origin}/transaksi-pembelian-pembayaran-talangin`
                  : window.location.origin,
              onSuccess: () => {
                if (!dataState) {
                  setLoading(false)
                  navigate('/')
                  dispatch(setBannerType('success'))
                  dispatch(
                    setMessageBanner(
                      `Uang Belanja sebesar Rp${amount} berhasil ditambahkan.`,
                    ),
                  )
                  dispatch(toggleBanner())
                }
              },

              onFailure: () => {
                if (!dataState) {
                  setLoading(false)
                  navigate('/')
                  dispatch(setBannerType('danger'))
                  dispatch(
                    setMessageBanner(
                      `Uang Belanja sebesar Rp${amount} gagal ditambahkan.`,
                    ),
                  )
                  dispatch(toggleBanner())
                }
              },

              onClose: () => {
                if (!dataState) {
                  setLoading(false)
                  navigate('/')
                }
              },

              container_elem: 'pay-btn-container',
              order_info: {
                id: data.id,
                customer_info: {
                  id: data.customer_id,
                },
              },
              btn_class_name: 'btn filled',
            })

            dpay.getCheckoutButton()
            document
              .getElementById('dpay-checkout-btn')
              .setAttribute('hidden', 'true')
            document.getElementById('dpay-checkout-btn').click()
          }

          // window.open(res?.paymentLink, '_blank').focus()
          // navigate('/')
        }
      } else if (topupMethod === 'bcaUc') {
        const res = await postBcaUniqueCode(obj).unwrap()
        if (res.status === 'PENDING') {
          navigate('/topup-instruksi', {
            state: {
              ...res,
              ...dataState,
            },
          })
        }
      } else if (topupMethod === 'briVa') {
        const res = await postBriVa(obj).unwrap()
        if (res.status === 'PENDING') {
          navigate('/topup-instruksi', {
            state: {
              ...res,
              ...dataState,
              bankCodeReceiver: 'BRI',
              isVa: true,
              amount: Number(
                amount
                  ?.replace?.(/\./g, '')
                  ?.replace?.(/\,/g, '.')
                  ?.slice?.(0, 15),
              ),
            },
          })
        }
      } else if (topupMethod === 'bcaVa') {
        const res = await postBcaVa(obj).unwrap()
        if (res.status === 'PENDING') {
          navigate('/topup-instruksi', {
            state: {
              ...res,
              ...dataState,
              bankCodeReceiver: 'BCA',
              isVa: true,
              amount: Number(
                amount
                  ?.replace?.(/\./g, '')
                  ?.replace?.(/\,/g, '.')
                  ?.slice?.(0, 15),
              ),
            },
          })
        }
      }
    } catch (error) {
      console.log(error)
      setErrMsg(error?.data?.message)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (pendingBcaData || pendingBriData || pendingBcaVaData) {
      navigate('/topup-instruksi', {
        state: pendingBcaData
          ? { ...pendingBcaData }
          : pendingBriData
            ? { ...pendingBriData, isVa: true, bankCodeReceiver: 'BRI' }
            : { ...pendingBcaVaData, isVa: true, bankCodeReceiver: 'BCA' },
      })
    }
  }, [dataState, pendingBcaData, pendingBriData, pendingBcaVaData])

  useEffect(() => {
    dispatch(pendingBca())
    dispatch(pendingBri())
    dispatch(pendingBcaVa())
  }, [])

  return (
    <>
      <Container className="custom-container pb-5 mt-4">
        <Card className="w-100">
          <CardBody>
            <CardTitle className="fw-bold">Tambah Uang Belanja</CardTitle>
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <Label>Jumlah</Label>
                <InputGroup>
                  <InputGroupText className="bg-transparent">Rp</InputGroupText>
                  <AmountInput
                    placeholder="Masukkan jumlah topup"
                    name="amountInput"
                    id="amountInput"
                    max={100000000000}
                    cb={handleTotalAmount}
                  />
                </InputGroup>
              </FormGroup>
              {isAmountChanged &&
                Number(
                  amount
                    ?.replace?.(/\./g, '')
                    ?.replace?.(/\,/g, '.')
                    ?.slice?.(0, 15),
                ) < 10000 && (
                  <FormGroup>
                    <small className="text-danger text-center">
                      <AlertCircle size={15} /> Minimum topup sebesar Rp 10.000
                    </small>
                  </FormGroup>
                )}

              <CardTitle className="fw-bold">Pilih metode pembayaran</CardTitle>
              <FormGroup>
                <Label>Transfer bank</Label>
                <div>
                  <div
                    style={{
                      backgroundColor: topupMethod === 'bcaUc' ? '#ECFAFF' : '',
                    }}
                    className={`border d-flex flex-row justify-content-between align-items-center p-3 rounded mb-3 ${
                      topupMethod === 'bcaUc' ? 'border-primary' : ''
                    }`}
                    onClick={() => {
                      handleChangePayMethod('bcaUc')
                    }}
                  >
                    <img
                      style={{ height: '20px', width: '40px' }}
                      src={BcaLogo}
                      alt="bca"
                    />
                    <Input
                      type="checkbox"
                      checked={topupMethod === 'bcaUc'}
                      onChange={() => handleChangePayMethod('bcaUc')}
                    />
                  </div>
                </div>
              </FormGroup>
              <FormGroup>
                <Label>Virtual Account</Label>
                <div
                  style={{
                    backgroundColor: topupMethod === 'briVa' ? '#ECFAFF' : '',
                  }}
                  className={`border d-flex flex-row justify-content-between align-items-center p-3 rounded mb-3 ${
                    topupMethod === 'briVa' ? 'border-primary' : ''
                  }`}
                  onClick={() => {
                    handleChangePayMethod('briVa')
                  }}
                >
                  <img
                    style={{ height: '20px', width: '40px' }}
                    src={BriLogo}
                    alt="bri"
                  />
                  <Input
                    type="checkbox"
                    checked={topupMethod === 'briVa'}
                    onChange={() => {
                      handleChangePayMethod('briVa')
                    }}
                  />
                </div>
                <div
                  style={{
                    backgroundColor: topupMethod === 'bcaVa' ? '#ECFAFF' : '',
                  }}
                  className={`border d-flex flex-row justify-content-between align-items-center p-3 rounded mb-3 ${
                    topupMethod === 'bcaVa' ? 'border-primary' : ''
                  }`}
                  onClick={() => {
                    handleChangePayMethod('bcaVa')
                  }}
                >
                  <img
                    style={{ height: '20px', width: '40px' }}
                    src={BcaLogo}
                    alt="bca"
                  />
                  <Input
                    type="checkbox"
                    checked={topupMethod === 'bcaVa'}
                    onChange={() => {
                      handleChangePayMethod('bcaVa')
                    }}
                  />
                </div>
                <div
                  style={{
                    backgroundColor: topupMethod === 'dpVa' ? '#ECFAFF' : '',
                  }}
                  className={`border d-flex flex-row justify-content-between align-items-center p-3 rounded mb-3 ${
                    topupMethod === 'dpVa' ? 'border-primary' : ''
                  }`}
                  onClick={() => {
                    handleChangePayMethod('dpVa')
                  }}
                >
                  <div>
                    <div className="d-flex flex-column">
                      <span className="fw-bold">Bank Lainnya</span>
                      <span className="fw-light">Bank yang tersedia:</span>
                    </div>
                    <Row className="d-flex gap-1">
                      {data?.rows?.map((e, i) => {
                        return (
                          <img
                            key={i}
                            style={{ height: '15%', width: '15%' }}
                            src={e?.logo}
                            alt="durianbanks"
                          />
                        )
                      })}
                    </Row>
                  </div>
                  <Input
                    type="checkbox"
                    checked={topupMethod === 'dpVa'}
                    onChange={() => handleChangePayMethod('dpVa')}
                  />
                </div>

                {errMsg && (
                  <FormGroup>
                    <small className="text-danger">
                      <AlertCircle size={15} /> {errMsg}
                    </small>
                  </FormGroup>
                )}
              </FormGroup>
              <Button
                type="submit"
                disabled={
                  !topupMethod ||
                  loading ||
                  Number(
                    amount
                      ?.replace?.(/\./g, '')
                      ?.replace?.(/\,/g, '.')
                      ?.slice?.(0, 15),
                  ) < 10000
                }
                block
                color="primary"
              >
                {loading ? (
                  <LoadingAnimation className="spinner-border spinner-border-sm" />
                ) : (
                  'Lanjutkan Pembayaran'
                )}
              </Button>
            </Form>
          </CardBody>
        </Card>
      </Container>
    </>
  )
}

export default Topup
