import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { Navbar, NavbarBrand } from 'reactstrap'
import PmgLogo from '../../assets/images/pmg-logo-beta.png'
import UserDropdown from './UserDropdown'

const NavbarDashboard = (args) => {
  const dispatch = useDispatch()

  // const { data, isLoading, isSuccess } = useFetchApprovalCountQuery()

  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(!isOpen)

  // useEffect(() => {
  //   if (isSuccess) dispatch(setCountTag(data?.data))
  // }, [dispatch, data, isSuccess])

  return (
    <>
      <Navbar {...args}>
        <NavbarBrand tag={Link} to="/">
          <img
            alt="PasarMIKRO logo"
            src={PmgLogo}
            style={{ width: '100%', maxWidth: '177px', height: 'auto' }}
          />
        </NavbarBrand>
        {/* <NavbarToggler onClick={toggle} children={<AlignLeft />} /> */}
        <div className="d-flex justify-content-end">
          {/* <Nav className="me-auto" navbar>
            {privateRouter.map((item, idx) => (
              <Can I={item.action} this={item.subject} key={idx}>
                <NavItem className="d-flex align-items-center">
                  <NavLinkDom
                    to={item.path}
                    aria-current="page"
                    className={({ isActive }) => {
                      if (isActive) {
                        return `nav-link active-link`
                      } else {
                        return `nav-link`
                      }
                    }}
                  >
                    {item.name}
                  </NavLinkDom>
                  {item.subject === BUSINESS_MYAPPROVAL && (
                    <span
                      style={{
                        height: 22,
                        width: 22,
                        textDecoration: 'none',
                      }}
                      className="rounded-circle badge-cancel flex-centered text-sm"
                    >
                      {isSuccess
                        ? data?.data
                            ?.map((e) => e.total)
                            ?.reduce((sum, e) => sum + e, 0)
                        : isLoading
                          ? 0
                          : 0}
                    </span>
                  )}
                </NavItem>
              </Can>
            ))}
          </Nav> */}
          <UserDropdown profile={args.profile} />
        </div>
      </Navbar>
    </>
  )
}

export default NavbarDashboard
