import { createApiSlice } from 'app/createApiSlice'

export const withdrawApiSlice = createApiSlice.injectEndpoints({
  reducerPath: 'withdraw/api',

  endpoints(builder) {
    return {
      fetchDisbursmentPricing: builder.query({
        query() {
          return `/api/v1/bussines/disbursementpricing/instantwithdraw`
        },
      }),
      fetchWithdrawal: builder.query({
        query() {
          return `/api/v1/bussines/instantwithdraw`
        },
      }),
      fetchWithdrawalPending: builder.query({
        query() {
          return `/api/v1/bussines/instantwithdraw?getPending=true`
        },
      }),
      postInstantWithdraw: builder.mutation({
        query(payload) {
          const { disbursementpricingId, wallet3 } = payload
          return {
            url: `/api/v1/bussines/instantwithdraw`,
            method: 'POST',
            body: {
              disbursementpricingId,
              wallet3,
            },
          }
        },
      }),
      getUangUsaha: builder.query({
        query() {
          return `/api/v1/bussines/depo2history/summary`
        },
      }),
      transferDana: builder.mutation({
        query(value) {
          return {
            url: `/api/v1/bussines/depo2history/transfer`,
            method: 'POST',
            body: { amount: value },
          }
        },
      }),
    }
  },
})

export const {
  useFetchDisbursmentPricingQuery,
  usePostInstantWithdrawMutation,
  useFetchWithdrawalQuery,
  useFetchWithdrawalPendingQuery,
  useGetUangUsahaQuery,
  useTransferDanaMutation,
} = withdrawApiSlice
