import moment from 'moment-timezone';

export const generateGreetings = () => {
  const currentHour = moment().format('HH');

  if (currentHour >= 3 && currentHour < 12) {
    return 'Selamat pagi';
  } else if (currentHour >= 12 && currentHour < 15) {
    return 'Selamat siang';
  } else if (currentHour >= 15 && currentHour < 19) {
    return 'Selamat sore';
  } else if (currentHour >= 19 || currentHour < 3) {
    return 'Selamat malam';
  } else {
    return 'Halo';
  }
};
