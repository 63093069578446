import { createAppSlice } from 'app/createAppSlice'

const initialState = {
  numberStep: 1,
  total: 0,
}

export const commodityPurchaseSlice = createAppSlice({
  name: 'comodity-purchase-admin',
  initialState,
  reducers: (create) => ({
    setNumberStep: create.reducer((state, { payload }) => {
      state.numberStep = payload
    }),
    setTotal: create.reducer((state, { payload }) => {
      state.total = payload
    }),
  }),
})

export const { setNumberStep, setTotal } = commodityPurchaseSlice.actions

export default commodityPurchaseSlice.reducer
