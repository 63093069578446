import { useEffect, useMemo, useState } from 'react'
import { useGetUserAvatarQuery as getAva } from 'features/private-views/settings/settingApiSlice'
import { useFetchRequestApprovalQuery as getTrxApproval } from 'features/private-views/approval/approvalApiSlice'

const useGetTrxApproval = (arg) => {
  let [userId, setUserId] = useState([])

  const trxApprovals = getTrxApproval(arg)
  const avaResult = getAva(userId, { skip: userId.length === 0 })

  const data = useMemo(() => {
    const getAvaMaker = (trx) => {
      let result = null
      for (let i = 0; i < avaResult.data?.data?.length; i++) {
        const avaUser = avaResult.data?.data[i]
        if (avaUser.userId === trx?.makerUserId) {
          result = avaUser.link
        }
      }
      return result
    }

    const getSellerAva = (trx) => {
      let result = null
      for (let i = 0; i < avaResult.data?.data?.length; i++) {
        const avaUser = avaResult.data?.data[i]
        if (avaUser.userId === trx?.sellerDetail?.userId) {
          result = avaUser.link
        }
      }
      return result
    }

    const getBuyerAva = (trx) => {
      let result = null
      for (let i = 0; i < avaResult.data?.data?.length; i++) {
        const avaUser = avaResult.data?.data[i]
        if (avaUser.userId === trx?.buyerDetail?.userId) {
          result = avaUser.link
        }
      }
      return result
    }

    return {
      ...trxApprovals,
      data: {
        ...trxApprovals.data,
        items: trxApprovals?.data?.items?.map((approvalData) => {
          return {
            ...approvalData,
            makerAvatar: getAvaMaker(approvalData),
            sellerAvatar: getSellerAva(approvalData),
            buyerAvatar: getBuyerAva(approvalData),
          }
        }),
      },
    }
  }, [trxApprovals, avaResult])

  useEffect(() => {
    function uniq_fast(a) {
      let seen = {}
      let out = []
      let len = a?.length
      let j = 0

      for (let i = 0; i < len; i++) {
        let makerUserId = a[i]?.makerUserId
        let buyerUserId = a[i]?.buyerDetail?.userId
        let sellerUserId = a[i]?.sellerDetail?.userId

        if (seen[makerUserId] !== 1) {
          seen[makerUserId] = 1
          out[j++] = makerUserId
        }

        if (seen[buyerUserId] !== 1) {
          seen[buyerUserId] = 1
          out[j++] = buyerUserId
        }

        if (seen[sellerUserId] !== 1) {
          seen[sellerUserId] = 1
          out[j++] = sellerUserId
        }
      }
      return out.filter((e) => e !== undefined)
    }
    setUserId(uniq_fast(trxApprovals?.data?.items))
  }, [trxApprovals])

  return data
}

export default useGetTrxApproval
