import ForgetPasswordPage from 'features/public-views/forgetPassword'
import StepFourForgetPassword from 'features/public-views/forgetPassword/StepFour'
import StepOneForgetPassword, {
  loader as stepOneLoader,
} from 'features/public-views/forgetPassword/StepOne'
import StepTwoForgetPassword from 'features/public-views/forgetPassword/StepTwo'

import {
  URL_FORGET_PASSWORD_STEP_1,
  URL_FORGET_PASSWORD_STEP_2,
  URL_FORGET_PASSWORD_STEP_4,
} from 'utils/pathUrl'

import { FORGET_PASSWORD, READ } from 'utils/subjectActions'

export const publicRouter = [
  {
    element: <ForgetPasswordPage action={READ} subject={FORGET_PASSWORD} />,
    children: [
      {
        path: URL_FORGET_PASSWORD_STEP_1,
        element: <StepOneForgetPassword />,
        loader: stepOneLoader,
      },
      {
        path: URL_FORGET_PASSWORD_STEP_2,
        element: <StepTwoForgetPassword />,
      },
      {
        path: URL_FORGET_PASSWORD_STEP_4,
        element: <StepFourForgetPassword />,
      },
    ],
    action: READ,
    subject: FORGET_PASSWORD,
    name: 'Forget Password',
  },
]
