import { Card, CardBody, Row, Col } from 'reactstrap'
import RoundedComponent from '../../components/Rounded'
import { useSelector } from 'react-redux'

const NavigationBar = () => {
  const { numberStep } = useSelector((state) => state.comodityPurchase)

  return (
    <div className="mb-4 mt-4">
      <Card>
        <CardBody className="p-4">
          <Row className="text-center d-flex align-items-center flex-column flex-md-row">
            <NavItem
              numberStep={numberStep}
              stepNumber={1}
              title="Tambah Komoditas"
            />
            <NavItem
              numberStep={numberStep}
              stepNumber={2}
              title="Pembayaran"
            />
            <NavItem
              numberStep={numberStep}
              stepNumber={3}
              title="Konfirmasi Pembayaran"
              hideLine={true}
            />
          </Row>
        </CardBody>
      </Card>
    </div>
  )
}

const NavItem = ({ numberStep, stepNumber, title, hideLine = false }) => (
  <>
    <Col className="d-flex align-items-center">
      <RoundedComponent
        childern={<span className="text-white">{stepNumber}</span>}
        className={`${
          numberStep === stepNumber ? 'bg-primary' : 'bg-secondary'
        } rounded-circle flex-centered`}
        style={{ width: '30px', height: '30px' }}
      />
      <small
        className={`mx-2 ${numberStep === stepNumber ? 'fw-bold' : ''} text-truncate`}
        style={{ fontSize: '0.8em' }}
      >
        {title}
      </small>
    </Col>
    {!hideLine && (
      <Col className="d-none d-md-block">
        <hr className="border border-dark border-1 opacity-100 my-0" />
      </Col>
    )}
  </>
)

export default NavigationBar
