export const apiEndpoint = process.env.REACT_APP_ENDPOINT1
export const apiEndpoint2 = process.env.REACT_APP_ENDPOINT2
export const sessionLifetime = process.env.REACT_APP_SESSION_LIFETIME ?? 120 // lifetime in minutes
export const version = process.env.REACT_APP_VERSION

// axios config
export const axiosConfig = () => {
  const token = JSON.parse(localStorage.getItem('apiToken'))

  return {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'ngrok-skip-browser-warning': '69420',
    },
  }
}

// local storage with expiration
export function setLocalStorageWithExpiry(key, value) {
  const now = new Date()

  const item = {
    value: value,
    expiry: now.getTime() + sessionLifetime * 60000,
  }
  localStorage.setItem(key, JSON.stringify(item))
}

// export function getLocalStorageWithExpiry(key) {
//   const itemStr = localStorage.getItem(key)

//   // if the item doesn't exist, return null
//   if (!itemStr) {
//     return null
//   }

//   const item = JSON.parse(itemStr)
//   const now = new Date()

//   // compare the expiry time of the item with the current time
//   if (now.getTime() > item.expiry) {
//     // If the item is expired, delete the item from storage
//     // and return null
//     localStorage.removeItem(key)
//     return null
//   }
//   return item.value
// }

export function getLocalStorageWithExpiry(key) {
  return JSON.parse(localStorage.getItem(key))
}
