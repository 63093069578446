import { CheckCircle, Home, Map, Repeat, Users } from 'react-feather'
import { URL_APPROVAL_BUY, URL_FARMERS } from 'utils/pathUrl'
import {
  BUSINESS_FARMERS,
  BUSINESS_MYAPPROVAL,
  BUSINESS_TALANGIN,
  BUSINESS_TOPUP,
  BUSINESS_TRANSACTION_PURCHASE,
  BUSINESS_TRANSACTION_SELL,
  BUSINESS_WITHDRAWAL,
  MANAGE,
  READ,
} from 'utils/subjectActions'
import switchVerticalIcon from 'assets/icons/switch-vertical.svg'

export const menus = [
  {
    headTitle: 'TRANSAKSI',
    action: READ,
    subject:
      BUSINESS_TALANGIN ||
      BUSINESS_TOPUP ||
      BUSINESS_TRANSACTION_PURCHASE ||
      BUSINESS_TRANSACTION_SELL ||
      BUSINESS_WITHDRAWAL ||
      BUSINESS_FARMERS,
    items: [
      {
        title: 'Dashboard',
        icon: <Home size={22} id="dashboard" />,
        path: '/',
        action: READ,
        subject:
          BUSINESS_TALANGIN ||
          BUSINESS_TOPUP ||
          BUSINESS_TRANSACTION_PURCHASE ||
          BUSINESS_TRANSACTION_SELL ||
          BUSINESS_WITHDRAWAL ||
          BUSINESS_FARMERS,
      },
      {
        title: 'Approval',
        icon: <CheckCircle size={22} id="approval" />,
        path: URL_APPROVAL_BUY,
        action: READ,
        subject: BUSINESS_MYAPPROVAL,
      },
      {
        title: 'Repo Komoditas',
        icon: <img src={switchVerticalIcon} id="repo-komoditas" />,
        path: '/repo-komoditas',
        subject: '',
      },
    ],
  },
  {
    headTitle: 'MANAJEMEN PETANI',
    action: MANAGE,
    subject: BUSINESS_FARMERS,
    items: [
      {
        title: 'Daftar Petani',
        icon: <Users size={22} id="daftar-petani" />,
        path: URL_FARMERS,
        action: MANAGE,
        subject: BUSINESS_FARMERS,
      },
    ],
  },
]
