import {
  Badge,
  Button,
  Card,
  CardBody,
  CardText,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Row,
} from 'reactstrap'
import ILDefaultAva from 'assets/icons/default-ava-profile-user.svg'
import { useDispatch, useSelector } from 'react-redux'

// Form
import { checkSellerInfo } from '../sellCommodityAction'
import { emailCheck } from 'utils/emailCheck'
import { useState } from 'react'

const InfoSeller = () => {
  const dispatch = useDispatch()
  const [dataSeller, setDataSeller] = useState('')

  const { orders } = useSelector((state) => state.sellCommodity)
  const { buyer } = orders

  const handleSubmit = (e) => {
    e.preventDefault()
    dispatch(checkSellerInfo({ search: dataSeller, type: 'SELL' }))
  }

  let isEmail = emailCheck(buyer?.fullName)

  return (
    <Card body className="custom-container m-0 m-auto">
      <Row>
        <Col sm="12">
          <Card className="border-0">
            <CardBody>
              <CardText tag="h5" className="text-md">
                Penjualan
              </CardText>
              <Form onSubmit={handleSubmit}>
                <FormGroup>
                  <Label for="phone" className="text-sm">
                    Jual ke
                  </Label>
                  <InputGroup>
                    <Input
                      name="phone"
                      placeholder="Masukkan nomor handphone pembeli"
                      type="text"
                      className="text-normal"
                      value={dataSeller}
                      onChange={(e) => {
                        let value = e.target.value.trim()
                        if (value.startsWith('+')) {
                          value = value.slice(1)
                        }
                        setDataSeller(value)
                      }}
                    />
                    <Button
                      color="transparent"
                      className="border text-normal"
                      type="submit"
                    >
                      Cek
                    </Button>
                  </InputGroup>
                  <span className="text-xs">
                    Contoh: 0813xxxx atau 62813xxxx
                  </span>
                </FormGroup>
              </Form>
            </CardBody>
          </Card>
        </Col>
        <Col sm="12">
          {buyer && (
            <Card className="border-0 p-0 m-0">
              <CardBody>
                <Card>
                  <CardBody>
                    <CardText tag="h6" className="text-sm fw-semibold">
                      Informasi pembeli
                    </CardText>
                    <Card>
                      <CardBody>
                        <div className="flex-centered justify-content-between">
                          <div className="flex-centered gap-1 ">
                            <img
                              src={buyer.profileImg ?? ILDefaultAva}
                              alt="ava"
                              width={36}
                              height={35}
                              className="rounded-circle"
                            />
                            <span className="text-sm fw-semibold">
                              {buyer?.fullName}
                            </span>
                          </div>
                          {isEmail ? (
                            <></>
                          ) : (
                            <Badge size="sm" disabled className="badge-verify">
                              {buyer?.profileStatus}
                            </Badge>
                          )}
                        </div>
                      </CardBody>
                    </Card>
                  </CardBody>
                </Card>
              </CardBody>
            </Card>
          )}
        </Col>
      </Row>
    </Card>
  )
}

export default InfoSeller
