import Logo from 'assets/images/pmg-logo-beta.png'
import { Card, CardText, Container } from 'reactstrap'
import './style.scss'
import UserInfo from './UserInfo'
import ItemsDetail from './Details'
import ButtonAction from './ButtonAction'
import { useCheckDataTrxQuery } from './requestTransactionApiSlice'
import { useSearchParams } from 'react-router-dom'
const RequestTransaction = () => {
  const [searchParams] = useSearchParams()
  let code = searchParams.get('code')
  let amount = searchParams.get('totalAmount')

  const { data } = useCheckDataTrxQuery({ code, amount })

  return (
    <Container>
      <Card
        style={{ maxWidth: 480 }}
        className="px-2 mx-auto d-flex justify-content-center align-items-center"
      >
        <img
          src={Logo}
          alt="Logo PasarMikro"
          className="text-center"
          style={{
            marginTop: 32,
            marginBottom: 24,
            width: '100%',
            maxWidth: '177px',
            height: 'auto',
          }}
        />
        <CardText tag="h4" className="mb-3">
          Permintaan Transaksi
        </CardText>

        <CardText
          className="head-badges w-100"
          style={{ padding: '16px 16px' }}
        >
          <span>Transaksi Pembelian</span>
        </CardText>
        <UserInfo sellerDetail={data?.sellerDetail} />
        <ItemsDetail
          itemDetail={data?.itemDetail}
          totalAmount={data?.totalAmount}
        />
        <ButtonAction code={code} amount={amount} />
      </Card>
    </Container>
  )
}

export default RequestTransaction
