import { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { CheckCircle, ChevronDown } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  Container,
  Spinner,
} from 'reactstrap'
import { toggleBanner } from './farmersListSlice'
import { useFetchFarmersQuery } from './farmersListApiSlice'
import { clearState } from '../forms/formSlice'
import { ButtonIcon } from 'components/Buttons'

const FarmersTable = () => {
  const { tag, isOpenBanner } = useSelector((s) => s.farmersList)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [type, setType] = useState({ size: tag.size, type: tag.type })
  let [page, setPage] = useState({ page: tag.page })

  const { data, isLoading } = useFetchFarmersQuery()

  // const data = {
  //   meta: {
  //     error: false,
  //     code: 200,
  //     message: 'request success',
  //   },
  //   data: {
  //     totalItems: 6,
  //     items: [
  //       {
  //         userId: 684,
  //         name: 'AMAT FAOZI',
  //         phoneNumber: '011111100000',
  //         status: 'WAITAPPROVAL',
  //         code: '36fc52bc-e430-5303-99ef-02a43c615fab',
  //         userLevel: 2,
  //       },
  //       {
  //         userId: 681,
  //         name: 'SAITON',
  //         phoneNumber: '0833333333333',
  //         status: 'WAITAPPROVAL',
  //         code: '36fc52bc-e430-5303-99ef-02a43c615fab',
  //         userLevel: 2,
  //       },
  //       {
  //         userId: 679,
  //         name: 'PANGERAN GANTENG',
  //         phoneNumber: '08999999999999',
  //         status: 'WAITAPPROVAL',
  //         code: '36fc52bc-e430-5303-99ef-02a43c615fab',
  //         userLevel: 2,
  //       },
  //       {
  //         userId: 678,
  //         name: 'SLAMET HARI NATAL',
  //         phoneNumber: '08122222222',
  //         status: 'WAITAPPROVAL',
  //         code: '36fc52bc-e430-5303-99ef-02a43c615fab',
  //         userLevel: 2,
  //       },
  //       {
  //         userId: 677,
  //         name: 'TUHAN',
  //         phoneNumber: '0811111111111',
  //         status: 'WAITAPPROVAL',
  //         code: '36fc52bc-e430-5303-99ef-02a43c615fab',
  //         userLevel: 2,
  //       },
  //       {
  //         userId: 282,
  //         name: 'Fajar Nur Hamzah',
  //         phoneNumber: '085716319806',
  //         status: 'VERIFIED',
  //         code: '36fc52bc-e430-5303-99ef-02a43c615fab',
  //         userLevel: 2,
  //       },
  //     ],
  //     totalPages: 1,
  //     currentPage: 0,
  //   },
  // }

  const columns = [
    {
      name: 'Nama',
      cell: (row) => {
        return <span>{row?.name}</span>
      },
    },
    {
      name: 'Nomor Telepon',
      cell: (row) => {
        return <span>{row?.phoneNumber}</span>
      },
    },
    {
      name: 'Status',
      cell: (row) => {
        return (
          <Badge
            className={
              row?.status === 'VERIFIED' ? 'badge-verify' : 'badge-danger'
            }
          >
            {row?.status === 'VERIFIED'
              ? 'Terverifikasi'
              : 'Belum Terverfikasi'}
          </Badge>
        )
      },
    },
    {
      name: '',
      cell: (row) => {
        return (
          <span
            style={{ cursor: 'pointer' }}
            className="fw-semibold text-center"
            onClick={() => navigate(`/farmers/${row?.userId}`)}
          >
            Lihat Detail
          </span>
        )
      },
    },
  ]

  const customStyles = {
    rows: {},
    headCells: {
      style: {
        backgroundColor: '#F9FAFB',
        color: '#475467',
      },
    },
    cells: {
      style: {
        color: '#475467',
      },
    },
  }

  useEffect(() => {
    dispatch(clearState())
  }, [])

  return (
    <Container>
      <Card className="mt-4">
        <CardBody>
          <DataTable
            customStyles={customStyles}
            columns={columns}
            data={data?.data?.items}
            progressPending={isLoading}
            responsive
            persistTableHead
            paginationTotalRows={data?.data?.totalItems}
            progressComponent={<Spinner />}
            sortIcon={<ChevronDown />}
            sortServer
            className="border p-0 border-1 rounded-top"
            noDataComponent={
              <p className="my-3 fw-semibold">
                Belum ada pengguna yang didaftarkan
              </p>
            }
            pagination
            paginationServer
            paginationComponent={(props) => {
              return (
                <CardFooter
                  className="border border-top-0"
                  style={{ backgroundColor: 'transparent' }}
                >
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="text-sm">
                      {`Halaman ${page.page + 1} dari ${Math.ceil(props.rowCount / props.rowsPerPage)}`}
                    </div>
                    <div className="d-flex flex-centered gap-1">
                      <ButtonIcon
                        btnTitle="Sebelumnya"
                        btnProps={{
                          disabled: page.page === 0,
                          size: 'sm',
                          color: 'secondary',
                          onClick: () => {
                            setPage((prev) => ({ page: prev.page - 1 }))
                            // dispatch(
                            //   setFetchOption({
                            //     type: tag.type,
                            //     page: page.page - 1,
                            //   })
                            // );
                          },
                        }}
                        btnTitleProps={{
                          className: 'text-sm',
                        }}
                      />
                      <ButtonIcon
                        btnTitle="Selanjutnya"
                        btnProps={{
                          disabled:
                            page.page ===
                            Math.ceil(props.rowCount / props.rowsPerPage) - 1,
                          size: 'sm',
                          color: 'secondary',
                          onClick: () => {
                            setPage((prev) => ({ page: prev.page + 1 }))
                            // dispatch(
                            //   setFetchOption({
                            //     type: tag.type,
                            //     page: page.page + 1,
                            //   })
                            // );
                          },
                        }}
                        btnTitleProps={{
                          className: 'text-sm',
                        }}
                      />
                    </div>
                  </div>
                </CardFooter>
              )
            }}
            subHeader={true}
            subHeaderComponent={
              <div className="w-100">
                <div className="d-flex justify-content-between align-items-center mb-1">
                  <h5 className="fw-semibold">Grup Saya</h5>
                  <Button
                    onClick={() => navigate('/farmers/registrasi')}
                    size="sm"
                    color="primary"
                  >
                    + Daftarkan Pengguna
                  </Button>
                </div>
                <div>
                  <Alert
                    isOpen={isOpenBanner}
                    toggle={() => dispatch(toggleBanner())}
                    color="success"
                    className="d-flex align-items-center mt-4 w-100"
                  >
                    <span>
                      <CheckCircle size={17} /> Pengguna baru berhasil
                      didaftarkan.
                    </span>
                  </Alert>
                </div>
              </div>
            }
          />
        </CardBody>
      </Card>
    </Container>
  )
}

export default FarmersTable
