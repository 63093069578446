import { createBrowserRouter } from 'react-router-dom'
import App from '../App'

// Public Router

import TncView from '../public-views/tnc'
import SuccessVerifyAccount from '../public-views/success-verify-account'
import OrderInfoView from 'features/public-views/order'

// Setting Pages
import RequestTransaction from 'features/private-views/request-transaction'
import PageForbidden from 'components/PageForbidden'

import { errorRouter } from './errorRouter'
import { privateRouter } from './privateRouter'
import { publicRouter } from './publicRouter'
import LandPlotView from 'features/public-views/plot'
import { authRouter } from './authRouter'

export const routes = createBrowserRouter([
  {
    element: <App />,
    children: [...privateRouter],
    errorElement: <PageForbidden />,
  },
  {
    path: '/request-transaction',
    element: <RequestTransaction />,
  },
  {
    path: '/syarat-dan-ketentuan',
    exact: true,
    element: <TncView />,
  },
  {
    path: '/sukses-verifikasi',
    exact: true,
    element: <SuccessVerifyAccount />,
  },
  {
    path: '/public/order',
    element: <OrderInfoView />,
  },
  {
    path: '/plot/view/:code',
    element: <LandPlotView />,
  },
  {
    path: '/plot/view/pin/:lat/:long',
    element: <LandPlotView />,
  },
  ...publicRouter,
  ...errorRouter,
  ...authRouter,
])
