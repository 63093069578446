import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { apiEndpoint } from 'configs'

export const loginApiSlice = createApi({
  reducerPath: 'login/api',
  baseQuery: fetchBaseQuery({ baseUrl: apiEndpoint }),
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    loginUser: builder.mutation({
      query: ({ email, password }) => ({
        url: `/api/v1/bussines/auth/login`,
        method: 'POST',
        body: { email, password },
      }),
      transformResponse: (response, _meta, arg) => {
        const newData = response?.abilityRole?.map((obj) => {
          const newItem = { ...obj, subject: obj.subjects }
          delete newItem?.subjects
          delete newItem?.fields
          delete newItem?.conditions
          return newItem
        })

        return {
          ...response,
          abilityRole: newData,
        }
      },
    }),
  }),
})

export const { useLoginUserMutation } = loginApiSlice
