import { Card, CardBody, CardText } from 'reactstrap'

const ItemsDetail = ({ itemDetail, totalAmount }) => {
  return (
    <Card className="w-100 mb-3">
      <CardBody>
        <CardText tag="h6" className="mb-1">
          Rincian Pesanan
        </CardText>
        {itemDetail?.map((item, idx) => (
          <div
            key={idx}
            className="d-flex align-items-center justify-content-between gap-2 my-3"
          >
            <div className="">
              <div>{item?.nameItem}</div>
              <div>
                <span style={{ color: 'rgba(102, 112, 133, 1)' }}>
                  {item?.amount} kg •{' '}
                </span>
                <span style={{ color: 'rgba(0, 99, 134, 1)' }}>
                  Rp{item?.price}
                </span>
              </div>
            </div>
            <div className="fw-semibold">Rp{item?.totalPrice}</div>
          </div>
        ))}
        <hr />
        <div className="d-flex align-items-center justify-content-between">
          <span className="fw-bold">Total</span>
          <div className="fw-bold">Rp{totalAmount}</div>
        </div>
      </CardBody>
    </Card>
  )
}

export default ItemsDetail
