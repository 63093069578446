import { createApiSlice } from 'app/createApiSlice'

export const topupApiSlice = createApiSlice.injectEndpoints({
  reducerPath: 'topup/api',
  endpoints(builder) {
    return {
      fetchTopUp: builder.query({
        query() {
          return `/api/v1/bussines/durianbanks`
        },
      }),
      fetchPendingBri: builder.query({
        query() {
          return {
            url: `/api/v1/bussines/topupbri/pending`,
          }
        },
      }),
      fetchPendingBca: builder.query({
        query() {
          return {
            url: `/api/v1/bussines/topupuniquecode/pending`,
          }
        },
      }),
      fetchPendingVaBca: builder.query({
        query() {
          return {
            url: `/api/v1/bussines/topupvabca/pending`,
          }
        },
      }),
      postDurianPay: builder.mutation({
        query(payload) {
          const { amount, gateway } = payload
          return {
            url: `/api/v1/bussines/topuppg`,
            method: 'POST',
            body: {
              amount,
              gateway,
            },
          }
        },
      }),
      postBcaUniqueCode: builder.mutation({
        query(payload) {
          const { amount } = payload
          return {
            url: `/api/v1/bussines/topupuniquecode`,
            method: 'POST',
            body: {
              amount,
            },
          }
        },
      }),
      postBriVa: builder.mutation({
        query(payload) {
          const { amount } = payload
          return {
            url: `/api/v1/bussines/topupbri`,
            method: 'POST',
            body: {
              amount,
            },
          }
        },
      }),
      postBcaVa: builder.mutation({
        query(payload) {
          const { amount } = payload
          return {
            url: `/api/v1/bussines/topupvabca`,
            method: 'POST',
            body: {
              amount,
            },
          }
        },
      }),
      cancelBcaUniqueCode: builder.mutation({
        query(payload) {
          const { customerCode } = payload
          return {
            url: `/api/v1/bussines/topupuniquecode/cancel`,
            method: 'POST',
            body: {
              customerCode,
            },
          }
        },
      }),
      cancelBcaVa: builder.mutation({
        query(payload) {
          const { customerCode } = payload
          return {
            url: `/api/v1/bussines/topupvabca/cancel`,
            method: 'POST',
            body: {
              customerCode,
            },
          }
        },
      }),
      cancelBriVa: builder.mutation({
        query(payload) {
          const { customerCode } = payload
          return {
            url: `api/v1/bussines/topupbri/cancel`,
            method: 'POST',
            body: {
              customerCode,
            },
          }
        },
      }),
      fetchConfirmBri: builder.query({
        query() {
          return {
            url: `topupbri/cofirm`,
          }
        },
      }),
    }
  },
})

export const {
  useFetchTopUpQuery,
  useFetchPendingBcaQuery,
  usePostDurianPayMutation,
  usePostBcaUniqueCodeMutation,
  useCancelBcaUniqueCodeMutation,
  useFetchPendingBriQuery,
  useFetchPendingVaBcaQuery,
  usePostBriVaMutation,
  usePostBcaVaMutation,
  useCancelBcaVaMutation,
  useCancelBriVaMutation,
  useFetchConfirmBriQuery,
} = topupApiSlice
