import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  Card,
  CardBody,
  CardTitle,
  Container,
  UncontrolledTooltip,
} from 'reactstrap'
import Total from '../Total'
import ModalPassword from './ModalPassword'
import uangBelanja from '../../../assets/icons/uang-belanja.png'
import uangUsaha from '../../../assets/icons/uang-usaha.png'
import { AlertCircle } from 'react-feather'
import { useIntl } from 'react-intl'
import axios from 'axios'
import { apiEndpoint } from '../../../utility/Utils'
import { setNumberStep, setTotal } from '../commodityPurchaseSlice'
import { useDispatch, useSelector } from 'react-redux'

const ConfirmPayment = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const intl = useIntl()
  const location = useLocation()
  const dataState = location.state
  const setNumber = (e) => dispatch(setNumberStep(e))
  const { token } = useSelector((e) => e.auth)

  const [userData, setUserData] = useState({})
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [dataProfile, setDataProfile] = useState({})
  const toggleModal = () => {
    setIsOpenModal(!isOpenModal)
  }

  const handleSubmit = () => {
    toggleModal()
  }

  const getProfile = async () => {
    try {
      const { data } = await axios.get(
        `${apiEndpoint}/api/v1/bussines/profile`,
        { headers: { Authorization: `Bearer ${token}` } },
      )
      setDataProfile(data)
    } catch (error) {
      console.log(error)
    }
  }

  const handleFinished = (resp) => {
    if (resp) {
      if (resp.status === 200) {
        navigate('/sukses-verifikasi')
      } else {
        console.log('tryagain')
      }
    }
  }

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem('userData'))
    setUserData(userData)
    setNumber(3)
    getProfile()
    dispatch(setTotal(dataState?.finalTotalAmount))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <ModalPassword
        toggle={toggleModal}
        isOpen={isOpenModal}
        handleFinished={handleFinished}
        dataProfile={dataProfile}
        userData={userData}
        dataState={dataState}
      />
      <Container fluid className="custom-container">
        <Card className="p-2">
          <CardBody>
            <div className="mb-5">
              <CardTitle
                style={{ fontWeight: 'bold' }}
                tag="h6"
                className="mb-3"
              >
                Daftar Pembelian
              </CardTitle>
              {dataState?.item?.map((e, index) => {
                return (
                  <div
                    key={index}
                    className="d-flex flex-row gap-3 align-items-center mb-3"
                  >
                    <img
                      src={e?.comodity?.img}
                      className="rounded float-left"
                      style={{ width: '60px', height: '60px' }}
                      alt="komoditas"
                    />
                    <div className="d-flex flex-column">
                      <span>
                        {e?.comodity?.label} {e?.amount} {e?.unit}
                      </span>
                      <span style={{ fontWeight: 'bold' }}>
                        Rp{' '}
                        {intl.formatNumber(
                          dataState?.vat?.enableVAT
                            ? (String(e?.priceAfterVAT)?.includes('.') ||
                              String(e?.priceAfterVAT)?.includes(',')
                                ? Number(
                                    e?.priceAfterVAT
                                      ?.replace?.(/\./g, '')
                                      ?.replace?.(/\,/g, '.')
                                      ?.slice?.(0, 15),
                                  )
                                : e?.priceAfterVAT) *
                                (String(e?.amount)?.includes('.') ||
                                String(e?.amount)?.includes(',')
                                  ? Number(
                                      e?.amount
                                        ?.replace?.(/\./g, '')
                                        ?.replace?.(/\,/g, '.')
                                        ?.slice?.(0, 15),
                                    )
                                  : e?.amount)
                            : (String(e?.price)?.includes('.') ||
                              String(e?.price)?.includes(',')
                                ? Number(
                                    e?.price
                                      ?.replace?.(/\./g, '')
                                      ?.replace?.(/\,/g, '.')
                                      ?.slice?.(0, 15),
                                  )
                                : e?.price) *
                                (String(e?.amount)?.includes('.') ||
                                String(e?.amount)?.includes(',')
                                  ? Number(
                                      e?.amount
                                        ?.replace?.(/\./g, '')
                                        ?.replace?.(/\,/g, '.')
                                        ?.slice?.(0, 15),
                                    )
                                  : e?.amount),
                          {
                            useGrouping: 'always',
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          },
                        )}
                      </span>
                    </div>
                  </div>
                )
              })}
            </div>
            <Card>
              <CardBody>
                <CardTitle tag="h6" className="fw-bold">
                  Ringkasan Pembelian
                </CardTitle>
                <div className="">
                  <div className="d-flex justify-content-between">
                    <p>Harga Komoditas</p>
                    <p>
                      Rp
                      {intl.formatNumber(
                        dataState?.vat?.enableVAT
                          ? dataState?.item?.reduce(
                              (sum, e) => sum + e.priceAfterVAT,
                              0,
                            )
                          : dataState?.totalAmount,
                        {
                          useGrouping: 'always',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        },
                      )}
                    </p>
                  </div>
                  <div className="d-flex justify-content-between">
                    <p>Biaya Transaksi</p>
                    <div className="d-flex justify-content-around gap-2">
                      {dataState?.feeTx?.haveFreeTx > 0 && (
                        <p className="text-secondary text-decoration-line-through">
                          Rp
                          {intl.formatNumber(
                            dataState?.feeTx?.haveFreeTx > 0
                              ? dataState?.feeTx?.feeTx -
                                  dataState?.feeTx?.feeTxVAT
                              : dataState?.feeTx?.feeTx,
                            {
                              useGrouping: 'always',
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 0,
                            },
                          )}
                        </p>
                      )}
                      <p>
                        Rp
                        {intl.formatNumber(
                          dataState?.feeTx?.haveFreeTx > 0
                            ? 0
                            : dataState?.feeTx?.feeTx -
                                dataState?.feeTx?.feeTxVAT,
                          {
                            useGrouping: 'always',
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          },
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between ">
                    <div>
                      <span>PPN</span>{' '}
                      <AlertCircle
                        style={{ color: '#667085' }}
                        size={20}
                        id="ppn"
                      />
                      <UncontrolledTooltip
                        className="text-secondary"
                        placement="bottom"
                        target="ppn"
                      >
                        PPN akan dikenakan sesuai dengan ketentuan
                        perundang-undangan yang berlaku di Indonesia
                      </UncontrolledTooltip>
                    </div>
                    <div className="d-flex justify-content-around gap-2">
                      {dataState?.feeTx?.haveFreeTx > 0 && (
                        <p className="text-secondary text-decoration-line-through">
                          Rp
                          {intl.formatNumber(
                            dataState?.vat?.totalVAT +
                              dataState?.feeTx?.feeTxVAT,
                            {
                              useGrouping: 'always',
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 0,
                            },
                          )}
                        </p>
                      )}
                      <p>
                        Rp{' '}
                        {intl.formatNumber(
                          dataState?.feeTx?.haveFreeTx > 0
                            ? dataState?.vat?.totalVAT
                            : dataState?.vat?.totalVAT +
                                dataState?.feeTx?.feeTxVAT,
                          {
                            useGrouping: 'always',
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          },
                        )}
                      </p>
                    </div>
                  </div>
                  <hr />
                  <div className="d-flex justify-content-between">
                    <p style={{ fontWeight: 'bold' }}>Total Transaksi</p>
                    <p style={{ fontWeight: 'bold' }}>
                      Rp
                      {intl.formatNumber(dataState?.finalTotalAmount, {
                        useGrouping: 'always',
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}
                    </p>
                  </div>
                </div>
              </CardBody>
            </Card>
            <div className="mt-3">
              <h6 style={{ fontWeight: 'bold' }}>Metode Pembayaran</h6>
              <div className="d-flex flex-row gap-3">
                {dataState?.talangin && (
                  <div
                    style={{
                      backgroundColor: '#FFFAEB',
                    }}
                    className="d-flex flex-row justify-content-between align-items-center p-3 rounded flex-grow-1"
                  >
                    <div className="d-flex flex-row gap-2">
                      <img
                        style={{ height: '32px', width: '32px' }}
                        src={uangUsaha}
                        alt="talangin"
                      />
                      <div className="d-flex flex-column">
                        <span style={{ fontWeight: 'bold' }}>Talangin</span>
                        <span>
                          Rp
                          {intl.formatNumber(dataState?.maxCreditLimit ?? 0, {
                            useGrouping: 'always',
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          })}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                <div
                  style={{
                    backgroundColor: '#ECFAFF',
                  }}
                  className="d-flex flex-row justify-content-between align-items-center p-3 rounded flex-grow-1"
                >
                  <div className="d-flex flex-row gap-2">
                    <img
                      style={{ height: '32px', width: '32px' }}
                      src={uangBelanja}
                      alt="talangin"
                    />
                    <div className="d-flex flex-column">
                      <span style={{ fontWeight: 'bold' }}>Uang Belanja</span>
                      <span>
                        Rp
                        {intl.formatNumber(
                          dataState?.talangin
                            ? dataState?.finalTotalAmount -
                                dataState?.maxCreditLimit
                            : dataState?.finalTotalAmount ?? 0,
                          {
                            useGrouping: 'always',
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          },
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </Container>
      <Total
        text="Bayar Sekarang"
        handleClick={handleSubmit}
        amount={dataState?.finalTotalAmount}
      />
    </div>
  )
}

export default ConfirmPayment
