import axios from 'axios'
import { useEffect, useState } from 'react'
import { Plus, Trash2 } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Row,
} from 'reactstrap'
import { apiEndpoint } from '../../utility/Utils'
import { setTotal } from './commodityPurchaseSlice'
import { useIntl } from 'react-intl'
import AmountInput from 'components/AmountInput'

const ComodityForm = ({
  handleFinished,
  title,
  isSet,
  userData,
  handleCbData,
}) => {
  const dispatch = useDispatch()
  const [comodities, setComodities] = useState([{ id: 1 }])
  const [dataComodities, setDatComodities] = useState([])
  const [notes, setNotes] = useState('')
  const [listComodities, setListComodities] = useState([])
  const [units, setUnits] = useState([])
  const { token } = useSelector((e) => e.auth)
  const intl = useIntl()

  const handleChange = (key, e, index) => {
    setDatComodities((prevValues) => {
      const newData = [...prevValues]
      newData[index] = {
        ...newData[index],
        [key]: e,
        totalPrice:
          key === 'price' && dataComodities[index]?.quantity
            ? Number(
                e?.replace?.(/\./g, '')?.replace?.(/\,/g, '.')?.slice?.(0, 15),
              ) *
              Number(
                newData[index]?.quantity
                  ?.replace?.(/\./g, '')
                  ?.replace?.(/\,/g, '.')
                  ?.slice?.(0, 15),
              )
            : key === 'quantity' && dataComodities[index]?.price
              ? Number(
                  newData[index]?.price
                    ?.replace?.(/\./g, '')
                    ?.replace?.(/\,/g, '.')
                    ?.slice?.(0, 15),
                ) *
                Number(
                  e
                    ?.replace?.(/\./g, '')
                    ?.replace?.(/\,/g, '.')
                    ?.slice?.(0, 15),
                )
              : newData[index]?.totalPrice,
      }
      return newData
    })
  }

  const addComodity = () => {
    const newId = comodities.length + 1
    setComodities([...comodities, { id: newId }])
  }

  const removeComodity = (id) => {
    setComodities(comodities.filter((comodity) => comodity.id !== id))

    const indexToRemove = id - 1
    setDatComodities(dataComodities.filter((_, idx) => +idx !== indexToRemove))
  }

  const getComodities = async () => {
    try {
      if (listComodities.length < 1) {
        const { data } = await axios.get(
          `${apiEndpoint}/api/v1/bussines/items?sortby=nameItem&sortdir=asc`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )

        if (data?.totalItems > data?.items.length) {
          const res = await axios.get(
            `${apiEndpoint}/api/v1/bussines/items?sortby=nameItem&sortdir=asc&size=${data?.totalItems}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
          )
          const newData = res?.data?.items.map((e) => {
            return {
              value: e.id,
              label: e.nameItem,
              id: e.id,
              img: e.linkFoto,
              sku: e.SKU,
            }
          })
          setListComodities(newData)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getUnits = async () => {
    try {
      if (units.length < 1) {
        const { data } = await axios.get(`${apiEndpoint}/api/v1/public/version`)
        const newData = data?.unitList?.split(',')
        const newestData = newData.map((e) => ({
          value: e,
          label: e,
        }))
        setUnits(newestData)
      }
    } catch (error) {
      console.log(error)
    }
  }

  handleCbData(dataComodities)

  const handleChangeInputSeparator = (data, name, idx) => {
    if (data !== dataComodities[idx]?.[name]) {
      handleChange(name, data, idx)
    }
  }

  useEffect(() => {
    if (listComodities?.length < 1) {
      getComodities()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listComodities])

  useEffect(() => {
    if (units?.length < 1) {
      getUnits()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [units])

  useEffect(() => {
    if (userData) {
      setDatComodities(userData?.dataComodities ?? [])
      setComodities(userData?.comodities ?? [{ id: 1 }])
      setNotes(userData?.notes ?? '')
    }
    if (isSet) {
      const obj = {
        dataComodities: dataComodities,
        comodities: comodities,
        notes: notes,
      }
      handleFinished(obj)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSet, userData])

  useEffect(() => {
    if (dataComodities) {
      dispatch(
        setTotal(dataComodities?.reduce((sum, e) => sum + e.totalPrice, 0)),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataComodities])

  return (
    <>
      <Card>
        <CardBody className="p-4">
          <CardTitle tag="h5" className="mb-4">
            {title}
          </CardTitle>
          {comodities?.map((comodity, index) => (
            <div key={comodity.id}>
              <FormGroup>
                <Label>Komoditas</Label>
                <Select
                  name="comodity"
                  placeholder="Pilih komoditas"
                  options={listComodities}
                  onChange={(e) => {
                    handleChange('comodity', e, index)
                  }}
                  value={dataComodities[index]?.comodity}
                  defaultValue={dataComodities[index]?.comodity}
                />
              </FormGroup>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label>Jumlah</Label>
                    <AmountInput
                      name="quantity"
                      idx={index}
                      value={dataComodities[index]?.quantity}
                      cb={handleChangeInputSeparator}
                      placeholder="0"
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label>Satuan</Label>
                    <Select
                      options={units}
                      name="unit"
                      onChange={(e) => {
                        handleChange('unit', e, index)
                      }}
                      placeholder="Pilih satuan"
                      defaultValue={dataComodities[index]?.unit}
                      value={dataComodities[index]?.unit}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label>Harga per satuan</Label>
                    <InputGroup>
                      <InputGroupText className="bg-transparent">
                        Rp
                      </InputGroupText>
                      <AmountInput
                        name="price"
                        idx={index}
                        value={dataComodities[index]?.price}
                        cb={handleChangeInputSeparator}
                        placeholder="0"
                        dotOnly
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label>Total Harga</Label>
                    <InputGroup>
                      <InputGroupText className="bg-transparent">
                        Rp
                      </InputGroupText>
                      <Input
                        disabled={true}
                        type="text"
                        value={intl.formatNumber(
                          isNaN(dataComodities[index]?.totalPrice)
                            ? 0
                            : dataComodities[index]?.totalPrice,
                          {
                            useGrouping: 'always',
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 3,
                          },
                        )}
                        name="totalPrice"
                        onChange={() => {}}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
              {index > 0 && (
                <div className="d-flex justify-content-end">
                  <Button
                    outline
                    color="danger"
                    onClick={() => removeComodity(comodity.id)}
                  >
                    <Trash2 /> Hapus
                  </Button>
                </div>
              )}
              <hr />
            </div>
          ))}
          <Button outline color="primary" onClick={addComodity}>
            <Plus /> Tambah Komoditas
          </Button>
        </CardBody>
      </Card>
      <Card>
        <CardBody className="p-4">
          <CardTitle tag="h5" className="mb-4">
            Catatan ke penjual
          </CardTitle>
          <Input
            type="textarea"
            name="notes"
            onChange={(e) => {
              setNotes(e.target.value)
            }}
            defaultValue={notes}
            placeholder="Masukkan catatan jika ada"
          />
        </CardBody>
      </Card>
    </>
  )
}

export default ComodityForm
