import ApprovalPage from 'features/private-views/approval'
import PurchaseCommodity from 'features/private-views/purchase-commodity'
import DashboardView from 'features/private-views/dashboard'
import SellCommodityView from 'features/private-views/sell-commodity'
import SuccessTrx from 'features/private-views/sell-commodity/SuccessTrx'
import TalanginInvoice from 'features/private-views/talangin-invoice'
import ConfirmRollover from 'features/private-views/talangin-invoice/rollover/ConfirmRollover'
import Rollover from 'features/private-views/talangin-invoice/rollover/Rollover'
import Topup from 'features/private-views/topup'
import TransferBank from 'features/private-views/topup/TransferBank'
import Withdraw from 'features/private-views/withdraw'
import SettingView from 'features/private-views/settings'

import Transactions from 'private-views/transactions'
import AddComodity from 'private-views/transactions/AddComodity'
import SuccesTransaction from 'private-views/transactions/SuccesTransaction'
import ConfirmPayment from 'private-views/transactions/confirmPayment'
import Payment from 'private-views/transactions/payment'
import TalanginConfirm from 'private-views/transactions/talanginConfirm'

import {
  BUSINESS_MYAPPROVAL,
  BUSINESS_TALANGIN,
  BUSINESS_TOPUP,
  READ,
  BUSINESS_TRANSACTION_PURCHASE,
  BUSINESS_TRANSACTION_SELL,
  BUSINESS_WITHDRAWAL,
  CREATE,
  MANAGE,
} from 'utils/subjectActions'
import { settingRoutes } from './settingRoutes'
import ApprovalTable from 'features/private-views/approval/ApprovalTable'
import TableRequestSell from 'features/private-views/approval/TableRequestSell'
import {
  URL_APPROVAL_BUY,
  URL_APPROVAL_SELL,
  URL_APPROVAL_WITHDRAWAL,
  URL_FARMERS,
  URL_FARMERS_DETAIL,
  URL_FARMERS_REGIST,
} from 'utils/pathUrl'
import TableRequestWithdrawal from 'features/private-views/approval/TableRequestWithdrawal'
import FarmersWeb from 'features/private-views/farmers-web'
import FarmersTable from 'features/private-views/farmers-web/farmers-list'
import FormRegis from 'features/private-views/farmers-web/forms'
import DetailFarmers from 'features/private-views/farmers-web/detail'

export const privateRouter = [
  {
    path: '/',
    element: <DashboardView />,
    action: READ,
    subject:
      BUSINESS_TALANGIN ||
      BUSINESS_TOPUP ||
      BUSINESS_TRANSACTION_PURCHASE ||
      BUSINESS_TRANSACTION_SELL ||
      BUSINESS_WITHDRAWAL,
    name: 'Dashboard',
  },
  {
    element: (
      <Transactions action={CREATE} subject={BUSINESS_TRANSACTION_PURCHASE} />
    ),
    children: [
      {
        path: '/transaksi-pembelian-tambah-komoditas',
        element: <AddComodity />,
      },
      {
        path: '/transaksi-pembelian-pembayaran',
        element: <Payment />,
      },
      {
        path: '/transaksi-pembelian-pembayaran-uang-belanja-konfirmasi',
        element: <ConfirmPayment />,
      },
      {
        path: '/transaksi-pembelian-pembayaran-talangin',
        element: <TalanginConfirm />,
      },
    ],
  },
  {
    path: '/transaksi-pembelian-sukses',
    element: (
      <SuccesTransaction
        action={CREATE}
        subject={BUSINESS_TRANSACTION_PURCHASE}
      />
    ),
  },
  {
    path: '/purchase-commodity',
    element: <PurchaseCommodity />,
  },
  {
    path: '/sell-commodity',
    element: <SellCommodityView />,
  },
  {
    path: '/sell-commodity-success',
    element: <SuccessTrx action={CREATE} subject={BUSINESS_TRANSACTION_SELL} />,
  },
  {
    path: '/topup',
    element: <Topup action={MANAGE} subject={BUSINESS_TOPUP} />,
  },
  {
    path: '/topup-instruksi',
    element: <TransferBank action={READ} subject={BUSINESS_TOPUP} />,
  },
  {
    path: '/tagihan-talangin',
    element: <TalanginInvoice action={READ} subject={BUSINESS_TALANGIN} />,
  },
  {
    path: '/penarikan-uang',
    element: <Withdraw action={READ} subject={BUSINESS_WITHDRAWAL} />,
  },
  {
    path: '/perpanjang-tempo-pembayaran',
    element: <Rollover action={MANAGE} subject={BUSINESS_TALANGIN} />,
  },
  {
    path: '/perpanjang-tempo-pembayaran-konfirmasi',
    element: <ConfirmRollover action={MANAGE} subject={BUSINESS_TALANGIN} />,
  },
  {
    path: URL_APPROVAL_BUY,
    element: <ApprovalPage />,
    action: READ,
    subject: BUSINESS_MYAPPROVAL,
    name: 'Approval',
    children: [
      {
        index: true,
        element: <ApprovalTable />,
      },
      {
        path: URL_APPROVAL_SELL,
        element: <TableRequestSell />,
      },
      {
        path: URL_APPROVAL_WITHDRAWAL,
        element: <TableRequestWithdrawal />,
      },
    ],
  },
  {
    path: URL_FARMERS,
    element: <FarmersWeb />,
    children: [
      {
        index: true,
        element: <FarmersTable />,
      },
      {
        path: URL_FARMERS_REGIST,
        element: <FormRegis />,
      },
      {
        path: URL_FARMERS_DETAIL,
        element: <DetailFarmers />,
      },
    ],
  },
  {
    element: <SettingView />,
    children: settingRoutes,
  },
]
