import { useState } from 'react'
import { Button, FormGroup, Input, Label, Modal, ModalBody } from 'reactstrap'
import lockIcon from 'assets/icons/lock-icon.png'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  setJustRolledOver,
  setMsgBanner,
  toggleBanner,
} from './talanginInvoiceSlice'
import {
  usePostCheckPassMutation,
  usePostPayTalanginMutation,
} from './talanginInvoiceApiSlice'
import { AlertCircle } from 'react-feather'
import { postRollover } from './talanginInvoiceAction'

const ModalPasswordInvoice = ({
  isOpen,
  toggle,
  toggleSidebar,
  row,
  isRollover = false,
}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [password, setPassword] = useState('')
  const [errMsg, setErrMsg] = useState('')
  const toggleForBanner = () => dispatch(toggleBanner())
  const [postCheckPass, result] = usePostCheckPassMutation()
  const [postPayTalangin, resultPay] = usePostPayTalanginMutation()
  const { detailRollover, selectedTenor } = useSelector(
    (s) => s.talanginInvoice,
  )

  const handleSubmit = async () => {
    try {
      const res = await postCheckPass({ password: password }).unwrap()
      if (res.success === 1) {
        if (isRollover) {
          const res = await dispatch(
            postRollover({
              loanDays: selectedTenor?.loanDays,
              creditRequested: selectedTenor?.creditRequested2,
              userpinjamanId: detailRollover?.id,
            }),
          )
          if (res.error) {
            throw res
          } else {
            navigate('/tagihan-talangin')
            toggle()
            dispatch(setJustRolledOver(detailRollover?.id))
            dispatch(setMsgBanner('Perpanjangan tempo pembayaran berhasil.'))
            toggleForBanner()
            setPassword('')
            setErrMsg('')
          }
        } else {
          const res2 = await postPayTalangin({
            userpinjamanId: row?.id,
          }).unwrap()
          if (res2) {
            toggle()
            toggleSidebar()
            dispatch(setMsgBanner('Pembayaran tagihan talangin selesai.'))
            toggleForBanner()
            setPassword('')
            setErrMsg('')
          }
        }
      }
    } catch (error) {
      setErrMsg(error?.payload ?? error?.data?.message)
    }
  }

  return (
    <>
      <Modal
        className="p-5 w-100"
        isOpen={isOpen}
        toggle={() => {
          toggle()
          setPassword('')
          setErrMsg('')
        }}
        centered
      >
        <ModalBody>
          <div className="mb-4 w-100">
            <div className="d-flex justify-content-center align-items-center mb-3">
              <img
                alt="password"
                src={lockIcon}
                style={{ width: '48px', height: '48px' }}
              />
            </div>
            <h6 className="text-center mb-3" style={{ fontWeight: 'bold' }}>
              Masukkan password untuk melanjutkan
            </h6>
            <FormGroup>
              <Label>Password</Label>
              <Input
                onChange={(e) => {
                  setPassword(e.target.value)
                  setErrMsg('')
                }}
                value={password}
                type="password"
              />
            </FormGroup>
            {errMsg && (
              <FormGroup>
                <small className="text-danger">
                  <AlertCircle size={15} /> {errMsg}
                </small>
              </FormGroup>
            )}
          </div>
          <div className="d-flex justify-content-between gap-3 w-100">
            <Button
              onClick={() => {
                toggle()
                setPassword('')
                setErrMsg('')
              }}
              block
              outline
              color="secondary"
            >
              Batal
            </Button>
            <Button
              onClick={() => {
                handleSubmit()
              }}
              block
              color="primary"
              disabled={!password || result?.isLoading || resultPay?.isLoading}
              type="submit"
            >
              Bayar
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

export default ModalPasswordInvoice
