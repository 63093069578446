export const tags = {
  request_buy: 'request_buy',
  request_sell: 'request_sell',
  request_withdrawal: 'request_withdrawal',
}

export const filterQuery = {
  [tags.request_buy]: 'Request buy',
  [tags.request_sell]: 'Request sell',
  [tags.request_withdrawal]: 'Request withdrawal',
}

// localStorage  ^^
export const TRANSACTION_SELL_OR_BUY = 'transaction_sell_or_buy'
export const APPROVAL_SELL = 'approval_sell'
export const APPROVAL_BUY = 'approval_buy'
export const APPROVAL_WD = 'approval_withdrawal'

export const LOCALSTORAGE_STATE = [
  TRANSACTION_SELL_OR_BUY,
  APPROVAL_SELL,
  APPROVAL_BUY,
  APPROVAL_WD,
]
