import { ButtonIcon } from 'components/Buttons'
import { useEffect } from 'react'
import { ButtonGroup, Col, Row } from 'reactstrap'

const HeaderTable = ({ type, abilityBuy, abilitySell, handleType }) => {
  useEffect(() => {
    if (type === undefined) {
      handleType(abilityBuy ? 'BUY' : abilitySell ? 'SELL' : '')
    }
  }, [type, abilityBuy, abilitySell, handleType])

  return (
    <Row className="w-100">
      <Col md="7">
        <div className="d-flex justify-content-start align-items-center mb-1">
          <ButtonGroup>
            {abilityBuy && (
              <ButtonIcon
                btnTitle="Pembelian"
                btnTitleProps={{
                  className: 'text-dark fw-medium fs-sm',
                }}
                btnProps={{
                  size: 'sm',
                  className: 'w-auto btn-dashboard-secondary',
                  color: 'secondary',
                  active: type === 'BUY',
                  onClick: () => {
                    handleType('BUY')
                  },
                }}
              />
            )}

            {abilitySell && (
              <ButtonIcon
                btnTitle="Penjualan"
                btnTitleProps={{
                  className: 'text-dark fw-medium fs-sm',
                }}
                btnProps={{
                  size: 'sm',
                  className: 'w-auto btn-dashboard-secondary',
                  color: 'secondary',
                  active: type === 'SELL',
                  onClick: () => {
                    handleType('SELL')
                  },
                }}
              />
            )}
          </ButtonGroup>
        </div>
      </Col>
      {/* <Col md="5">
    <div className="d-flex align-items-center  mb-1">
      <ButtonIcon
        btnTitle="Filter"
        icon={
          <img
            width="15px"
            height="10px"
            src={ICFilter}
            alt="icon"
            className="ml-2"
          />
        }
        btnProps={{
          className: 'w-auto d-flex me-2',
          size: 'sm',
          disabled: true,
        }}
      />
      <InputGroup className="input-group-merge" size="sm">
        <InputGroupText className="bg-transparent border-right-0">
          <Search size={18} />
        </InputGroupText>
        <Input
          className="d-flex align-items-center float-right border-left-0"
          type="text"
          bsSize="sm"
          id="search-input"
          placeholder="Cari"
          disabled
        />
      </InputGroup>
    </div>
  </Col> */}
    </Row>
  )
}

export default HeaderTable
