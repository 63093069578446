import axios from 'axios'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Badge,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  InputGroup,
  InputGroupText,
  Label,
  Row,
} from 'reactstrap'
import { apiEndpoint } from '../../utility/Utils'
import ComodityForm from './ComodityForm'
import Total from './Total'
import ModalUnverifSeller from './ModalUnverifSeller'
import { AlertCircle } from 'react-feather'
import pakTani from '../../assets/icons/Pak Tani.png'
import { useDispatch, useSelector } from 'react-redux'
import { setNumberStep } from './commodityPurchaseSlice'
import InputPhoneNumber from 'components/InputPhoneNumber'

const AddComodity = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { token } = useSelector((e) => e.auth)

  const [phoneNumber, setPhoneNumber] = useState('')
  const [dataSeller, setDataSeller] = useState('')
  const [userData] = useState({})
  const [isSet, setIsSet] = useState(false)
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [errMsgPhone, setErrMsgPhone] = useState('')
  const [errMsg, setErrMsg] = useState('')
  const [disable, setDisable] = useState(false)
  const toggleModal = () => {
    setIsOpenModal(!isOpenModal)
  }

  const handleCheckNumber = async () => {
    try {
      if (phoneNumber && dataSeller?.phoneNumber !== phoneNumber) {
        const { data } = await axios.get(
          `${apiEndpoint}/api/v1/bussines/transaction/checkuser?search=${phoneNumber}&type=BUY`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
        if (data?.status === 'REGISTERED') {
          setDataSeller(data)
        } else {
          setDataSeller('')
          toggleModal()
        }
      }
    } catch (error) {
      setErrMsgPhone(error.response.data.message)
    }
  }

  const getProfile = async () => {
    try {
      const { data } = await axios.get(
        `${apiEndpoint}/api/v1/bussines/profile`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      return data
    } catch (error) {
      console.log(error)
    }
  }

  const handleFinished = async (response) => {
    try {
      if (response) {
        const obj = {
          ...response,
          dataSeller: dataSeller,
        }
        localStorage.setItem('userData', JSON.stringify(obj))
        if (
          dataSeller?.status === 'UNREGISTERED' ||
          dataSeller?.profileStatus === 'UNVERIFIED' ||
          !dataSeller?.isLogin
        ) {
          const dataProfile = await getProfile()
          const obj = {
            userId: dataProfile?.ownerData?.userId,
            item: response?.dataComodities?.map((e) => {
              return {
                SKU: e?.comodity?.sku,
                nameItem: e?.comodity?.label,
                unit: e?.unit?.value,
                amount: Number(
                  e?.quantity
                    ?.replace?.(/\./g, '')
                    ?.replace?.(/,/g, '.')
                    ?.slice?.(0, 15),
                ),
                price: Number(
                  e?.price
                    ?.replace?.(/\./g, '')
                    ?.replace?.(/,/g, '.')
                    ?.slice?.(0, 15),
                ),
                imageUrl: e?.comodity?.img,
              }
            }),
            order: {
              type: 'BUY',
              note: response?.notes,
            },
            buyer: {
              fullName: dataProfile?.ownerData?.name,
              phoneNumber: dataProfile?.ownerData?.phoneNumber,
              userId: dataProfile?.ownerData?.userId,
            },
            seller: {
              fullName: dataSeller?.fullName,
              phoneNumber: dataSeller?.phoneNumber,
              bankCode: dataSeller?.bankCode,
              bankAccountName: dataSeller?.bankAccountName,
              bankAccountNumber: dataSeller?.bankAccountNumber,
              isLogin: dataSeller?.isLogin ?? false,
              ...(dataSeller?.status === 'REGISTERED' && {
                userId: dataSeller?.userId,
              }),
            },
          }
          const res = await axios.post(
            `${apiEndpoint}/api/v1/bussines/transaction/buy`,
            obj,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
          )
          if (res.status === 200) {
            const obj = {
              ...response,
              dataSeller: dataSeller,
              trxId: res?.data?.id,
            }
            localStorage.setItem('userData', JSON.stringify(obj))
            navigate('/transaksi-pembelian-pembayaran')
          }
        } else {
          const obj = {
            ...response,
            dataSeller: dataSeller,
          }
          localStorage.setItem('userData', JSON.stringify(obj))
          setIsSet(false)
          navigate('/transaksi-pembelian-pembayaran')
        }
      } else {
        console.log('tryagain')
        setIsSet(false)
      }
    } catch (error) {
      setIsSet(false)
      setErrMsg(error?.response?.data?.message)
    }
  }

  const handleFinishedCheckNumber = (resp) => {
    if (resp) {
      setDataSeller({
        ...resp,
        profileStatus: 'UNVERFIED',
        status: 'UNREGISTERED',
        bankLogo: resp?.bankData?.logo,
        bankCode: resp?.bankData?.code,
        phoneNumber: phoneNumber,
      })
      toggleModal()
    }
  }

  const handleCbData = (resp) => {
    try {
      const checkUnits = resp?.some((i) => {
        return (
          !Object.prototype.hasOwnProperty.call(i, 'comodity') ||
          !Object.prototype.hasOwnProperty.call(i, 'price') ||
          !Object.prototype.hasOwnProperty.call(i, 'quantity') ||
          !Object.prototype.hasOwnProperty.call(i, 'totalPrice') ||
          !Object.prototype.hasOwnProperty.call(i, 'unit')
        )
      })

      setDisable(
        checkUnits || resp?.reduce((sum, e) => sum + e.totalPrice, 0) < 50000,
      )
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    dispatch(setNumberStep(1))
  }, [dispatch])

  return (
    <div>
      <ModalUnverifSeller
        toggle={toggleModal}
        isOpen={isOpenModal}
        handleFinished={handleFinishedCheckNumber}
      />
      <Container fluid className="custom-container">
        <div className="d-flex flex-column gap-4">
          <Card>
            <CardBody className="p-4">
              <CardTitle tag="h5">Pembelian</CardTitle>
              <Form
                onSubmit={(e) => {
                  e.preventDefault()
                  handleCheckNumber()
                }}
              >
                <FormGroup className="mt-4">
                  <Label>Beli dari</Label>
                  <InputGroup>
                    <InputPhoneNumber
                      placeholder="Masukkan nomor handphone penjual"
                      value={phoneNumber}
                      setValue={(e) => {
                        setErrMsgPhone('')
                        setErrMsg('')
                        setPhoneNumber(e)
                      }}
                      name="phoneNumber"
                      id="phoneNumber"
                    />
                    <InputGroupText
                      onClick={handleCheckNumber}
                      className="bg-transparent"
                    >
                      Cek
                    </InputGroupText>
                  </InputGroup>
                  <span className="text-xs">
                    Contoh: 0813xxxx atau 62813xxxx
                  </span>
                </FormGroup>
              </Form>
              {errMsgPhone && (
                <small className="text-danger">
                  <AlertCircle size={15} /> {errMsgPhone}
                </small>
              )}
              {dataSeller && (
                <Card>
                  <CardBody>
                    <CardText tag="h6" className="text-sm fw-semibold">
                      Informasi penjual
                    </CardText>
                    <Row>
                      <Col>
                        <Card style={{ maxHeight: 68 }}>
                          <CardBody>
                            <div className="flex-centered justify-content-between">
                              <div className="flex-centered gap-1 ">
                                <img
                                  src={dataSeller?.profileImg ?? pakTani}
                                  alt="ava"
                                  width={36}
                                  height={35}
                                  className="rounded-circle"
                                />
                                <span className="text-sm fw-semibold">
                                  {dataSeller?.fullName}
                                </span>
                              </div>
                              <Badge
                                size="sm"
                                disabled
                                className={
                                  dataSeller?.profileStatus === 'VERIFIED'
                                    ? 'badge-verify'
                                    : 'badge-warning'
                                }
                              >
                                {dataSeller?.profileStatus === 'VERIFIED'
                                  ? 'Terverifikasi'
                                  : 'Belum Terverifikasi'}
                              </Badge>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col>
                        <Card style={{ maxHeight: 68 }}>
                          <CardBody>
                            <div className="d-flex justify-content-between flex-column">
                              <div className="flex-centered justify-content-start gap-1">
                                <img
                                  src={dataSeller?.bankLogo}
                                  alt="ava"
                                  width={18}
                                  height={18}
                                />
                                <span style={{ fontSize: 14 }}>
                                  {dataSeller?.bankAccountNumber}
                                </span>
                              </div>
                              <div
                                className="fw-semibold"
                                style={{ fontSize: 14 }}
                              >
                                {dataSeller?.bankAccountName}
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              )}
            </CardBody>
          </Card>
          <ComodityForm
            title={'Barang yang dibeli'}
            handleFinished={handleFinished}
            isSet={isSet}
            userData={userData}
            handleCbData={handleCbData}
          />
          {errMsg && (
            <small className="text-danger">
              <AlertCircle size={15} /> {errMsg}
            </small>
          )}
        </div>
      </Container>
      <Total
        text="Buat Transaksi"
        handleClick={() => {
          setIsSet(true)
        }}
        amount={userData?.dataComodities?.reduce(
          (sum, e) => sum + e.totalPrice,
          0,
        )}
        isDisable={!dataSeller || !userData || disable}
      />
    </div>
  )
}

export default AddComodity
