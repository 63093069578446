import { useAuthAccess } from 'hooks/useAuthAccess'
import NavbarDashboard from './components/Navbar'
import { Navigate, Outlet } from 'react-router-dom'
import useGetMyProfileAvaQuery from 'hooks/useGetMyProfileAvaQuery'
import NavbarSide from 'components/NavbarSide'

function App() {
  const { token, alreadychangepass, userNotPartnership } = useAuthAccess()
  const profile = useGetMyProfileAvaQuery()

  if (
    token === null ||
    (token !== null && !userNotPartnership && alreadychangepass === 0)
  ) {
    return <Navigate to="/login" replace />
  }

  return (
    <div
      style={{ minHeight: '100vh', width: '100%', overflow: 'auto' }}
      className="bg-light d-flex flex-column"
    >
      <NavbarDashboard color="white" expand="md" profile={profile} />
      <div style={{ flex: 1 }} className="bg-light d-flex">
        <NavbarSide />
        <Outlet context={profile} />
      </div>
    </div>
  )
}

export default App
