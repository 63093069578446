import axios from 'axios'
import AmountInput from 'components/AmountInput'
import moment from 'moment-timezone'
import { useEffect, useState } from 'react'
import { AlertCircle } from 'react-feather'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Container,
  FormGroup,
  InputGroup,
  InputGroupText,
  UncontrolledTooltip,
} from 'reactstrap'
import { apiEndpoint } from '../../../utility/Utils'
import Total from '../Total'
import { setNumberStep, setTotal } from '../commodityPurchaseSlice'
import { useFetchSaldoQuery } from 'features/private-views/dashboard/dashboardApiSlice'

const TalanginConfirm = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const intl = useIntl()
  const { token } = useSelector((e) => e.auth)
  const [tenor, setTenor] = useState('')
  const [dataTenor, setDataTenor] = useState({})
  const [amount, setAmount] = useState(null)
  const [totalAmount, setTotalAmount] = useState(0)
  const [userData, setUserData] = useState({})
  const [selectedTenor, setSelectedTenor] = useState(null)
  const [defaultAmount, setDefaultAmount] = useState(0)
  const { data } = useFetchSaldoQuery()
  const amountToPay =
    selectedTenor?.haveFreeTx > 0
      ? Number(!amount ? defaultAmount : amount) + Number(selectedTenor?.margin)
      : Number(!amount ? defaultAmount : amount) +
        Number(selectedTenor?.margin) +
        Number(selectedTenor?.feeTx)

  const fetchData = async (amount) => {
    try {
      const { data } = await axios.get(
        `${apiEndpoint}/api/v1/bussines/transaction/credit-tenor?amount=${totalAmount}&creditRequested=${
          Number(
            amount?.replace?.(/\./g, '')?.replace?.(/\,/g, '.')?.slice?.(0, 15),
          ) ?? 'MAX'
        }`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      setDataTenor(data)
      setDefaultAmount(data?.maxCreditLimit)
    } catch (error) {
      console.log(error)
    }
  }

  const handleSubmit = async () => {
    try {
      const obj = {
        totalAmount: userData?.dataComodities?.reduce(
          (sum, e) => sum + e.totalPrice,
          0,
        ),
        loanDays: selectedTenor?.loanDays,
        creditRequested: Number(
          amount?.replace?.(/\./g, '')?.replace?.(/\,/g, '.')?.slice?.(0, 15),
        ),
        item: userData?.dataComodities?.map((e) => {
          return {
            comodity: e?.comodity,
            amount: e?.quantity,
            price: e?.price,
            unit: e?.unit?.value,
          }
        }),
      }
      const resp = await axios.post(
        `${apiEndpoint}/api/v1/bussines/transaction/calculate`,
        obj,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      if (resp.status === 200) {
        navigate('/transaksi-pembelian-pembayaran-uang-belanja-konfirmasi', {
          state: {
            ...resp.data,
            talangin: true,
            selectedTenor: selectedTenor,
            dataTenor: dataTenor,
            maxCreditLimit: amount,
          },
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleChange = (data) => {
    if (data !== amount) {
      setAmount(data)
      if (data > 0) {
        fetchData(data)
      }
    }
  }

  useEffect(() => {
    if (totalAmount > 0) {
      fetchData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalAmount])

  useEffect(() => {
    dispatch(setNumberStep(2))
    const userData = JSON.parse(localStorage.getItem('userData'))
    setUserData(userData)
    setTotalAmount(
      userData?.dataComodities?.reduce((sum, e) => sum + e.totalPrice, 0),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    dispatch(setTotal(0))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Container fluid className="custom-container">
        <Card>
          <CardBody>
            <CardTitle>
              <div className="d-flex justify-content-between">
                <h6 className="fw-bold">Pakai talangin sebesar</h6>
                <AlertCircle
                  className="text-secondary"
                  size={20}
                  id="talangin"
                />
                <UncontrolledTooltip target="talangin">
                  <span>
                    Jumlah maksimal talangin untuk transaksi ini adalah di bawah{' '}
                    {dataTenor?.percentage ?? 0}% dari subtotal pembelian atau
                    total talangin anda.
                  </span>
                </UncontrolledTooltip>
              </div>
            </CardTitle>
            <FormGroup className="mt-3">
              <InputGroup>
                <InputGroupText className="bg-transparent">Rp</InputGroupText>
                {/* <Input
                  type="number"
                  onChange={(e) => {
                    setAmount(e.target.value)
                    fetchData(e.target.value)
                  }}
                  value={amount === 0 ? defaultAmount : amount}
                  // defaultValue={defaultAmount}
                /> */}
                <AmountInput
                  value={!amount ? defaultAmount : amount}
                  cb={handleChange}
                />
              </InputGroup>
              <small className="fw-light">
                Dari maks. talangin Rp{' '}
                {intl.formatNumber(dataTenor?.maxCreditLimit ?? 0, {
                  useGrouping: 'always',
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
              </small>
            </FormGroup>
            <CardTitle className="fw-bold mt-4 mb-3" tag="h6">
              Pilih tempo pembayaran
            </CardTitle>
            {dataTenor?.data?.map((e, index) => {
              return (
                <Card
                  key={index}
                  className="my-3"
                  onClick={() => {
                    setTenor(index)
                    setSelectedTenor(e)
                  }}
                  style={{
                    borderStyle: tenor === index ? 'solid' : '',
                    borderColor: tenor === index ? '#006386' : '',
                  }}
                >
                  <CardHeader
                    style={{
                      backgroundColor:
                        tenor === index ? '#ECF9FE' : 'transparent',
                    }}
                    className="fw-bold"
                  >
                    {e?.loanDays} Hari
                  </CardHeader>
                  <CardBody>
                    <div className="d-flex justify-content-between">
                      <span>Jatuh tempo:</span>
                      <span>
                        {moment(e?.duedate).locale('id').format('DD MMMM YYYY')}
                      </span>
                    </div>
                    <div className="d-flex justify-content-between">
                      <span>Total pembayaran talangin:</span>
                      <span className="fw-bold">
                        Rp
                        {intl.formatNumber(
                          e?.haveFreeTx > 0
                            ? Number(
                                Number(
                                  amount
                                    ?.replace?.(/\./g, '')
                                    ?.replace?.(/\,/g, '.')
                                    ?.slice?.(0, 15),
                                ) === 0
                                  ? defaultAmount
                                  : Number(
                                      amount
                                        ?.replace?.(/\./g, '')
                                        ?.replace?.(/\,/g, '.')
                                        ?.slice?.(0, 15),
                                    ),
                              ) + Number(e?.margin)
                            : Number(
                                Number(
                                  amount
                                    ?.replace?.(/\./g, '')
                                    ?.replace?.(/\,/g, '.')
                                    ?.slice?.(0, 15),
                                ) === 0
                                  ? defaultAmount
                                  : Number(
                                      amount
                                        ?.replace?.(/\./g, '')
                                        ?.replace?.(/\,/g, '.')
                                        ?.slice?.(0, 15),
                                    ),
                              ) +
                                Number(e?.margin) +
                                Number(e?.feeTx),
                          {
                            useGrouping: 'always',
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          },
                        )}
                      </span>
                    </div>
                  </CardBody>
                </Card>
              )
            })}
          </CardBody>
        </Card>
      </Container>
      <Total
        customButton={
          amountToPay > data?.wallet1 ? (
            <Button
              outline
              disabled={amount > dataTenor?.maxCreditLimit || !selectedTenor}
              onClick={() => navigate('/topup', { state: { talangin: true } })}
              color="primary"
            >
              Tambah Uang Belanja
            </Button>
          ) : (
            <Button
              disabled={amount > dataTenor?.maxCreditLimit || !selectedTenor}
              onClick={handleSubmit}
              color="primary"
            >
              Pakai Talangin
            </Button>
          )
        }
        customTotalText={
          amountToPay > data?.wallet1 ? (
            <span style={{ color: '#B42318' }}>
              Uang Belanja kurang{' '}
              {intl.formatNumber(amountToPay - data?.wallet1, {
                useGrouping: 'always',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </span>
          ) : (
            <div>
              <span className="fw-bold">Total yang dibayar: </span>
              <span>
                Rp
                {intl.formatNumber(0, {
                  useGrouping: 'always',
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
              </span>
            </div>
          )
        }
      />
    </>
  )
}

export default TalanginConfirm
