import { createAppSlice } from 'app/createAppSlice'
import {
  getListBanks,
  getListCity,
  getListDistrict,
  getListPostalCode,
  getListProvinces,
  getListSubdistrict,
} from './formsAction'

const initialState = {
  isOpenModal: false,
  payload: {},
  banks: [],
  provinces: [],
  cities: [],
  districts: [],
  subDistricts: [],
  postalCodes: [],
  urlSelfie: '',
  urlKtp: '',
  payloadOcr: null,
}

export const formFarmersSlice = createAppSlice({
  name: 'formFarmers',
  initialState,
  reducers: (create) => ({
    toggleModal: create.reducer((state, { payload }) => {
      state.isOpenModal = !state.isOpenModal
    }),
    setPayloadSlice: create.reducer((state, { payload }) => {
      state.payload = payload
    }),
    setPayloadOcr: create.reducer((state, { payload }) => {
      state.payloadOcr = payload
    }),
    setUrlSelfie: create.reducer((state, { payload }) => {
      state.urlSelfie = payload
    }),
    setUrlKtp: create.reducer((state, { payload }) => {
      state.urlKtp = payload
    }),
    clearState: create.reducer((state) => {
      state.isOpenModal = false
      state.payload = {}
      state.banks = []
      state.provinces = []
      state.cities = []
      state.districts = []
      state.subDistricts = []
      state.postalCodes = []
      state.urlSelfie = ''
      state.urlKtp = ''
      state.payloadOcr = null
    }),
  }),
  extraReducers: (builder) => {
    builder
      .addCase(getListBanks.pending, (state) => {
        state.loading = true
      })
      .addCase(getListBanks.fulfilled, (state, { payload }) => {
        state.loading = false
        state.banks = payload
      })
      .addCase(getListBanks.rejected, (state, { payload }) => {
        state.loading = false
      })
      .addCase(getListProvinces.pending, (state) => {
        state.loading = true
      })
      .addCase(getListProvinces.fulfilled, (state, { payload }) => {
        state.loading = false
        state.provinces = payload
      })
      .addCase(getListProvinces.rejected, (state, { payload }) => {
        state.loading = false
      })
      .addCase(getListCity.pending, (state) => {
        state.loading = true
      })
      .addCase(getListCity.fulfilled, (state, { payload }) => {
        state.loading = false
        state.cities = payload
      })
      .addCase(getListCity.rejected, (state, { payload }) => {
        state.loading = false
      })
      .addCase(getListDistrict.pending, (state) => {
        state.loading = true
      })
      .addCase(getListDistrict.fulfilled, (state, { payload }) => {
        state.loading = false
        state.districts = payload
      })
      .addCase(getListDistrict.rejected, (state, { payload }) => {
        state.loading = false
      })
      .addCase(getListSubdistrict.pending, (state) => {
        state.loading = true
      })
      .addCase(getListSubdistrict.fulfilled, (state, { payload }) => {
        state.loading = false
        state.subDistricts = payload
      })
      .addCase(getListSubdistrict.rejected, (state, { payload }) => {
        state.loading = false
      })
      .addCase(getListPostalCode.pending, (state) => {
        state.loading = true
      })
      .addCase(getListPostalCode.fulfilled, (state, { payload }) => {
        state.loading = false
        state.postalCodes = payload
      })
      .addCase(getListPostalCode.rejected, (state, { payload }) => {
        state.loading = false
      })
  },
})

export const {
  toggleModal,
  setPayloadSlice,
  setPayloadOcr,
  setUrlKtp,
  setUrlSelfie,
  clearState,
} = formFarmersSlice.actions

export default formFarmersSlice.reducer
