export const filterLatLong = (data) => {
  return data
    .map((location) => {
      if (location.plottingPoint && location.plottingPoint.coordinates) {
        return {
          lat: location.plottingPoint.coordinates[1],
          lng: location.plottingPoint.coordinates[0],
        }
      } else {
        console.error('Invalid location data:', location)
        return null
      }
    })
    .filter(Boolean)
}
