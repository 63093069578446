import { createAppSlice } from 'app/createAppSlice'

const initialState = {
  page: 0,
  search: '',
  size: 10,
  sortBy: undefined,
  sortDir: undefined,
  status: undefined,
}

export const talanginTableSlice = createAppSlice({
  name: 'talanginTable',
  initialState,
  reducers: (create) => ({
    setFilter: create.reducer((state, { payload }) => {
      Object.assign(state, payload)
    }),

    resetFilter: create.reducer((state) => {
      Object.assign(state, initialState)
    }),

    setPage: create.reducer((state, { payload }) => {
      Object.assign(state, {
        page: payload.page,
      })
    }),
  }),
})

export const { setFilter, setPage, resetFilter } = talanginTableSlice.actions
export default talanginTableSlice.reducer
