import { Can } from 'context/Acl'
import { useFetchApprovalCountQuery } from 'features/private-views/approval/approvalApiSlice'
import { setCountTag } from 'features/private-views/approval/approvalSlice'
import { toggleNavbar } from 'features/private-views/dashboard/dashboardSlice'
import { useEffect } from 'react'
import { ChevronLeft, ChevronRight } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import {
  Button,
  Container,
  Nav,
  NavItem,
  UncontrolledTooltip,
} from 'reactstrap'
import { BUSINESS_MYAPPROVAL } from 'utils/subjectActions'
import { menus } from './menus'

const NavbarSide = () => {
  const dispatch = useDispatch()
  const { data, isLoading, isSuccess } = useFetchApprovalCountQuery()
  const { isOpenNavbar } = useSelector((s) => s.dashboard)

  useEffect(() => {
    if (isSuccess) dispatch(setCountTag(data?.data))
  }, [dispatch, data, isSuccess])

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        dispatch(toggleNavbar())
      }
    }

    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [dispatch])

  return (
    <div
      style={{
        width: isOpenNavbar ? '280px' : '80px',
        height: 'auto',
        overflow: 'auto',
        whiteSpace: 'normal',
        wordBreak: 'break-word',
      }}
      className="bg-white border d-flex flex-column"
    >
      <Nav vertical className="list-unstyled pb-3">
        <Container>
          {menus?.map((e, i) => {
            return (
              <div key={i} className="d-flex flex-column gap-3 my-4">
                <Can I={e.action} this={e.subject}>
                  <small className="text-secondary fw-semibold">
                    {e.headTitle}
                  </small>
                </Can>
                {e?.items?.map((item) => (
                  <Can I={item.action} this={item.subject} key={item.path}>
                    <NavItem>
                      <NavLink
                        className={({ isActive }) =>
                          isActive
                            ? !isOpenNavbar
                              ? 'nav-active justify-content-center'
                              : 'nav-active'
                            : !isActive && !isOpenNavbar
                              ? 'nav-unactive justify-content-center'
                              : 'nav-unactive'
                        }
                        to={item?.path}
                        style={{
                          whiteSpace: 'normal',
                          wordBreak: 'break-word',
                        }}
                      >
                        {item?.icon}
                        {!isOpenNavbar ? (
                          <UncontrolledTooltip
                            placement="right"
                            target={item?.title
                              .toLowerCase()
                              ?.replace(' ', '-')}
                          >
                            {item?.title}
                          </UncontrolledTooltip>
                        ) : (
                          ''
                        )}
                        {isOpenNavbar ? <small>{item?.title}</small> : ''}
                        {item.subject === BUSINESS_MYAPPROVAL &&
                          isOpenNavbar && (
                            <span
                              style={{
                                height: 22,
                                width: 22,
                                textDecoration: 'none',
                              }}
                              className="rounded-circle badge-cancel flex-centered text-sm"
                            >
                              {isSuccess
                                ? data?.data
                                    ?.map((e) => e.total)
                                    ?.reduce((sum, e) => sum + e, 0)
                                : isLoading
                                  ? 0
                                  : 0}
                            </span>
                          )}
                      </NavLink>
                    </NavItem>
                  </Can>
                ))}
              </div>
            )
          })}
          <div className="d-flex justify-content-center">
            <Button
              size="sm"
              style={{
                width: '25px',
                height: '25px',
                boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
              }}
              outline
              className="rounded-circle d-flex flex-column align-items-center border-0"
              color="secondary"
              onClick={() => dispatch(toggleNavbar())}
            >
              {isOpenNavbar ? (
                <ChevronLeft size={25} />
              ) : (
                <ChevronRight size={25} />
              )}
            </Button>
          </div>
        </Container>
      </Nav>
    </div>
  )
}

export default NavbarSide
