import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { API_FARMERS_LIST } from 'utils/apiList'

const getFarmersDetail = createAsyncThunk(
  'farmersList/list',
  async ({ id }, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState()
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
      }
      const { data } = await axios.get(`${API_FARMERS_LIST}/${id}`, config)
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

export { getFarmersDetail }
