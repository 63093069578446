import { stepOrder } from 'features/private-views/purchase-commodity/purchaseCommoditySlice'
import { useAbilityAccess } from 'hooks/useAbilityAccess'
import { useState } from 'react'
import { Plus } from 'react-feather'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap'
import {
  BUSINESS_TRANSACTION_PURCHASE,
  BUSINESS_TRANSACTION_SELL,
  CREATE,
  MAKE,
} from 'utils/subjectActions'

const BtnMakeTransaction = ({ direction, ...props }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const toggle = () => setDropdownOpen((prevState) => !prevState)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const createSell = useAbilityAccess({
    action: CREATE,
    subject: BUSINESS_TRANSACTION_SELL,
  })
  const makeSell = useAbilityAccess({
    action: MAKE,
    subject: BUSINESS_TRANSACTION_SELL,
  })
  const createPurchase = useAbilityAccess({
    action: CREATE,
    subject: BUSINESS_TRANSACTION_PURCHASE,
  })
  const makePurchase = useAbilityAccess({
    action: MAKE,
    subject: BUSINESS_TRANSACTION_PURCHASE,
  })

  return (
    <>
      {(createSell || createPurchase || makeSell || makePurchase) && (
        <Dropdown isOpen={dropdownOpen} toggle={toggle} direction={direction}>
          <DropdownToggle color="primary" size="sm">
            <Plus size={16} /> Buat Transaksi
          </DropdownToggle>
          <DropdownMenu {...props}>
            {
              <>
                {(createPurchase || makePurchase) && (
                  <DropdownItem
                    onClick={() => {
                      if (createPurchase) {
                        navigate('/transaksi-pembelian-tambah-komoditas')
                      } else {
                        dispatch(
                          stepOrder({
                            step: 1,
                            type: 'BUY',
                          }),
                        )
                        navigate('/purchase-commodity')
                      }
                    }}
                  >
                    <span className="text-sm">Pembelian</span>
                  </DropdownItem>
                )}
              </>
            }
            {
              <>
                {(createSell || makeSell) && (
                  <DropdownItem onClick={() => navigate('/sell-commodity')}>
                    <span className="text-sm">Penjualan</span>
                  </DropdownItem>
                )}
              </>
            }
          </DropdownMenu>
        </Dropdown>
      )}
    </>
  )
}

export default BtnMakeTransaction
