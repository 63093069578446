// Path URL ERROR
export const URL_406 = '/unauthorized'

// Path URL PUBLIC
export const URL_FORGET_PASSWORD_STEP_1 = '/resetpassword'
export const URL_FORGET_PASSWORD_STEP_2 = '/resetpassword/email'
export const URL_FORGET_PASSWORD_STEP_4 = '/resetpassword/success'

export const URL_LOGIN = '/login'

// Path URL Private
export const HOME = '/'

// Path URL APPROVAL
export const URL_APPROVAL_BUY = 'approval-saya'
export const URL_APPROVAL_SELL = 'sell'
export const URL_APPROVAL_WITHDRAWAL = 'withdrawal'

// Path Url Farmers
export const URL_FARMERS = '/farmers'
export const URL_FARMERS_REGIST = '/farmers/registrasi'
export const URL_FARMERS_DETAIL = '/farmers/:id'
