import { Button, FormGroup, Modal, ModalBody } from 'reactstrap'
import {
  useApproveItemMutation,
  useApproveWithdrawMutation,
  useFetchApprovalCountQuery,
  useRejectItemMutation,
  useRejectWithdrawMutation,
} from '../approvalApiSlice'
import { useState } from 'react'
import { AlertCircle } from 'react-feather'

function ModalInfo(props) {
  const { isOpen, toggle, requestData, toggleSuccess, toggleReject } = props

  const [approveItem, { isLoading: isApproveItem }] = useApproveItemMutation()
  const [rejectItem, { isLoading: isRejectItem }] = useRejectItemMutation()
  const [approveWd, { isLoading: isApproveWd }] = useApproveWithdrawMutation()
  const [rejectWd, { isLoading: isRejectWd }] = useRejectWithdrawMutation()
  const [errMsg, setErrMsg] = useState('')
  const { refetch } = useFetchApprovalCountQuery()

  const handleSubmit = async () => {
    try {
      if (requestData?.type === 1) {
        await approveItem({
          requestId: requestData?.data?.map((e) => e.id),
        }).unwrap()
        toggleSuccess()
        refetch()
        toggle()
      }

      if (requestData?.type === null) {
        await rejectItem({
          requestId: requestData?.data?.map((e) => e.id),
        }).unwrap()
        toggleReject()
        refetch()
        toggle()
      }
      if (requestData?.type === 2) {
        await approveWd({
          requestId: requestData?.data?.map((e) => e.id),
        }).unwrap()
        toggleSuccess()
        refetch()
        toggle()
      }
      if (requestData?.type === 3) {
        await rejectWd({
          requestId: requestData?.data?.map((e) => e.id),
        }).unwrap()
        toggleReject()
        refetch()
        toggle()
      }
    } catch (error) {
      setErrMsg(error?.data?.message)
    }
  }

  const handleClose = () => {
    toggle()
    setErrMsg('')
  }
  return (
    <Modal
      isOpen={isOpen}
      toggle={handleClose}
      centered
      style={{ maxWidth: '450px' }}
      backdrop="static"
      keyboard={false}
    >
      <ModalBody>
        <div className="text-center w-100">
          <h5 className="fw-bold text-md text-start">
            {requestData?.type === 1 || requestData?.type === 2
              ? 'Approve'
              : requestData?.type === null || requestData?.type === 3
                ? 'Tolak'
                : ''}{' '}
            {requestData?.data?.length > 1 ? requestData?.data?.length : ''}{' '}
            Transaksi
          </h5>
          <p className="text-sm text-start">
            {requestData?.type === 1 || requestData?.type === 2
              ? `Transaksi akan dilanjutkan oleh ${requestData?.data?.length > 1 ? 'staff terkait' : requestData?.data?.[0]?.makerName}. Kamu yakin untuk
            approve transaksi ini?`
              : requestData?.type === null || requestData?.type === 3
                ? `Kamu yakin untuk
            tolak transaksi ini?`
                : ''}
          </p>
        </div>
        {errMsg && (
          <FormGroup>
            <small className="text-danger text-xs">
              <AlertCircle size={15} /> {errMsg}
            </small>
          </FormGroup>
        )}
        <div className="d-flex gap-2 w-100">
          <Button
            color="secondary"
            onClick={handleClose}
            block
            size="sm"
            className="p-2"
            disabled={
              isApproveItem || isRejectItem || isApproveWd || isRejectWd
            }
          >
            <span className="text-normal">Batal</span>
          </Button>
          <Button
            color="primary"
            onClick={() => handleSubmit()}
            block
            size="sm"
            className="p-2"
            disabled={
              isApproveItem || isRejectItem || isApproveWd || isRejectWd
            }
          >
            <span className="text-normal">
              {requestData?.type === 1 || requestData?.type === 2
                ? 'Approve'
                : requestData?.type === null || requestData?.type === 3
                  ? 'Tolak'
                  : ''}
            </span>
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ModalInfo
