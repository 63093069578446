import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { apiEndpoint } from 'configs'

const purchaseCommodityCheckUser = createAsyncThunk(
  'purchase/commodity/checkUser',
  async ({ search, type }, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState()
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
      }

      const { data } = await axios.get(
        `${apiEndpoint}/api/v1/bussines/transaction/checkuser?search=${search}&type=${type}`,
        config,
      )
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

const getUnits = createAsyncThunk(
  'purchase/commodity/units',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `https://kelud.pasarmikro.id:9443/api/v1/public`,
      )
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

const createOrderStaff = createAsyncThunk(
  'purchase/commodity/order/staff',
  async (payload, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState()
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
      }

      const { data } = await axios.post(
        `${apiEndpoint}/api/v1/bussines/transaction/buy`,
        payload,
        config,
      )

      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

const getComodities = createAsyncThunk(
  'purchase/commodity/comodities',
  async (_, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState()
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
      }
      const { data } = await axios.get(
        `${apiEndpoint}/api/v1/bussines/items?sortby=nameItem&sortdir=asc`,
        config,
      )
      if (data?.totalItems > data?.items.length) {
        const res = await axios.get(
          `${apiEndpoint}/api/v1/bussines/items?sortby=nameItem&sortdir=asc&size=${data?.totalItems}`,
          config,
        )
        const newData = res?.data?.items.map((e) => {
          return {
            value: e.id,
            label: e.nameItem,
            id: e.id,
            img: e.linkFoto,
            sku: e.SKU,
          }
        })
        return newData
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

// Export the functions
export { purchaseCommodityCheckUser, getUnits, createOrderStaff, getComodities }
