import {
  Button,
  Card,
  CardBody,
  CardText,
  Modal,
  ModalBody,
  Input,
  InputGroup,
  InputGroupText,
  FormGroup,
  Label,
  FormText,
  Spinner,
} from 'reactstrap'

import IcBtn from 'assets/icons/ic-btn-tukar-uang-usaha.svg'
import { useEffect, useRef, useState } from 'react'
import { AlertCircle } from 'react-feather'
import { useTransferDanaMutation } from '../withdrawApiSlice'
import { useIntl } from 'react-intl'
import { useFetchSaldoQuery } from 'features/private-views/dashboard/dashboardApiSlice'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setSuccessPindahUangUsaha } from '../withdrawSlice'

const SectionPindahUangUsaha = ({ wallet2, isLoading: loadingWallet }) => {
  const intl = useIntl()
  const nav = useNavigate()
  const dispatch = useDispatch()
  const [modal, setModal] = useState(false)
  const [amountVal, setAmountVal] = useState(0)
  const toggle = () => setModal(!modal)

  const [transferData] = useTransferDanaMutation()
  const { refetch } = useFetchSaldoQuery()

  const [isLoading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const handleTransferData = async () => {
    if (amountVal === '' || amountVal === 0 || amountVal === '0') {
      return console.log(amountVal)
    }

    let amount = amountVal?.replace(/\./g, '')?.replace(',', '.')

    try {
      setLoading(true)
      const data = await transferData(amount).unwrap()
      if (data.id) refetch()
      dispatch(
        setSuccessPindahUangUsaha({
          message: `Uang Usaha sebesar Rp${amount} berhasil dipindahkan ke Uang Belanja.`,
        }),
      )
      nav('/', { replace: true })
    } catch (error) {
      setErrorMessage(error?.data?.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Card>
        <CardBody className="p-4">
          <div className="d-flex justify-content-center flex-column">
            <CardText className="text-sm text-gray-600 mb-0 pb-0">
              Anda dapat memindahkan uang usaha ke uang belanja untuk transaksi
              selanjutnya.
            </CardText>
            <Button
              className="w-100 btn-color-uang-usaha"
              style={{ backgroundColor: 'transparent' }}
              onClick={toggle}
            >
              <img
                src={IcBtn}
                alt="icon"
                width={15}
                height={15}
                className="me-2"
              />
              <span className="text-sm text-gray-700 fw-semibold">
                Pindahkan Uang Usaha
              </span>
            </Button>
          </div>
        </CardBody>
      </Card>
      <Modal
        isOpen={modal}
        toggle={toggle}
        size="md"
        style={{ maxWidth: '400px' }}
        centered
        backdrop="static"
        keyboard={false}
      >
        <ModalBody className="d-flex flex-column gap-2">
          {/*  */}
          <div className="">
            <h1 className="text-md">Pindah Uang Usaha</h1>
          </div>
          {/*  */}
          <div className="d-flex gap-2 bg-warning-50 rounded w-100 px-2">
            <AlertCircle width={32} height={32} className="m-auto mt-0" />
            <span className="text-sm p-1">
              Uang usaha yang dipindahkan hanya bisa digunakan untuk transaksi
              dan tidak bisa dikembalikan.
            </span>
          </div>
          {errorMessage !== '' && (
            <span className="text-danger">{errorMessage}</span>
          )}
          <div className="">
            <FormGroup>
              <Label for="amount" className="text-sm">
                Pindahkan sebesar
              </Label>
              <InputCurrency
                id="amount"
                name="amount"
                setValue={setAmountVal}
                value={amountVal}
                disabled={isLoading}
                maxAmount={wallet2}
              />
              <FormText className="text-sm">
                Uang Usaha:{' '}
                {loadingWallet ? (
                  <Spinner size="sm">Loading...</Spinner>
                ) : wallet2 >= 0 ? (
                  `Rp${intl.formatNumber(wallet2, { useGrouping: 'always', maximumFractionDigits: 0 })}`
                ) : (
                  <Spinner size="sm">Loading...</Spinner>
                )}
              </FormText>
            </FormGroup>
          </div>
          {/*  */}
          <div className="d-flex w-100 gap-2">
            <Button
              onClick={() => {
                toggle()
                setAmountVal(0)
                setErrorMessage('')
              }}
              style={{ backgroundColor: 'transparent' }}
              className="btn-cancel w-50"
              disabled={isLoading}
            >
              Batal
            </Button>
            <Button
              color="primary"
              onClick={handleTransferData}
              className="w-50"
              disabled={
                isLoading ||
                amountVal === '' ||
                amountVal === 0 ||
                amountVal === '0'
              }
            >
              {isLoading ? (
                <Spinner size="sm">Loading...</Spinner>
              ) : (
                'Pindahkan'
              )}
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

export default SectionPindahUangUsaha

const InputCurrency = ({
  id,
  name,
  value = '0',
  setValue,
  maxAmount,
  ...args
}) => {
  const handleChange = (e) => {
    let inputValue = e.target.value.trim()
    let newValue = inputValue === '' ? '0' : inputValue
    newValue = newValue.replace(/[^\d]/g, '')

    const formattedValue = (args) => {
      return new Intl.NumberFormat('id-ID').format(args)
    }

    if (parseInt(newValue) > maxAmount) {
      setValue(formattedValue(maxAmount?.toFixed(0)))
    } else {
      setValue(formattedValue(newValue))
    }
  }

  const inputElement = useRef(null)
  useEffect(() => {
    if (inputElement.current !== null) {
      inputElement.current.focus()
    }
  }, [])

  return (
    <InputGroup>
      <InputGroupText
        style={{
          backgroundColor: 'transparent',
          borderRightColor: 'transparent',
        }}
        className="text-sm"
      >
        Rp
      </InputGroupText>
      <Input
        type="text"
        id={id}
        name={name}
        value={value}
        onChange={handleChange}
        style={{ borderLeftColor: 'transparent' }}
        autoComplete="off"
        innerRef={inputElement}
        {...args}
      />
    </InputGroup>
  )
}
