import { useEffect, useRef, useState } from 'react'
import { getLocalStorage } from 'utils/getLocalStorage'

export const useLocalStorageDashboard = (tag) => {
  const tagInit = useRef(getLocalStorage(tag))

  const [page, setPage] = useState(tagInit.current?.page ?? 0)
  const [size] = useState(tagInit.current?.size ?? 10)
  const [type, setType] = useState(tagInit.current?.type)

  const handlePrevPage = () => {
    setPage((prev) => prev - 1)
    tagInit.current = { ...tagInit.current, page: page - 1 }
  }

  const handleNextPage = () => {
    setPage((prev) => prev + 1)
    tagInit.current = { ...tagInit.current, page: page + 1 }
  }

  const handleType = (args) => {
    setType(() => args)
    setPage(() => 0)
    tagInit.current = { ...tagInit.current, type: args, page: 0 }
  }

  useEffect(() => {
    localStorage.setItem(
      tag,
      JSON.stringify({ page, size, type, ...tagInit.current }),
    )
  }, [tagInit, tag, page, size, type])

  return {
    options: { ...tagInit.current, page, size, type },
    handlePrevPage,
    handleNextPage,
    handleType,
  }
}
