import { Button, Card, CardBody, Container } from 'reactstrap'
import successTrx from '../../assets/images/success-trx.png'
import { Navigate, useNavigate } from 'react-router-dom'
import { useAbilityAccess } from 'hooks/useAbilityAccess'
import { URL_406 } from 'utils/pathUrl'

const SuccesTransaction = ({ action, subject }) => {
  const navigate = useNavigate()
  const abilityCreate = useAbilityAccess({ action, subject })

  const handleClick = () => {
    navigate('/')
  }

  if (!abilityCreate) {
    return <Navigate to={URL_406} replace={true} />
  }

  return (
    <Container fluid className="custom-container mt-5">
      <Card>
        <CardBody>
          <div className="d-flex justify-content-center">
            <img
              style={{ width: '280px', height: '182px' }}
              alt="success transaction"
              src={successTrx}
            />
          </div>
          <h6 style={{ fontWeight: 'bold' }} className="text-center mt-3">
            Pembayaran anda sudah diterima!
          </h6>
          <p className="text-center">
            Silakan tunggu penjual memproses pesanan anda.
          </p>
          <div className="d-flex justify-content-center">
            <Button onClick={handleClick} color="primary">
              Kembali ke Beranda
            </Button>
          </div>
        </CardBody>
      </Card>
    </Container>
  )
}

export default SuccesTransaction
