import { createAppSlice } from 'app/createAppSlice'

const initialState = {
  isLoading: false,
  teams: [],
  teamTable: {
    page: 0,
    search: '',
    size: 10,
    sortBy: undefined,
    sortDir: undefined,
  },
  name: '',
  isEdit: false,
}

export const settingSlice = createAppSlice({
  name: 'setting/slice',
  initialState,
  reducers: (create) => ({
    setPage: create.reducer((state, { payload }) => {
      Object.assign(state.teamTable, {
        page: payload.page,
      })
    }),
    setFilter: create.reducer((state, { payload }) => {
      Object.assign(state.teamTable, payload)
    }),
    setIsEdit: create.reducer((state, { payload }) => {
      state.isEdit = payload
    }),
  }),
  extraReducers: (builder) => {},
})

export const { setPage, setFilter, toggleSidebarProfile, setIsEdit } =
  settingSlice.actions
export default settingSlice.reducer
