export const sessionLifetime = process.env.REACT_APP_SESSION_LIFETIME ?? 120

export function setSession() {
  const now = new Date()
  return now.getTime() + sessionLifetime * 60000
}

export function checkSession(times) {
  const now = new Date()
  if (!times) {
    return null
  }
  if (now.getTime() > times) {
    return null
  }
  return times
}
