import { logout } from 'features/public-views/login/loginSlice'
import PropTypes from 'prop-types'
import { createContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { checkSession } from 'utils/setSession'

export const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
  const {
    token,
    session: s,
    alreadychangepass,
    userNotPartnership,
  } = useSelector((s) => s.auth)
  const dispatch = useDispatch()
  let session = checkSession(s)

  useEffect(() => {
    if (!session) {
      dispatch(logout())
    }
  }, [session, dispatch])

  return (
    <AuthContext.Provider
      value={{ token, session, alreadychangepass, userNotPartnership }}
    >
      {children}
    </AuthContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.node,
}
