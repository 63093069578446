import { Card, CardBody, CardTitle } from 'reactstrap'
import LogoPasarMikro from 'assets/images/pmg-logo-beta.png'

const SuccessVerifyAccount = () => {
  return (
    <>
      <div
        className="bg-light d-flex justify-content-center align-items-start "
        style={{ minHeight: '100vh' }}
      >
        <Card style={{ marginTop: '75px', width: '45%' }}>
          <CardBody>
            <div className="d-flex justify-content-center align-items-center mb-3">
              <img
                src={LogoPasarMikro}
                alt="PasarMIKRO logo"
                style={{ width: '100%', maxWidth: '177px', height: 'auto' }}
              />
            </div>
            <CardTitle
              className="d-flex justify-content-center align-items-center mb-4 text-center"
              tag="h2"
            >
              Silakan cek email anda secara berkala. Anda akan diberi akses ke
              PasarMIKRO Business.
            </CardTitle>
          </CardBody>
        </Card>
      </div>
    </>
  )
}

export default SuccessVerifyAccount
