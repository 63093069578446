import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { apiEndpoint } from 'utility/Utils'
export const publicApiDevSlice = createApi({
  reducerPath: 'public/api/dev',
  baseQuery: fetchBaseQuery({
    baseUrl: apiEndpoint,
    prepareHeaders(headers) {
      headers.set('Content-Type', 'application/json')
      return headers
    },
  }),
  endpoints(builder) {
    return {
      fetchUnitsDev: builder.query({
        query() {
          return `/api/v1/public/version`
        },
      }),
    }
  },
})

export const publicApiSlice = createApi({
  reducerPath: 'public/api',
  baseQuery: fetchBaseQuery({
    baseUrl: apiEndpoint,
    prepareHeaders(headers) {
      headers.set('Content-Type', 'application/json')
      return headers
    },
  }),
  endpoints(builder) {
    return {
      fetchUnits: builder.query({
        query() {
          return `/api/v1/public/version`
        },
      }),
    }
  },
})

export const { useFetchUnitsQuery } = publicApiSlice
export const { useFetchUnitsDevQuery } = publicApiDevSlice
