import { useContext } from 'react'
import { AuthContext } from 'context/Auth'

export const useAuthAccess = () => {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return context
}
