import { createApiSlice } from 'app/createApiSlice'
import { API_FARMERS_LIST, API_FARMERS_PLOTTING } from 'utils/apiList'

export const farmersListApiSlice = createApiSlice.injectEndpoints({
  reducerPath: 'farmersList/api',
  endpoints(builder) {
    return {
      fetchFarmers: builder.query({
        query() {
          return API_FARMERS_LIST
        },
      }),
      fetchFarmersDetail: builder.query({
        query({ id }) {
          return `${API_FARMERS_LIST}/${id}`
        },
      }),
      fetchFarmersPlot: builder.query({
        query({ id }) {
          return `${API_FARMERS_PLOTTING}?userId=${id}`
        },
      }),
    }
  },
})

export const {
  useFetchFarmersQuery,
  useFetchFarmersDetailQuery,
  useFetchFarmersPlotQuery,
} = farmersListApiSlice
