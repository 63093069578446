import { Button, Card, CardBody, CardText, Container } from 'reactstrap'
import successTrx from 'assets/images/success-trx.png'
import { useAbilityAccess } from 'hooks/useAbilityAccess'
import { URL_406 } from 'utils/pathUrl'
import { Navigate } from 'react-router-dom'

const SuccessTrx = ({ action, subject }) => {
  const abilityCreate = useAbilityAccess({ action, subject })

  const handleClick = () => {
    window.location.href = '/'
  }

  if (!abilityCreate) {
    return <Navigate to={URL_406} replace={true} />
  }

  return (
    <Container fluid className="custom-container mt-5">
      <Card>
        <CardBody>
          <div className="d-flex justify-content-center">
            <img
              style={{ width: '280px', height: '182px' }}
              alt="success transaction"
              src={successTrx}
            />
          </div>
          <div className="w-75 mx-auto my-3">
            <CardText tag="p" className="text-center">
              Transaksi akan dilanjutkan jika pembeli sudah mengkonfirmasi dan
              melakukan pembayaran.
            </CardText>
          </div>
          <div className="d-flex justify-content-center">
            <Button onClick={handleClick} color="primary">
              Kembali ke Beranda
            </Button>
          </div>
        </CardBody>
      </Card>
    </Container>
  )
}

export default SuccessTrx
