import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { Button, Container } from 'reactstrap'

const Total = ({
  text,
  handleClick,
  isDisable = false,
  customTotalText,
  customButton,
}) => {
  const intl = useIntl()
  const { total } = useSelector((s) => s.comodityPurchase)

  return (
    <div className="py-5">
      <div className="bg-white fixed-bottom border-top border-2 p-3">
        <Container className="custom-container">
          <div className="d-flex justify-content-between align-items-center">
            {customTotalText ? (
              customTotalText
            ) : (
              <div>
                <span className="fw-bold">Total yang dibayar: </span>
                <span>
                  Rp
                  {intl.formatNumber(isNaN(total) ? 0 : total, {
                    useGrouping: 'always',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                </span>
                {total > 0 && total < 50000 && (
                  <div className="text-sm text-danger">(Minimal Rp50.000)</div>
                )}
              </div>
            )}
            {customButton ? (
              customButton
            ) : (
              <Button
                disabled={isDisable}
                onClick={handleClick}
                color="primary"
              >
                {text}
              </Button>
            )}
          </div>
        </Container>
      </div>
    </div>
  )
}

export default Total
