import { createApiSlice } from 'app/createApiSlice'

export const purchaseCommodityApiSlice = createApiSlice.injectEndpoints({
  reducerPath: 'commodityList',

  endpoints(builder) {
    return {
      fetchCheckUser: builder.query({
        query({ search, type }) {
          return {
            url: '/api/v1/bussines/transaction/checkuser',
            params: { search, type },
          }
        },
      }),
      fetchCommodity: builder.query({
        query() {
          return {
            url: '/api/v1/bussines/items?sortby=nameItem&sortdir=asc&size=10000',
          }
        },
        transformResponse(response, meta, arg) {
          const newData = response?.items.map((e) => {
            return {
              value: e.id,
              label: e.nameItem,
              id: e.id,
              img: e.linkFoto,
              sku: e.SKU,
            }
          })
          return newData
        },
      }),
    }
  },
})

export const { useFetchCheckUserQuery, useFetchCommodityQuery } =
  purchaseCommodityApiSlice
