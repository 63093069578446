import { useEffect, useMemo, useState } from 'react'
import { useGetUserAvatarQuery as getAva } from 'features/private-views/settings/settingApiSlice'
import { useFetchTalanginInvoiceQuery as getTalanginList } from 'features/private-views/talangin-invoice/talanginInvoiceApiSlice'

const useGetTrxTalanginQuery = (options, args) => {
  let [userId, setUserId] = useState([])

  const trxResult = getTalanginList(options, { args })
  const avaResult = getAva(userId, { skip: userId.length === 0 })

  const data = useMemo(() => {
    const getSellerAva = (trx) => {
      let result = null

      for (let i = 0; i < avaResult.data?.data?.length; i++) {
        const avaUser = avaResult.data?.data[i]
        if (avaUser.userId === trx?.escrowpayment?.seller?.userId) {
          result = avaUser.link
        }
      }
      return result
    }
    const getPaidOffUserAvatar = (trx) => {
      let result = null

      for (let i = 0; i < avaResult.data?.data?.length; i++) {
        const avaUser = avaResult.data?.data[i]
        if (avaUser.userId === trx?.pmbactions?.userId) {
          result = avaUser.link
        }
      }
      return result
    }

    return {
      ...trxResult,
      data: {
        ...trxResult.data,
        items: trxResult?.data?.items.map((trx) => ({
          ...trx,
          sellerAvatar: getSellerAva(trx),
          paidOffUserAvatar: getPaidOffUserAvatar(trx),
        })),
      },
    }
  }, [trxResult, avaResult])

  useEffect(() => {
    function uniq_fast(a) {
      let seen = {}
      let out = []
      let len = a?.length
      let j = 0

      for (let i = 0; i < len; i++) {
        let sellerId = a[i]?.escrowpayment?.seller?.userId
        let paidOffUserId = a[i]?.pmbactions?.userId

        if (seen[sellerId] !== 1) {
          seen[sellerId] = 1
          out[j++] = sellerId
        }

        if (seen[paidOffUserId] !== 1) {
          seen[paidOffUserId] = 1
          out[j++] = paidOffUserId
        }
      }
      return out.filter((e) => e !== undefined)
    }
    setUserId(uniq_fast(trxResult?.data?.items))
  }, [trxResult])

  return data
}

export default useGetTrxTalanginQuery
