import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { apiEndpoint } from 'configs'

const getRequestApproval = createAsyncThunk(
  'approval/getRequest',
  async ({ filter, page = 0, size = 10 }, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState()
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
      }

      const { data } = await axios.get(
        `${apiEndpoint}/api/v1/bussines/approval?page=${page}&size=${size}&filter=${filter}&status=pending`,
        config,
      )
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

const getApprovalCount = createAsyncThunk(
  'approval/getCount',
  async ({ filter, page = 0, size = 10 }, { rejectWithValue, getState }) => {
    try {
      const { auth } = getState()
      let config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`,
        },
      }

      const { data } = await axios.get(
        `${apiEndpoint}/api/v1/bussines/approvalcount?filter=${filter}&status=pending`,
        config,
      )
      return data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

export { getRequestApproval, getApprovalCount }
