import RoundedComponent from 'components/Rounded'
import { useSelector } from 'react-redux'
import { Col } from 'reactstrap'

const HeaderItem = ({ title, numberStep, hideDivider }) => {
  const { orders } = useSelector((state) => state.purchaseCommmodity)

  return (
    <>
      <>
        <Col className="d-flex align-items-center">
          <RoundedComponent
            childern={<span className="text-white">{numberStep}</span>}
            className={`${
              orders.step === numberStep ? 'bg-primary' : 'bg-secondary'
            } rounded-circle flex-centered`}
            style={{ width: '30px', height: '30px' }}
          />
          <small
            className={`mx-2 ${orders.step === numberStep ? 'fw-bold' : ''} text-truncate`}
            style={{ fontSize: '0.8em' }}
          >
            {title}
          </small>
        </Col>
        {!hideDivider && (
          <Col className="d-none d-md-block">
            <hr className="border border-dark border-1 opacity-100 my-0" />
          </Col>
        )}
      </>
    </>
  )
}

export default HeaderItem
