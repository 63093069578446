import { createAppSlice } from 'app/createAppSlice'
import { tags } from 'utils/tags'
import { getApprovalCount, getRequestApproval } from './approvalApiAction'

const initialState = {
  isLoading: false,
  isOpenModal: false,
  item: null,
  tag: tags.request_buy,
  data: {
    [tags.request_buy]: null,
    [tags.request_sell]: null,
    [tags.request_withdrawal]: null,
  },
  countData: {
    [tags.request_buy]: null,
    [tags.request_sell]: null,
    [tags.request_withdrawal]: null,
  },
  isOpenBannerReject: false,
  isRefetch: false,
}

export const approvalSlice = createAppSlice({
  name: 'approval',
  initialState,
  reducers: (create) => ({
    selectedData: create.reducer((state, { payload }) => {
      state.item = payload
    }),
    unSelectedData: create.reducer((state) => {
      state.item = null
    }),
    setOpenModal: create.reducer((state) => {
      state.isOpenModal = !state.isOpenModal
    }),
    setTag: create.reducer((state, { payload }) => {
      state.tag = payload
    }),
    toggleBannerReject: create.reducer((state) => {
      state.isOpenBannerReject = !state.isOpenBannerReject
    }),
    setIsRefetch: create.reducer((state, { payload }) => {
      state.isRefetch = payload
    }),
    setCountTag: create.reducer((state, { payload }) => {
      if (payload.length > 0) {
        payload.forEach((e) => {
          if (e.description === 'Request buy') {
            state.countData[tags.request_buy] = e.total ?? 0
          }
          if (e.description === 'Request sell') {
            state.countData[tags.request_sell] = e.total ?? 0
          }
          if (e.description === 'Request withdrawal') {
            state.countData[tags.request_withdrawal] = e.total ?? 0
          }
        })
      } else {
        Object.values(tags).map((e) => (state.countData[e] = 0))
      }
    }),
  }),
  extraReducers: (builder) => {
    builder
      .addCase(getRequestApproval.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getRequestApproval.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.data[state.tag] = payload
      })
      .addCase(getRequestApproval.rejected, (state) => {
        state.isLoading = false
      })

      .addCase(getApprovalCount.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getApprovalCount.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.countData[state.tag] = payload
      })
      .addCase(getApprovalCount.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export const {
  selectedData,
  unSelectedData,
  setOpenModal,
  setTag,
  toggleBannerReject,
  setIsRefetch,
  setCountTag,
} = approvalSlice.actions
export default approvalSlice.reducer
