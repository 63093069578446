import { createAppSlice } from 'app/createAppSlice'

const initialState = {
  isOpenBanner: false,
  isOpenModalChangeProfile: false,
}

export const detailFarmersSlice = createAppSlice({
  name: 'detailFarmers',
  initialState,
  reducers: (create) => ({
    toggleBannerDetail: create.reducer((state, { payload }) => {
      state.isOpenBanner = !state.isOpenBanner
    }),
    toggleModalChangeProfile: create.reducer((state, { payload }) => {
      state.isOpenModalChangeProfile = !state.isOpenModalChangeProfile
    }),
  }),
  extraReducers: (builder) => {},
})

export const { toggleModalChangeProfile, toggleBannerDetail } =
  detailFarmersSlice.actions

export default detailFarmersSlice.reducer
