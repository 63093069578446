import PropTypes from 'prop-types'
import { Button, Card, CardBody, CardHeader } from 'reactstrap'
//
import { formatNumber } from '../../utils/formatNumber'
import { ButtonIcon } from '../Buttons'
import LoadingAnimation from 'components/LoadingAnimation'

const CardBankAccount = (props) => {
  const {
    title = 'No Title',
    bankAmount = 0,
    btnTitle = 'No Button Title',
    iconCard,
    btnIcon,
    isLoading,
    isSuccess,
    handleClick,
    isDisable,
    isProcess,
    processMsg,
  } = props

  return (
    <Card className="mb-3">
      <CardHeader
        style={{ backgroundColor: 'transparent', borderBottom: 'none' }}
      >
        <div className="d-flex align-items-center" style={{ gap: '16px' }}>
          {iconCard && (
            <img
              src={iconCard}
              style={{ width: '36px', height: '36px' }}
              alt={title}
            />
          )}
          <div className="d-flex flex-column">
            <span className="fw-medium">{title}</span>
            <span className="text-primary fw-bold">
              Rp
              {isLoading ? (
                <LoadingAnimation size="sm" />
              ) : isSuccess ? (
                formatNumber(bankAmount)
              ) : (
                <LoadingAnimation size="sm" />
              )}
            </span>
          </div>
        </div>
      </CardHeader>
      <CardBody>
        {isProcess ? (
          <Button
            block
            style={{
              borderColor: '#FEB273',
              backgroundColor: '#FFF6ED',
              color: 'black',
            }}
            onClick={handleClick}
          >
            <div className="d-flex justify-content-between">
              <span>{processMsg}</span>
              <span>Cek</span>
            </div>
          </Button>
        ) : (
          <ButtonIcon
            btnTitle={btnTitle}
            icon={btnIcon}
            btnProps={{
              disabled: isDisable ?? true,
              onClick: handleClick,
            }}
          />
        )}
      </CardBody>
    </Card>
  )
}

CardBankAccount.propTypes = {
  title: PropTypes.string,
  bankAmount: PropTypes.number,
  iconCard: PropTypes.string,
  btnTitle: PropTypes.string,
  btnIcon: PropTypes.object,
  handleClick: PropTypes.func,
  isDisable: PropTypes.bool,
}
export default CardBankAccount
