import { AbilityContext } from 'context/Acl'
import { useContext } from 'react'

// ** Hooks
export const useAbilityAccess = ({ action, subject }) => {
  const context = useContext(AbilityContext)

  if (context === undefined) {
    throw new Error('useAbility must be used within an AbilityProvider')
  }

  if (context.can(action, subject)) {
    return true
  }
  return false
}
