import { Navigate, Outlet } from 'react-router-dom'
import { Container } from 'reactstrap'
import NavigationBar from './NavigationBar'
import { useAbilityAccess } from 'hooks/useAbilityAccess'
import { URL_406 } from 'utils/pathUrl'

const Transactions = ({ action, subject }) => {
  const abilityCreate = useAbilityAccess({ action, subject })

  return (
    <div className="w-100">
      <Container fluid className="custom-container">
        <NavigationBar />
      </Container>
      {abilityCreate ? <Outlet /> : <Navigate to={URL_406} replace={true} />}
    </div>
  )
}

export default Transactions
