import BcaLogo from 'assets/images/BCA-01.png'
import BriLogo from 'assets/images/BRI-01.png'
import { useAbilityAccess } from 'hooks/useAbilityAccess'
import { useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { AlertTriangle, ChevronDown, ChevronUp } from 'react-feather'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Collapse,
  Container,
  FormGroup,
  Label,
} from 'reactstrap'
import {
  setBannerType,
  setMessageBanner,
  toggleBanner,
} from '../dashboard/dashboardSlice'
import ModalSuccess from './ModalSuccess'
import { confrimBri } from './topupAction'
import {
  useCancelBcaUniqueCodeMutation,
  useCancelBcaVaMutation,
  useCancelBriVaMutation,
  useFetchPendingBcaQuery,
  useFetchPendingVaBcaQuery,
} from './topupApiSlice'
import { setInitStateTopup, toggleModalPending } from './topupSlice'
import ModalPendingTopup from './ModalPendingTopup'
import { URL_406 } from 'utils/pathUrl'

const TransferBank = ({ action, subject }) => {
  const abilityCreate = useAbilityAccess({ action, subject })

  const intl = useIntl()
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const dataState = location.state
  const [cancelBcaUniqueCode] = useCancelBcaUniqueCodeMutation()
  const [cancelBcaVa] = useCancelBcaVaMutation()
  const [cancelBriVa] = useCancelBriVaMutation()
  const { refetch: refetchBca, data: dataBca } = useFetchPendingBcaQuery()
  const { refetch: refetchBcaVa, data: dataBcaVa } = useFetchPendingVaBcaQuery()
  const [loading, setLoading] = useState(false)
  const [isOpenModalSuccess, setIsOpenModalSuccess] = useState(false)
  const toggleModalSuccess = () => {
    setIsOpenModalSuccess(!isOpenModalSuccess)
  }
  const { instructionsBca, instructionsBri } = useSelector((s) => s.topup)
  const instructions =
    dataState?.bankCodeReceiver === 'BCA' ? instructionsBca : instructionsBri

  const [isOpen, setIsOpen] = useState(
    Array(instructionsBca.length).fill(false),
  )

  const toggle = (index) => {
    const newIsOpen = [...isOpen]
    newIsOpen[index] = !newIsOpen[index]
    setIsOpen(newIsOpen)
  }

  const formattedUniqueCode = () => {
    const uniqueCode = dataState?.amountPlusUniqueCode - dataState?.amount
    const uniqueCodeLength = uniqueCode?.toString().length
    return uniqueCodeLength === 1
      ? `00${uniqueCode}`
      : uniqueCodeLength === 2
        ? `0${uniqueCode}`
        : uniqueCode
  }

  const handleCancel = async () => {
    try {
      setLoading(true)
      const obj = {
        customerCode: dataState?.customerCode,
      }
      let response
      if (dataState?.isVa) {
        if (dataState?.bankCodeReceiver === 'BCA') {
          response = await cancelBcaVa(obj).unwrap()
        } else {
          response = await cancelBriVa(obj).unwrap()
        }
      } else {
        response = await cancelBcaUniqueCode(obj).unwrap()
      }
      if (response?.status === 'CANCELED') {
        dispatch(setInitStateTopup())
        navigate('/topup', { state: { fetchPending: true } })
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const handleDoneTransfer = () => {
    if (dataState?.isVa && dataState?.bankCodeReceiver === 'BRI') {
      dispatch(confrimBri())
    } else if (dataState?.rollover || dataState?.trx || dataState?.talangin) {
      toggleModalSuccess()
    } else {
      if (dataState.isVa && dataState?.bankCodeReceiver === 'BCA') {
        refetchBcaVa()
        if (dataBcaVa) {
          dispatch(toggleModalPending())
        } else {
          navigate('/')
          dispatch(setBannerType('success'))
          dispatch(
            setMessageBanner(
              `Uang Belanja sebesar Rp${intl.formatNumber(dataState?.amount, {
                useGrouping: 'always',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })} berhasil ditambahkan.`,
            ),
          )
          dispatch(toggleBanner())
        }
      } else {
        refetchBca()
        if (dataBca) {
          dispatch(toggleModalPending())
        } else {
          navigate('/')
          dispatch(setBannerType('success'))
          dispatch(
            setMessageBanner(
              `Uang Belanja sebesar Rp${intl.formatNumber(dataState?.amount, {
                useGrouping: 'always',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })} berhasil ditambahkan.`,
            ),
          )
          dispatch(toggleBanner())
        }
      }
    }
  }

  if (!abilityCreate) {
    return <Navigate to={URL_406} replace={true} />
  }

  return (
    <Container className="custom-container pb-5 mt-4">
      <ModalSuccess isOpen={isOpenModalSuccess} toggle={toggleModalSuccess} />
      <ModalPendingTopup />
      <Card>
        <CardBody>
          <CardTitle className="fw-bold">
            Transfer ke {dataState?.isVa ? 'Virtual Account' : 'rekening'}
          </CardTitle>
          <div className="d-flex flex-row gap-2 mb-3">
            <Card
              style={{
                width: '5rem',
                padding: '8px',
              }}
              className="bg-light"
            >
              <img
                alt={dataState?.bankCodeReceiver === 'BCA' ? 'bca' : 'bri'}
                src={dataState?.bankCodeReceiver === 'BCA' ? BcaLogo : BriLogo}
              />
            </Card>
            <div className="d-flex flex-column justify-content-center">
              <span className="fw-bold" style={{ color: '#2B7D9A' }}>
                Bank {dataState?.bankCodeReceiver}
              </span>
              <span className="text-secondary">
                {dataState?.bankAccountNameReceiver}
              </span>
            </div>
          </div>
          <div>
            <FormGroup>
              <Label>
                Nomor {dataState?.isVa ? 'Virtual Account' : 'rekening'}
              </Label>
              <div
                className="bg-light rounded d-flex justify-content-between align-items-center px-2 border"
                style={{ height: '60px' }}
              >
                <span style={{ color: '#2B7D9A', fontWeight: '600' }}>
                  {!dataState?.isVa
                    ? dataState?.bankAccountNumberReceiver
                    : dataState?.bankCodeReceiver === 'BCA'
                      ? `14359${dataState?.customerCode}`
                      : `10441${dataState?.customerCode}`}
                </span>
                <CopyToClipboard
                  onCopy={() => {}}
                  text={
                    !dataState?.isVa
                      ? dataState?.bankAccountNumberReceiver
                      : dataState?.bankCodeReceiver === 'BCA'
                        ? `14359${dataState?.customerCode}`
                        : `10441${dataState?.customerCode}`
                  }
                >
                  <Button
                    style={{ backgroundColor: '#FFFFFF', color: '#344054' }}
                  >
                    Salin
                  </Button>
                </CopyToClipboard>
              </div>
            </FormGroup>
          </div>
          <div>
            <FormGroup>
              <Label>Jumlah transfer</Label>
              <div
                className="bg-light rounded d-flex justify-content-between align-items-center px-2 border"
                style={{ height: '60px' }}
              >
                <span style={{ color: '#2B7D9A', fontWeight: '600' }}>
                  Rp
                  {dataState?.amountPlusUniqueCode ? (
                    <>
                      {intl.formatNumber(
                        Number(dataState?.amount?.toString().slice(0, -3)),
                        {
                          useGrouping: 'always',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        },
                      )}
                      <span style={{ color: '#DC6803' }}>
                        .{formattedUniqueCode()}
                      </span>
                    </>
                  ) : (
                    intl.formatNumber(Number(dataState?.amount), {
                      useGrouping: 'always',
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })
                  )}
                </span>

                <CopyToClipboard
                  onCopy={() => {}}
                  text={
                    dataState?.amountPlusUniqueCode
                      ? dataState?.amountPlusUniqueCode
                      : dataState?.amount
                  }
                >
                  <Button
                    style={{ backgroundColor: '#FFFFFF', color: '#344054' }}
                  >
                    Salin
                  </Button>
                </CopyToClipboard>
              </div>
              {!dataState?.isVa && (
                <span style={{ color: '#DC6803' }}>
                  <AlertTriangle size={20} />{' '}
                  <small>
                    Pastikan nilai transfer sesuai jumlah tertera (termasuk tiga
                    digit terakhir)
                  </small>
                </span>
              )}
            </FormGroup>
          </div>
          <CardTitle className="fw-bold">Cara pembayaran</CardTitle>
          <div className="d-flex flex-column gap-2">
            {instructions?.map((e, i) => {
              return (
                <Card key={i}>
                  <CardBody>
                    <div
                      onClick={() => {
                        toggle(i)
                      }}
                      className="d-flex justify-content-between"
                    >
                      <span>{e?.title}</span>
                      {isOpen[i] ? <ChevronUp /> : <ChevronDown />}
                    </div>
                  </CardBody>
                  <Collapse isOpen={isOpen[i]}>
                    <CardBody>
                      <div>
                        {e?.content?.map((z, i) => {
                          return (
                            <div key={i} className="d-flex my-2 gap-1">
                              <div>{`${i + 1}.`} </div>
                              <div> {z}</div>
                            </div>
                          )
                        })}
                      </div>
                    </CardBody>
                  </Collapse>
                </Card>
              )
            })}
          </div>
          {dataState?.isVa ? (
            <div
              className="py-3 px-3 rounded mt-3"
              style={{ backgroundColor: '#F3FEFF' }}
            >
              Setelah melakukan pembayaran, klik Konfirmasi untuk melanjutkan
              proses.
            </div>
          ) : (
            <></>
          )}
          <div className="d-flex justify-content-between mt-4 gap-2">
            <Button
              disabled={loading}
              onClick={handleCancel}
              block
              outline
              color="primary"
            >
              Batal
            </Button>
            <Button
              disabled={loading}
              onClick={() => {
                handleDoneTransfer()
              }}
              block
              color="primary"
            >
              {dataState?.isVa ? 'Konfirmasi' : ' Saya Sudah Transfer'}
            </Button>
          </div>
        </CardBody>
      </Card>
    </Container>
  )
}

export default TransferBank
