import { createAppSlice } from 'app/createAppSlice'

const initialState = {
  isProcess: false,
  processMsg: '',
  selectedPricing: '',
  handlePindahUangUsaha: {
    isFetch: false,
    message: '',
  },
}

export const withdrawSlice = createAppSlice({
  name: 'withdraw-slice',
  initialState,
  reducers: (create) => ({
    setIsProcess: create.reducer((state, { payload }) => {
      state.isProcess = payload
    }),
    setProcessMsg: create.reducer((state, { payload }) => {
      state.processMsg = payload
    }),
    setSelectedPricing: create.reducer((state, { payload }) => {
      state.selectedPricing = payload
    }),
    setSuccessPindahUangUsaha: create.reducer((state, { payload }) => {
      state.handlePindahUangUsaha.isFetch = payload.isFetch
      state.handlePindahUangUsaha.message = payload.message
    }),
  }),
})

export const {
  setProcessMsg,
  setIsProcess,
  setSelectedPricing,
  setSuccessPindahUangUsaha,
} = withdrawSlice.actions

export default withdrawSlice.reducer
