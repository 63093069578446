import { useDispatch, useSelector } from 'react-redux'
import { Card, CardBody, Col, FormGroup, Input, Label, Row } from 'reactstrap'
import { setOrdersNote } from '../purchaseCommoditySlice'

const NoteInfo = () => {
  const dispatch = useDispatch()
  const { orders } = useSelector((state) => state.purchaseCommmodity)

  const handleChange = (e) => {
    dispatch(setOrdersNote(e.target.value))
  }

  return (
    <Card body className="custom-container m-0 m-auto">
      <Row>
        <Col>
          <Card className="border-0">
            <CardBody>
              <FormGroup>
                <Label for="notes">Catatan ke penjual</Label>
                <Input
                  id="notes"
                  name="notes"
                  placeholder="Masukkan catatan jika ada"
                  type="textarea"
                  style={{ minHeight: '128px' }}
                  value={orders?.order?.notes}
                  onChange={(e) => handleChange(e)}
                />
              </FormGroup>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Card>
  )
}

export default NoteInfo
