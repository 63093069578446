import { useEffect, useState } from 'react'
import { Button, Card, CardBody, Modal } from 'reactstrap'
import logoSuccess from 'assets/images/success-trx-2.png'
import { useNavigate } from 'react-router-dom'

const ModalSuccess = ({ isOpen, toggle }) => {
  const [countdown, setCountdown] = useState(10)
  const navigate = useNavigate()

  useEffect(() => {
    if (isOpen) {
      const interval = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1)
      }, 1000)

      return () => clearInterval(interval)
    }
  }, [isOpen])

  useEffect(() => {
    if (countdown === 0) {
      navigate(-2)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countdown])

  return (
    <Modal centered size="sm" isOpen={isOpen} toggle={toggle}>
      <Card>
        <CardBody className="d-flex flex-column align-items-center">
          <img className="img-fluid" alt="sukses topup" src={logoSuccess} />
          <p className="fw-semibold">Tambah uang belanja selesai!</p>
          <p className="text-secondary small">
            Kembali dalam {countdown} detik
          </p>
          <Button
            block
            outline
            onClick={() => navigate(-2, { state: { refetchSaldo: true } })}
          >
            Lewati Sekarang
          </Button>
        </CardBody>
      </Card>
    </Modal>
  )
}

export default ModalSuccess
