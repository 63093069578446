import { useEffect, useMemo } from 'react'
import { useFetchProfileQuery as getProfile } from 'features/private-views/dashboard/dashboardApiSlice'
import { useDispatch } from 'react-redux'
import { setProfile } from 'features/private-views/dashboard/dashboardSlice'

const useGetMyProfileAvaQuery = () => {
  const dispatch = useDispatch()

  const profile = getProfile()
  const data = useMemo(() => profile, [profile])

  useEffect(() => {
    dispatch(setProfile(profile?.data?.makerData))
  }, [dispatch, profile])

  return data
}

export default useGetMyProfileAvaQuery
