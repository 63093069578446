// ** Third Party Components
import { ArrowLeft, X } from 'react-feather'
import Proptypes from 'prop-types'
import classnames from 'classnames'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'

const SidebarDetails = (props) => {
  // ** Props
  const {
    width,
    open,
    toggleSidebar,
    size,
    bodyClassName,
    contentClassName,
    wrapperClassName,
    headerClassName,
    className,
    title,
    children,
    closeBtn,
    noClose = false,
    isBack = false,
    handleBack,
    ...rest
  } = props

  // ** If user passes custom close btn render that else default close btn
  const renderCloseBtn = !noClose ? (
    closeBtn ? (
      closeBtn
    ) : (
      <X
        className="pe-auto"
        style={{
          cursor: 'pointer',
        }}
        size={20}
        onClick={toggleSidebar}
      />
    )
  ) : (
    <></>
  )

  return (
    <Modal
      isOpen={open}
      toggle={toggleSidebar}
      contentClassName={classnames({
        [contentClassName]: contentClassName,
      })}
      modalClassName={classnames('modal-slide-in', {
        [wrapperClassName]: wrapperClassName,
      })}
      className={classnames({
        [className]: className,
        'SidebarDetails-lg': size === 'lg',
        'SidebarDetails-sm': size === 'sm',
      })}
      /*eslint-disable */
      {...(width !== undefined
        ? {
            style: { width: String(width) + 'px' },
          }
        : {})}
      /*eslint-enable */
      {...rest}
    >
      <ModalHeader
        className={classnames({
          [headerClassName]: headerClassName,
        })}
        toggle={toggleSidebar}
        close={renderCloseBtn}
        tag="div"
      >
        <div className="d-flex align-items-center gap-2">
          {isBack && (
            <ArrowLeft style={{ cursor: 'pointer' }} onClick={handleBack} />
          )}
          <h5 className="modal-title">
            <span className="align-middle">{title}</span>
          </h5>
        </div>
      </ModalHeader>
      <ModalBody
        className={classnames('flex-grow-1', {
          [bodyClassName]: bodyClassName,
        })}
      >
        {children}
      </ModalBody>
    </Modal>
  )
}

export default SidebarDetails

// ** PropTypes
SidebarDetails.propTypes = {
  title: Proptypes.string.isRequired,
  open: Proptypes.bool.isRequired,
  toggleSidebar: Proptypes.func.isRequired,
  size: Proptypes.oneOf(['sm', 'lg']),
  className: Proptypes.string,
  bodyClassName: Proptypes.string,
  contentClassName: Proptypes.string,
  wrapperClassName: Proptypes.string,
  children: Proptypes.any.isRequired,
  width: Proptypes.oneOfType([Proptypes.number, Proptypes.string]),
}
