import { useDispatch, useSelector } from 'react-redux'
import { Button, Modal, ModalBody } from 'reactstrap'
import { toggleModal } from '../sellCommoditySlice'
import { useNavigate } from 'react-router-dom'
import { createOrder } from '../sellCommodityAction'
import { useCallback, useEffect } from 'react'

function ModalTrx() {
  const { isOpenModal, orders } = useSelector((s) => s.sellCommodity)
  const dispatch = useDispatch()

  const toggle = useCallback(() => {
    dispatch(toggleModal())
  }, [dispatch])

  const navigate = useNavigate()
  useEffect(() => {
    if (orders.isSuccess) {
      toggle()
      navigate('/sell-commodity-success')
    }
  }, [orders, navigate, toggle])

  const payloadOrderModified = (orders) => {
    return {
      ...orders,
      item: orders?.item?.map((e) => {
        return {
          ...e,
          price: Number(
            e?.price
              ?.replace?.(/\./g, '')
              ?.replace?.(/\,/g, '.')
              ?.slice?.(0, 15),
          ),
          amount: Number(
            e?.amount
              ?.replace?.(/\./g, '')
              ?.replace?.(/\,/g, '.')
              ?.slice?.(0, 15),
          ),
        }
      }),
    }
  }

  return (
    <Modal
      isOpen={isOpenModal}
      toggle={toggle}
      centered
      style={{ maxWidth: '400px' }}
    >
      <ModalBody>
        <div className="text-left">
          <h5 className="fw-bold text-md">Buat Transaksi</h5>
          <p className="text-sm">
            Pastikan data yang kamu masukkan sudah benar.
          </p>
        </div>

        <div className="border-0 flex-centered flex-row gap-2 w-100">
          <Button
            color="secondary"
            onClick={() => {
              toggle()
            }}
            outline
            className="w-50"
          >
            <span className="text-normal">Batal</span>
          </Button>
          <Button
            color="primary"
            onClick={() => {
              let payloadOrder = { ...orders }
              delete payloadOrder.step
              delete payloadOrder.isLoading
              dispatch(createOrder(payloadOrderModified(orders)))
            }}
            className="w-50"
          >
            <span className="text-normal">Lanjutkan</span>
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ModalTrx
