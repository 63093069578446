import axios from 'axios'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Select from 'react-select'
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
} from 'reactstrap'
import { apiEndpoint } from '../../utility/Utils'

const ModalUnverifSeller = ({ isOpen, toggle, handleFinished }) => {
  const { token } = useSelector((e) => e.auth)
  const [dataSeller, setDataSeller] = useState({})
  const [dataBank, setDataBank] = useState([])

  const handleChange = (key, e) => {
    setDataSeller((prevValues) => ({
      ...prevValues,
      [key]: e,
    }))
  }

  const getBank = async () => {
    try {
      const { data } = await axios.get(`${apiEndpoint}/api/v1/bussines/bank`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      const newData = data?.data?.map((e) => {
        return {
          label: e.code,
          value: e.code,
          logo: e.logo,
          code: e.code,
        }
      })
      setDataBank(newData)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (isOpen) {
      getBank()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  return (
    <Modal className="p-5 w-100" isOpen={isOpen} toggle={toggle} centered>
      <ModalBody className="w-100">
        <h5 className="fw-bold">Nomor belum terdaftar</h5>
        <p>
          Nomor handphone yang anda masukkan belum terdaftar di PasarMIKRO.
          Tambahkan data secara manual untuk melanjutkan transaksi.
        </p>
        <Form className="w-100">
          <FormGroup>
            <Label>Nama penjual</Label>
            <Input
              placeholder="Masukkan nama"
              type="text"
              name="fullName"
              onChange={(e) => {
                handleChange(e.target.name, e.target.value)
              }}
            />
          </FormGroup>
          <FormGroup>
            <Label>Bank</Label>
            <Select
              placeholder="Pilih bank"
              options={dataBank}
              onChange={(e) => {
                handleChange('bankData', e)
              }}
            />
          </FormGroup>
          <FormGroup>
            <Label>Nomor rekening</Label>
            <Input
              placeholder="Masukkan nomor rekening"
              type="number"
              name="bankAccountNumber"
              onChange={(e) => {
                handleChange(e.target.name, e.target.value)
              }}
            />
          </FormGroup>
          <FormGroup>
            <Label>Nama pemilik rekening</Label>
            <Input
              placeholder="Masukkan nama pemilik rekening"
              type="text"
              name="bankAccountName"
              onChange={(e) => {
                handleChange(e.target.name, e.target.value)
              }}
            />
          </FormGroup>
          <div className="d-flex justify-content-between gap-2">
            <Button
              onClick={toggle}
              className="flex-grow-1"
              outline
              color="secondary"
            >
              Batal
            </Button>
            <Button
              onClick={() => {
                handleFinished(dataSeller)
              }}
              className="flex-grow-1"
              color="primary"
            >
              Tambah Data
            </Button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default ModalUnverifSeller
