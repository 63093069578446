import { Input } from 'reactstrap'

const InputPhoneNumber = ({ placeholder, type, value, setValue, ...props }) => {
  return (
    <Input
      placeholder={placeholder}
      type={type}
      value={value}
      onChange={(e) => {
        let inputValue = e.target.value.trim()
        const num = inputValue.replace(/[^\d.-]/g, '')
        if (!isNaN(num)) {
          setValue(num.toString())
        }
      }}
      {...props}
    />
  )
}

export default InputPhoneNumber
