import ILDefaultAva from 'assets/icons/default-ava-profile-user.svg'
import { Card, CardBody, CardText } from 'reactstrap'

const UserInfo = ({ sellerDetail }) => {
  return (
    <Card className="w-100 mb-3">
      <CardBody>
        <CardText tag="h6">Penjual</CardText>
        <div className="d-flex align-items-center gap-2">
          <img
            src={ILDefaultAva}
            alt="ava"
            width={25}
            height={25}
            className="rounded-circle"
          />
          <span className="text-sm fw-semibold">{sellerDetail?.fullName}</span>
        </div>
      </CardBody>
    </Card>
  )
}

export default UserInfo
