import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'reactstrap'
import { confirmTrx } from '../requestTransactionAction'
import { useEffect } from 'react'

const ButtonAction = ({ amount, code, data }) => {
  const dispatch = useDispatch()
  const { linkApproved, isApproved } = useSelector((s) => s.reqTrxSell)

  useEffect(() => {
    if (isApproved) {
      window.open(linkApproved, '_blank', 'noopener,noreferrer')
    }
  }, [isApproved, linkApproved])
  return (
    <div className="d-flex align-items-center gap-2 mb-4">
      <Button
        style={{ width: 220 }}
        color="secondary"
        outline
        onClick={() => dispatch(confirmTrx({ amount, code, status: 'CANCEL' }))}
        disabled={data?.approvedAt || isApproved}
      >
        Tolak
      </Button>
      <Button
        disabled={data?.approvedAt || isApproved}
        style={{ width: 220 }}
        color="primary"
        onClick={() =>
          dispatch(confirmTrx({ amount, code, status: 'CONFIRM' }))
        }
      >
        Terima dan Bayar
      </Button>
    </div>
  )
}

export default ButtonAction
