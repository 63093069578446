import { Card, CardBody, Col, Row } from 'reactstrap'
import HeaderItem from './HeaderItem'

const HeaderTransaction = () => {
  return (
    <>
      <div className="mb-4 mt-4">
        <Card>
          <CardBody className="p-4">
            <Row className="text-center d-flex align-items-center flex-column flex-md-row">
              <HeaderItem numberStep={1} title="Tambah Komoditas" />
              <HeaderItem numberStep={2} title="Pembayaran" />
              <HeaderItem
                numberStep={3}
                title="Konfirmasi Pembayaran"
                hideDivider={true}
              />
            </Row>
          </CardBody>
        </Card>
      </div>
    </>
  )
}

export default HeaderTransaction
