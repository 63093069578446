import PageForbidden from 'components/PageForbidden'
import { URL_406 } from 'utils/pathUrl'

export const errorRouter = [
  {
    path: URL_406,
    name: 'Forbidden',
    element: <PageForbidden />,
  },
]
