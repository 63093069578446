import { createApiSlice } from 'app/createApiSlice'

export const requestTransactionApiSlice = createApiSlice.injectEndpoints({
  reducerPath: 'requestTrxApi',

  endpoints(builder) {
    return {
      checkDataTrx: builder.query({
        query({ code, amount }) {
          return {
            url: '/api/v1/bussines/public/transaction',
            params: { code, amount },
          }
        },
      }),
    }
  },
})

export const { useCheckDataTrxQuery } = requestTransactionApiSlice
