import { createApiSlice } from 'app/createApiSlice'
import {
  API_FARMERS_BANK_LIST,
  API_FARMERS_OCR,
  API_FARMERS_PRECHECK,
  API_FARMERS_REGISTER,
  API_FARMERS_UPLOAD_FILE,
} from 'utils/apiList'

export const formFarmersApiSlice = createApiSlice.injectEndpoints({
  reducerPath: 'formFarmers/api',
  endpoints(builder) {
    return {
      fetchBanks: builder.query({
        query() {
          return API_FARMERS_BANK_LIST
        },
        transformResponse: (response, _meta, arg) => {
          const newData = response?.data?.map((e) => {
            return {
              value: e.name,
              label: e.name,
              code: e.code,
            }
          })
          return newData
        },
      }),
      uploadImage: builder.mutation({
        query({ img, fileType }) {
          const image = new FormData()
          image.append('file', img)
          return {
            url: API_FARMERS_UPLOAD_FILE,
            method: 'POST',
            body: {
              file: image,
              fileType,
            },
          }
        },
      }),
      ocrKtp: builder.mutation({
        query({ image, userId }) {
          return {
            url: API_FARMERS_OCR,
            method: 'POST',
            body: {
              userId,
              image,
            },
          }
        },
      }),
      preCheckFarmer: builder.mutation({
        query({ identity }) {
          return {
            url: API_FARMERS_PRECHECK,
            method: 'POST',
            body: {
              identity,
            },
          }
        },
      }),
      registerFarmer: builder.mutation({
        query(payload) {
          return {
            url: API_FARMERS_REGISTER,
            method: 'POST',
            body: payload,
          }
        },
      }),
      changeProfile: builder.mutation({
        query({ payload, id }) {
          return {
            url: `${API_FARMERS_REGISTER}/${id}`,
            method: 'POST',
            body: payload,
          }
        },
      }),
    }
  },
})

export const {
  useFetchBanksQuery,
  useUploadImageMutation,
  useOcrKtpMutation,
  usePreCheckFarmerMutation,
  useRegisterFarmerMutation,
  useChangeProfileMutation,
} = formFarmersApiSlice
