import React from 'react'
import { Modal, ModalBody } from 'reactstrap'
import FormRegis from '../forms'
import { toggleModalChangeProfile } from './detailSlice'
import { useDispatch, useSelector } from 'react-redux'

const ModalChangeProfil = ({ row }) => {
  const dispatch = useDispatch()
  const { isOpenModalChangeProfile } = useSelector((s) => s.farmersDetail)

  return (
    <Modal
      centered
      isOpen={isOpenModalChangeProfile}
      toggle={() => dispatch(toggleModalChangeProfile())}
    >
      <ModalBody>
        <h6>Ubah data diri</h6>
        <FormRegis row={row} />
      </ModalBody>
    </Modal>
  )
}

export default ModalChangeProfil
