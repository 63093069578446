import { useEffect, useMemo, useState } from 'react'
import {
  useGetUserAvatarQuery as getAva,
  useFetchTeamListQuery as getTeams,
} from 'features/private-views/settings/settingApiSlice'

const useGetTeamsAvatarQuery = (arg) => {
  let [userId, setUserId] = useState([])

  const teamResult = getTeams(arg)
  const avaResult = getAva(userId, { skip: userId.length === 0 })

  const data = useMemo(() => {
    const getAvatar = (trx) => {
      let result = null
      for (let i = 0; i < avaResult.data?.data?.length; i++) {
        const avaUser = avaResult.data?.data[i]
        if (avaUser.userId === trx?.userId) {
          result = avaUser.link
        }
      }
      return result
    }

    return {
      ...teamResult,
      data: {
        ...teamResult?.data,
        items: teamResult?.data?.items?.map((team) => ({
          ...team,
          personAvatar: getAvatar(team),
        })),
      },
    }
  }, [teamResult, avaResult])

  useEffect(() => {
    let tempUserId = []
    teamResult?.data?.items.forEach((e) => {
      tempUserId.push(e?.userId)
    })
    setUserId(tempUserId)
  }, [teamResult])
  return data
}

export default useGetTeamsAvatarQuery
