import SidebarDetails from 'components/SidebarDetails'
import { useState } from 'react'
import { Badge, Button, Card, CardBody } from 'reactstrap'
import ICdefault from 'assets/icons/default-ava-profile-user.svg'
import { useIntl } from 'react-intl'
import { useFetchWithdrawalQuery } from 'features/private-views/withdraw/withdrawApiSlice'
import moment from 'moment-timezone'
import iconCard from 'assets/icons/red-wallet.png'
import { useSelector } from 'react-redux'

const SidebarWithdraw = ({ open, toggle }) => {
  const intl = useIntl()
  const { data: dataWithdraw } = useFetchWithdrawalQuery()
  const [isHistory, setIsHistory] = useState(false)
  const { role } = useSelector((s) => s.auth)

  return (
    <SidebarDetails
      width={400}
      open={open}
      title={isHistory ? 'Riwayat Penarikan' : 'Detail Penarikan'}
      headerClassName="mb-1 justify-content-between"
      contentClassName="pt-0"
      toggleSidebar={() => {
        toggle()
        setIsHistory(false)
      }}
      isBack={isHistory}
      handleBack={() => {
        setIsHistory(false)
      }}
    >
      {isHistory && (
        <Card>
          {dataWithdraw?.items.map((e, i) => {
            return (
              <CardBody key={i}>
                <p className="text-secondary fw-medium">
                  {moment(e?.createdAt).format('LL')}
                </p>
                <div
                  className="d-flex align-items-center"
                  style={{ gap: '16px' }}
                >
                  <img
                    src={iconCard}
                    style={{ width: '39px', height: '39px' }}
                    alt="Wallet"
                  />
                  <div className="d-flex justify-content-between w-100">
                    <div className="d-flex flex-column">
                      <span className="fw-bold">Penarikan {e?.title}</span>
                      <span className="text-secondary">{e?.number}</span>
                      <span className="text-secondary">
                        {moment(e?.createdAt).format('hh:mm')}
                      </span>
                    </div>
                    <div className="d-flex flex-column ">
                      <span className="text-danger fw-bold">
                        -
                        {intl.formatNumber(e?.amount, {
                          useGrouping: 'always',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })}
                      </span>
                      <Badge
                        size="sm"
                        className={`${e?.status === 'Selesai' ? 'badge-verify' : e?.status === 'Dalam Proses' ? 'badge-warning' : 'badge-cancel'}`}
                      >
                        {e?.status}
                      </Badge>
                    </div>
                  </div>
                </div>
                {dataWithdraw?.items?.length === i + 1 ? <></> : <hr></hr>}
              </CardBody>
            )
          })}
        </Card>
      )}
      {!isHistory && (
        <div className="d-flex flex-column gap-3">
          <div>
            <Badge size="sm" disabled className="badge-warning mt-1">
              {dataWithdraw?.items[0]?.status}
            </Badge>
          </div>
          <Card body>
            <span className="fw-bold">Pemohon</span>
            <div className="d-flex align-items-center gap-2">
              <img src={ICdefault} alt="icon ava" />
              <span>
                {dataWithdraw?.items[0]?.makerName ??
                  dataWithdraw?.items[0]?.requesterName}
              </span>
            </div>
          </Card>
          <Card body>
            <p className="fw-bold">Rincian Penarikan</p>
            <div className="d-flex justify-content-between align-items-center">
              <p>Nominal</p>
              <p>
                Rp
                {intl.formatNumber(dataWithdraw?.items[0]?.amount ?? 0, {
                  useGrouping: 'always',
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
              </p>
            </div>
            <div className="d-flex justify-content-between align-items-start">
              <p>
                {`Biaya penarikan `}
                <br />
                <small>
                  <span className="text-danger">*</span>
                  {`sudah termasuk PPN`}
                </small>
              </p>
              <p>
                Rp
                {intl.formatNumber(dataWithdraw?.items[0]?.fee ?? 0, {
                  useGrouping: 'always',
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
              </p>
            </div>
            <div className="d-flex justify-content-between align-items-start">
              <p className="fw-bold">Nominal yang diterima</p>
              <p>
                Rp
                {intl.formatNumber(
                  dataWithdraw?.items[0]?.amount ??
                    0 - dataWithdraw?.items[0]?.fee ??
                    0,
                  {
                    useGrouping: 'always',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  },
                )}
              </p>
            </div>
            <div
              className="rounded p-3 d-flex gap-3 align-items-center"
              style={{
                backgroundColor: '#F3FEFF',
                borderColor: '#8ABBCC',
                borderStyle: 'solid',
                borderWidth: '1px',
              }}
            >
              <div>
                <img
                  alt="bca logo"
                  style={{ maxHeight: '30px', maxWidth: '30px' }}
                  src={dataWithdraw?.items[0]?.logo}
                />
              </div>
              <div className="d-flex flex-column ">
                <span>{dataWithdraw?.items[0]?.bankAccountName}</span>
                <span>
                  {dataWithdraw?.items[0]?.bankCode}{' '}
                  {dataWithdraw?.items[0]?.bankAccountNumber}
                </span>
              </div>
            </div>
          </Card>
          {!role === 'Staff' && (
            <Card body>
              <p className="fw-bold">Estimasi Selesai</p>
              <span className="text-secondary">
                {`${moment(dataWithdraw?.items[0]?.estimateTime).format('LL')} (${moment(dataWithdraw?.items[0]?.estimateTime).format('hh:mm')})`}
              </span>
            </Card>
          )}
          <Button onClick={() => setIsHistory(true)} outline color="primary">
            Lihat Riwayat Penarikan
          </Button>
        </div>
      )}
    </SidebarDetails>
  )
}

export default SidebarWithdraw
