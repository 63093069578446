import {
  Badge,
  Button,
  Card,
  CardBody,
  CardText,
  Col,
  Form,
  FormGroup,
  InputGroup,
  Label,
  Row,
} from 'reactstrap'
import ILDefaultAva from 'assets/icons/default-ava-profile-user.svg'
import { purchaseCommodityCheckUser } from '../purchaseCommodityAction'
import { useDispatch, useSelector } from 'react-redux'

// Form

import { useEffect, useState } from 'react'
import ModalUnverifSeller from 'private-views/transactions/ModalUnverifSeller'
import { resetErrorMsg, setNewSeller } from '../purchaseCommoditySlice'
import { AlertCircle } from 'react-feather'
import InputPhoneNumber from 'components/InputPhoneNumber'

const UserInfo = () => {
  const dispatch = useDispatch()

  const { orders, errorMessage } = useSelector(
    (state) => state.purchaseCommmodity,
  )
  const { seller } = orders

  const [phoneNum, setPhoneNumber] = useState('')
  const [isOpenModal, setIsOpenModal] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault()
    dispatch(purchaseCommodityCheckUser({ search: phoneNum, type: 'BUY' }))
  }

  const handleFinishedCheckNumber = (payload) => {
    setIsOpenModal(false)
    dispatch(setNewSeller({ ...payload, phoneNumber: phoneNum }))
  }

  useEffect(() => {
    if (seller && seller?.profileStatus === 'UNREGISTERED') {
      setIsOpenModal(true)
    }
  }, [seller])

  return (
    <>
      <ModalUnverifSeller
        toggle={() => setIsOpenModal(!isOpenModal)}
        isOpen={isOpenModal}
        handleFinished={handleFinishedCheckNumber}
      />
      <Card body className="custom-container m-0 m-auto">
        <Row>
          <Col>
            <Card className="border-0">
              <CardBody>
                <CardText tag="h5" className="text-md">
                  Pembelian
                </CardText>
                <Form onSubmit={handleSubmit}>
                  <FormGroup>
                    <Label for="phone" className="text-sm">
                      Beli dari
                    </Label>
                    <InputGroup>
                      <InputPhoneNumber
                        placeholder="Masukkan nomor handphone penjual"
                        value={phoneNum}
                        setValue={(e) => {
                          setPhoneNumber(e)
                          dispatch(resetErrorMsg(''))
                        }}
                        name="phoneNumber"
                        id="phoneNumber"
                      />

                      <Button
                        color="transparent"
                        className="border text-normal"
                        type="submit"
                      >
                        Cek
                      </Button>
                    </InputGroup>
                    <span className="text-xs">
                      Contoh: 0813xxxx atau 62813xxxx
                    </span>
                  </FormGroup>
                </Form>
                {errorMessage && (
                  <FormGroup>
                    <small className="text-danger">
                      <AlertCircle size={15} /> {errorMessage}
                    </small>
                  </FormGroup>
                )}
              </CardBody>
            </Card>
            {seller?.fullName && (
              <Card>
                <CardBody>
                  <CardText tag="h6" className="text-sm fw-semibold">
                    Info penjual
                  </CardText>
                  <Row>
                    <Col>
                      <Card style={{ maxHeight: 68 }}>
                        <CardBody>
                          <div className="flex-centered justify-content-between">
                            <div className="flex-centered gap-1 ">
                              <img
                                src={seller.profileImg ?? ILDefaultAva}
                                alt="ava"
                                width={36}
                                height={35}
                                className="rounded-circle"
                              />
                              <span className="text-sm fw-semibold">
                                {seller?.fullName}
                              </span>
                            </div>
                            {seller?.profileStatus === 'REGISTERED' && (
                              <Badge
                                size="sm"
                                disabled
                                className="badge-verify"
                              >
                                Terdaftar
                              </Badge>
                            )}
                            {seller?.profileStatus === 'VERIFIED' && (
                              <Badge
                                size="sm"
                                disabled
                                className="badge-verify"
                              >
                                Terverifikasi
                              </Badge>
                            )}
                            {seller?.profileStatus ===
                              'Belum Terverifikasi' && (
                              <Badge
                                size="sm"
                                disabled
                                className="badge-warning"
                              >
                                {seller?.profileStatus}
                              </Badge>
                            )}
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col>
                      {seller?.bankAccountNumber && seller?.bankAccountName && (
                        <Card style={{ maxHeight: 68 }}>
                          <CardBody>
                            <div className="d-flex justify-content-between flex-column">
                              <div className="flex-centered justify-content-start gap-1">
                                <img
                                  src={seller?.bankLogo}
                                  alt="ava"
                                  width={18}
                                  height={18}
                                />
                                <span style={{ fontSize: 14 }}>
                                  {seller?.bankAccountNumber}
                                </span>
                              </div>
                              <div
                                className="fw-semibold"
                                style={{ fontSize: 14 }}
                              >
                                {seller?.bankAccountName}
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      )}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            )}
          </Col>
        </Row>
      </Card>
    </>
  )
}

export default UserInfo
