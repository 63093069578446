import ICDefaultPhoto from 'assets/icons/default-ava-profile-user.svg'
import SidebarDetails from 'components/SidebarDetails'
import { Check, CheckCircle, ChevronDown, Eye, X } from 'react-feather'
import { Alert, Badge, Button, Card, CardFooter, CardText } from 'reactstrap'
import ModalInfoFast from '../ModalApproval'

import IPhoto from 'assets/icons/Pak-Tani.png'
import { ButtonIcon } from 'components/Buttons'
import LoadingAnimation from 'components/LoadingAnimation'
import { Can } from 'context/Acl'
import { useAbilityAccess } from 'hooks/useAbilityAccess'
import useGetTrxApproval from 'hooks/useGetTrxApproval'
import { useState } from 'react'
import DataTable from 'react-data-table-component'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { BUSINESS_MYAPPROVAL, MANAGE } from 'utils/subjectActions'
import { statusApproval } from '../ApprovalTable'
import { selectedData, setOpenModal } from '../approvalSlice'

const TableRequestWithdrawal = () => {
  const dispatch = useDispatch()
  const access = useAbilityAccess({
    action: MANAGE,
    subject: BUSINESS_MYAPPROVAL,
  })

  const trxApproval = useGetTrxApproval({ filter: 'Request withdrawal' })

  const { item, isOpenModal } = useSelector((s) => s.approval)
  const intl = useIntl()
  const [isOpen, setIsOpen] = useState(false)
  const [isOpen2, setIsOpen2] = useState(false)
  const [selectRows, setSelectRows] = useState(null)
  const [selectedRows, setSelectedRows] = useState([])
  const [clearRows, setClearRows] = useState(false)
  const [showAlertError, setShowAlertError] = useState(false)
  const [showAlertSuccess, setShowAlertSuccess] = useState(false)

  let [page, setPage] = useState({ page: 0 })
  const toggle = () => setIsOpen(!isOpen)

  const toggleSidebar = (payload) => {
    dispatch(setOpenModal())
    if (payload) {
      dispatch(selectedData(payload))
    }
  }

  const toggleFastApprove = (payload, type) => {
    setIsOpen2(!isOpen2)
    setSelectRows({ data: payload, type })
  }

  const handleSelect = (e) => {
    setSelectedRows(e)
  }

  const columns = [
    {
      name: 'Pemohon',
      cell: (row) => (
        <div className="flex-centered gap-2">
          <img
            className="rounded-circle"
            src={row?.makerAvatar ?? IPhoto}
            alt="pak tani"
            width={20}
            height={20}
          />
          <span>{row?.makerName}</span>
        </div>
      ),
      sortable: true,
    },
    {
      name: 'Nominal',
      cell: (row) => (
        <span>
          Rp
          {intl.formatNumber(row?.instantWithdrawal?.amount ?? 0, {
            useGrouping: 'always',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })}
        </span>
      ),
      sortable: true,
    },
    {
      name: 'Jenis Penarikan',
      cell: (row) => <span>Instan {row?.instantWithdrawal?.title}</span>,
      sortable: true,
    },
    {
      name: '',
      cell: (row) => {
        return (
          <>
            <Eye
              size={18}
              className="fw-bold mx-1"
              onClick={() => toggleSidebar(row)}
              style={{ cursor: 'pointer' }}
            />
            {access && (
              <>
                <X
                  size={18}
                  className="text-danger fw-bold mx-1"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    toggleFastApprove([row], statusApproval.withdrawReject)
                  }}
                />
                <Check
                  size={18}
                  className="text-success fw-bold mx-1"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    toggleFastApprove([row], statusApproval.withdrawApprove)
                  }}
                />
              </>
            )}
          </>
        )
      },
    },
  ]

  return (
    <>
      <Card body className="my-3">
        <div className="d-flex justify-content-between align-items-center">
          <CardText tag="h5" className="pb-2 py-2">
            Permintaan Approval
          </CardText>
          {selectedRows?.selectedCount > 0 ? (
            <div className="d-flex gap-3 align-items-center justify-content-center">
              <span className="text-muted">
                {selectedRows?.selectedCount} transaksi terpilih
              </span>
              <div className="d-flex gap-2">
                <Button
                  onClick={() => {
                    toggleFastApprove(
                      selectedRows?.selectedRows,
                      statusApproval.withdrawReject,
                    )
                  }}
                  className="rounded"
                  size="sm"
                  color="danger"
                  outline
                >
                  {' '}
                  <X size={20} />{' '}
                </Button>
                <Button
                  onClick={() => {
                    toggleFastApprove(
                      selectedRows?.selectedRows,
                      statusApproval.withdrawApprove,
                    )
                  }}
                  className="rounded"
                  size="sm"
                  color="success"
                  outline
                >
                  {' '}
                  <Check size={20} />{' '}
                </Button>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
        <Alert
          color="success"
          isOpen={showAlertSuccess}
          toggle={() => setShowAlertSuccess(false)}
          className="mb-2 mt-2"
        >
          <CheckCircle className="text-success me-2" size={16} />
          <span>Penarikan berhasil disetujui.</span>
        </Alert>
        <Alert
          color="danger"
          isOpen={showAlertError}
          toggle={() => setShowAlertError(false)}
        >
          <CheckCircle className="text-success me-2" size={16} />
          <span>Penarikan berhasil dibatalkan.</span>
        </Alert>
        <DataTable
          columns={columns}
          selectableRows
          onSelectedRowsChange={handleSelect}
          clearSelectedRows={clearRows}
          data={trxApproval?.data?.items}
          progressPending={trxApproval?.isLoading}
          responsive
          persistTableHead
          paginationTotalRows={trxApproval?.data?.totalItems}
          progressComponent={<LoadingAnimation />}
          sortIcon={<ChevronDown />}
          sortServer
          className="border p-0 border-1 rounded-top border-plat"
          noDataComponent={
            <span className="my-1">
              Belum ada data. Buat transaksi untuk memulai.
            </span>
          }
          pagination
          paginationServer
          paginationComponent={(props) => {
            return (
              <CardFooter
                className="border border-top-0 pe-4 ps-4 py-3"
                style={{ backgroundColor: 'transparent' }}
              >
                <div className="d-flex align-items-center justify-content-between">
                  <div className="text-sm">
                    {`Halaman ${props?.currentPage} dari ${Math.ceil(props.rowCount / props.rowsPerPage)}`}
                  </div>
                  <div className="flex-centered gap-1">
                    <ButtonIcon
                      btnTitle="Sebelumnya"
                      btnProps={{
                        disabled: page.page === 0,
                        size: 'sm',
                        color: 'secondary',
                        onClick: () =>
                          setPage((prev) => ({ page: prev.page - 1 })),
                      }}
                      btnTitleProps={{
                        className: 'text-sm',
                      }}
                    />
                    <ButtonIcon
                      btnTitle="Selanjutnya"
                      btnProps={{
                        disabled:
                          page.page ===
                          Math.ceil(props.rowCount / props.rowsPerPage) - 1,
                        size: 'sm',
                        color: 'secondary',
                        onClick: () =>
                          setPage((prev) => ({ page: prev.page + 1 })),
                      }}
                      btnTitleProps={{
                        className: 'text-sm',
                      }}
                    />
                  </div>
                </div>
              </CardFooter>
            )
          }}
        />
      </Card>
      <SidebarDetails
        width={400}
        open={isOpenModal}
        title="Data Transaksi"
        headerClassName="mb-1 justify-content-between"
        contentClassName="pt-0"
        toggleSidebar={toggleSidebar}
      >
        <div className="d-flex flex-column gap-3 mb-5">
          <div>
            <Badge size="sm" disabled className="badge-warning mt-1">
              {item?.status}
            </Badge>
          </div>
          <Card body>
            <span className="fw-bold">Pemohon</span>
            <div className="d-flex align-items-center gap-2">
              <img
                src={item?.makerAvatar ?? ICDefaultPhoto}
                className="rounded-circle"
                alt="icon ava"
                width={32}
                height={32}
              />
              <span>{item?.makerName}</span>
            </div>
          </Card>
          <Card body>
            <p className="fw-bold">Rincian Penarikan</p>
            <div className="d-flex justify-content-between align-items-center">
              <p>Nominal</p>
              <p>
                Rp
                {intl.formatNumber(item?.instantWithdrawal?.amount ?? 0, {
                  useGrouping: 'always',
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
              </p>
            </div>
            <div className="d-flex justify-content-between align-items-start">
              <p>
                {`Biaya penarikan `}
                <br />
                <small>
                  <span className="text-danger">*</span>
                  {`sudah termasuk PPN`}
                </small>
              </p>
              <p>
                Rp
                {intl.formatNumber(item?.instantWithdrawal?.fee ?? 0, {
                  useGrouping: 'always',
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
              </p>
            </div>
            <div className="d-flex justify-content-between align-items-start">
              <p className="fw-bold">Nominal yang diterima</p>
              <p>
                Rp
                {intl.formatNumber(
                  item?.instantWithdrawal?.amount ??
                    0 - item?.instantWithdrawal?.fee ??
                    0,
                  {
                    useGrouping: 'always',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  },
                )}
              </p>
            </div>
            <div
              className="rounded p-3 d-flex gap-3 align-items-center"
              style={{
                backgroundColor: '#F3FEFF',
                borderColor: '#8ABBCC',
                borderStyle: 'solid',
                borderWidth: '1px',
              }}
            >
              <div>
                <img
                  alt="bca logo"
                  style={{ maxHeight: '30px', maxWidth: '30px' }}
                  src={item?.instantWithdrawal?.bankLogo}
                />
              </div>
              <div className="d-flex flex-column ">
                <span>{item?.instantWithdrawal?.bankAccountName}</span>
                <span>
                  {item?.instantWithdrawal?.bankCode}{' '}
                  {item?.instantWithdrawal?.bankAccountNumber}
                </span>
              </div>
            </div>
          </Card>
        </div>
        <Can I={MANAGE} this={BUSINESS_MYAPPROVAL}>
          <div className="d-flex justify-content-between gap-3 w-100">
            <Button
              block
              color="primary"
              size="sm"
              outline
              onClick={() => {
                toggleFastApprove([item], statusApproval.withdrawReject)
              }}
            >
              Tolak
            </Button>
            <Button
              block
              color="primary"
              size="sm"
              onClick={() => {
                toggleFastApprove([item], statusApproval.withdrawApprove)
              }}
            >
              Approve
            </Button>
          </div>
        </Can>
      </SidebarDetails>

      <ModalInfoFast
        isOpen={isOpen2}
        requestData={selectRows}
        setIsOpen={setIsOpen2}
        toggle={() => toggleFastApprove()}
        toggleSuccess={() => {
          if (item) {
            toggleSidebar()
            setShowAlertSuccess(true)
            trxApproval.refetch()
          } else {
            setShowAlertSuccess(true)
            trxApproval.refetch()
            setClearRows(true)
            setSelectedRows([])
          }
        }}
        toggleReject={() => {
          if (item) {
            toggleSidebar()
            showAlertError(true)
            trxApproval.refetch()
          } else {
            setShowAlertError(true)
            trxApproval.refetch()
            setClearRows(true)
            setSelectedRows([])
          }
        }}
      />
    </>
  )
}

export default TableRequestWithdrawal
