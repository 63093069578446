import { Button, Modal, ModalBody } from 'reactstrap'
import { useNavigate } from 'react-router-dom'
import ICThunder from 'assets/icons/thunder-icon.svg'
import { AlertCircle } from 'react-feather'

function ModalInfo({ isOpen, toggle, handleConfirm, errMsg, isLoading }) {
  const navigate = useNavigate()

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      centered
      style={{ maxWidth: '400px' }}
    >
      <ModalBody>
        <div>
          <div className="text-center">
            <img src={ICThunder} alt="icon" width={48} height={48} />
            <h5 className="fw-bold text-md text-center">
              Selesaikan transaksi ini?
            </h5>
            <p className="text-sm text-center">
              Pembayaran anda akan diteruskan ke penjual. Lanjutkan?
            </p>
          </div>
          {errMsg && (
            <div>
              <small className="text-danger">
                <AlertCircle size={15} /> {errMsg}
              </small>
            </div>
          )}
        </div>
        <div className="d-flex gap-2 w-100">
          <Button
            color="secondary"
            onClick={() => {
              toggle()
              navigate('/')
            }}
            block
            size="sm"
            className="p-2"
            disabled={isLoading}
          >
            <span className="text-normal">Batal</span>
          </Button>
          <Button
            color="primary"
            onClick={() => {
              handleConfirm()
            }}
            block
            size="sm"
            className="p-2"
            disabled={isLoading}
          >
            <span className="text-normal">Ya, Lanjut</span>
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ModalInfo
