import { Plus } from 'react-feather'
import { Button, Card, CardBody, CardText, Col, Row } from 'reactstrap'
import Item from './Item'
import { useDispatch, useSelector } from 'react-redux'
import { addItem } from '../purchaseCommoditySlice'

const ItemInfo = () => {
  const dispatch = useDispatch()
  const { addItemCount } = useSelector((state) => state.purchaseCommmodity)

  return (
    <Card body className="custom-container m-0 m-auto">
      <Row>
        <Col>
          <Card className="border-0">
            <CardText tag="h5">Barang Yang Dibeli</CardText>
            {addItemCount.map((num, idx) => (
              <Item key={idx} fieldNum={num} idx={idx} />
            ))}

            <CardBody className="py-0">
              <Button
                size="sm"
                style={{ maxWidth: 186 }}
                className="ml-2"
                color="primary"
                onClick={() => {
                  dispatch(addItem())
                }}
              >
                <Plus size={16} />
                <span>Tambah Komoditas</span>
              </Button>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Card>
  )
}

export default ItemInfo
