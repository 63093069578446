import { Button } from 'reactstrap'
import { Link } from 'react-router-dom'
import PasmikLogo from 'assets/images/pmg-logo-beta.png'

const Page404 = () => {
  return (
    <div
      className="flex-centered flex-column justify-content-evenly"
      style={{ height: '100vh', width: '100vw' }}
    >
      <img
        src={PasmikLogo}
        alt="logo"
        style={{ width: '100%', maxWidth: '200px' }}
      />
      <div className="flex-centered flex-column">
        <div className="p-2 p-sm-3">
          <div className="w-100 text-center">
            <h2 className="mb-1">Halaman tidak ditemukan 🕵🏻‍♀️</h2>
            <p className="mb-2">
              URL yang diminta tidak ditemukan di server ini.
            </p>
          </div>
        </div>
        <Button tag={Link} to="/" color="primary" className="btn-sm-block mb-2">
          Back to home
        </Button>
      </div>
      <h5 className="text-primary">PasarMIKRO Business</h5>
    </div>
  )
}
export default Page404
