import { Container } from 'reactstrap'
import { Outlet } from 'react-router-dom'

const ForgetPassword = () => {
  return (
    <Container>
      <Outlet />
    </Container>
  )
}

export default ForgetPassword
