/* eslint-disable eqeqeq */
import ICTransaction from 'assets/icons/icon-transaksi.svg'
import axios from 'axios'
import SidebarDetails from 'components/SidebarDetails'
import moment from 'moment-timezone'
import { useState } from 'react'
import { FileText } from 'react-feather'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Button, Card, CardText, CardTitle, Container } from 'reactstrap'
import ModalPasswordInvoice from './ModalPasswordInvoice'

const SidebarTalanginInvoice = ({ isOpen, toggle }) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const [openModal, setOpenModal] = useState(false)
  const { token } = useSelector((s) => s.auth)
  const row = useSelector((s) => s.talanginInvoice.selectedRow)
  const toggleModal = () => {
    setOpenModal(!openModal)
  }

  const today = moment().tz('Asia/Jakarta').startOf('day').toDate()

  const subtotal =
    row?.escrowpayment?.amount +
    (row?.previousUserPinjamanId
      ? row?.escrowpayment?.escrowcreditv2?.margin2
      : row?.escrowpayment?.escrowcreditv2?.margin)

  const feeTx = row?.escrowpayment?.escrowcreditv2?.useFreeTx
    ? 0
    : row?.escrowpayment?.escrowcreditv2?.feeTx -
      row?.escrowpayment?.escrowcreditv2?.feeTxVAT

  const vat = row?.escrowpayment?.escrowcreditv2?.useFreeTx
    ? 0
    : row?.escrowpayment?.escrowcreditv2?.feeTxVAT

  const amountToPay =
    row?.status == 'PAIDOFF'
      ? 0
      : row?.netOutstanding
        ? row?.netOutstanding
        : row?.amount + row?.fineData?.totalFine - row?.discountData?.discount

  const totalAmountToPay = row?.escrowpayment?.escrowcreditv2?.useFreeTx
    ? row?.escrowpayment?.escrowcreditv2?.totalVAT +
      (subtotal || row?.amountPlusInterest) +
      (row?.escrowpayment?.escrowcreditv2?.feeTx -
        row?.escrowpayment?.escrowcreditv2?.feeTxVAT) +
      (row?.partialpaymentData?.fine || row?.fineData?.totalFine)
    : row?.escrowpayment?.escrowcreditv2?.feeTxVAT +
      row?.escrowpayment?.escrowcreditv2?.totalVAT +
      (subtotal || row?.amountPlusInterest) +
      (row?.escrowpayment?.escrowcreditv2?.feeTx -
        row?.escrowpayment?.escrowcreditv2?.feeTxVAT) +
      (row?.partialpaymentData?.fine || row?.fineData?.totalFine)

  const paidAmout = totalAmountToPay - amountToPay

  const downloadAsPdf = (data, url, type) => {
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        ...{ responseType: 'blob' },
      })
      .then((response) => {
        const defName = `TRX/ID/${data.loan ? 'PMG-' : ''}${data.id}`
        var pdfNumber = defName
        if (data.deponumbers && data.deponumbers.length > 1) {
          pdfNumber = data.deponumbers[0].fullNumber
          if (data.loan || data.creditRequested > 0) {
            pdfNumber.replace(type, `${type}/PMG-`)
          }
        }
        var filename = `${pdfNumber.replaceAll('/', '-')}.pdf`
        const disposition = response.headers['content-disposition']
        if (disposition && disposition.indexOf('attachment') !== -1) {
          var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
          var matches = filenameRegex.exec(disposition)
          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '')
          }
        }
        const blobFile = new Blob([response.data], { type: 'application/pdf' })
        const url = window.URL.createObjectURL(blobFile)
        const a = document.createElement('a')
        a.href = url
        a.download = filename
        a.click()
        window.URL.revokeObjectURL(url)
      })
      .catch(function (error) {})
  }

  return (
    <>
      <ModalPasswordInvoice
        isOpen={openModal}
        toggle={toggleModal}
        toggleSidebar={toggle}
        row={row}
      />
      <SidebarDetails
        width={400}
        open={isOpen}
        title="Detail Transaksi"
        headerClassName="mb-1 justify-content-between"
        contentClassName="pt-0"
        toggleSidebar={toggle}
      >
        <Container className="h-100">
          <Card body className="mb-3">
            <div className="d-flex gap-2 flex-row">
              <div className="flex-centered justify-content-start gap-1">
                <img src={ICTransaction} alt="ava" width={40} height={40} />
              </div>
              <div>
                <CardText tag="h6" className="fw-bold text-primary">
                  Alur Transaksi
                </CardText>
                <CardText tag="p" className="m-0 fw-light">
                  Tagihan pinjaman disetujui
                </CardText>
                <CardText className="fw-light" tag="p">
                  {`(${moment(row?.createdAt).format('Do MMMM YYYY, hh:mm')})`}
                </CardText>
              </div>
            </div>
          </Card>
          {/* penjual */}
          <Card body className="mb-3">
            <CardText tag="h6" className="fw-bold">
              Tagihan dari
            </CardText>
            <div className="d-flex flex-column justify-content-start gap-1">
              <span>PT PasarMIKRO Global</span>
              <small className="fw-light">
                Mewakili toko: {row?.sellerTokoName}
              </small>
            </div>
          </Card>
          {/* Rincian Pesanan */}
          <Card body className="mb-3">
            <CardText tag="h6" className="fw-bold">
              Rincian Pesanan
            </CardText>
            <div className="gap-1">
              {row?.escrowpayment?.order?.orderitem?.map((e, i) => {
                return (
                  <div
                    key={i}
                    className="d-flex justify-content-between align-items-center"
                  >
                    <div className="d-flex flex-column mb-2">
                      <span>{e?.item?.nameItem}</span>
                      <span>
                        <span className="fw-light">
                          {e?.amount} {e?.item?.unit ?? e?.unit} •{' '}
                        </span>{' '}
                        <span className="text-primary">
                          Rp{' '}
                          {intl.formatNumber(
                            e?.sellPriceBeforeVAT / e?.amount,
                            {
                              useGrouping: 'always',
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 0,
                            },
                          )}
                        </span>
                      </span>
                    </div>
                    <span>
                      Rp
                      {intl.formatNumber(e?.sellPriceBeforeVAT ?? 0, {
                        useGrouping: 'always',
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}
                    </span>
                  </div>
                )
              })}
              <hr></hr>
              <div className="d-flex justify-content-between align-items-center">
                <p>Subtotal</p>
                <p>
                  Rp
                  {intl.formatNumber(subtotal, {
                    useGrouping: 'always',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                </p>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <p>Biaya transaksi</p>
                <p>
                  Rp
                  {intl.formatNumber(feeTx, {
                    useGrouping: 'always',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                </p>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <span>PPN</span>
                <span>
                  Rp
                  {intl.formatNumber(vat, {
                    useGrouping: 'always',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                </span>
              </div>
              <hr></hr>
              <div className="d-flex justify-content-between align-items-center">
                <p>Total transaksi</p>
                <p>
                  Rp
                  {intl.formatNumber(totalAmountToPay, {
                    useGrouping: 'always',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                </p>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <span>Pembayaran diawal</span>
                <span>
                  Rp
                  {intl.formatNumber(paidAmout, {
                    useGrouping: 'always',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                </span>
              </div>
              <hr></hr>
              <div className="">
                <div className="d-flex justify-content-between align-items-center">
                  <p className="fw-bold">Tagihan talangin</p>
                  <p className="fw-bold">
                    Rp
                    {intl.formatNumber(amountToPay, {
                      useGrouping: 'always',
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
                  </p>
                </div>
                {row?.status === 'PAIDOFF' && (
                  <div className="d-flex justify-content-between align-items-center">
                    <p>Pembayaran tagihan</p>
                    <p>
                      Rp
                      {intl.formatNumber(amountToPay, {
                        useGrouping: 'always',
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}
                    </p>
                  </div>
                )}
              </div>
              {row?.status === 'PAIDOFF' && (
                <>
                  <hr></hr>
                  <div className="d-flex justify-content-between align-items-center">
                    <p>Sisa Tagihan</p>
                    <p>
                      Rp
                      {intl.formatNumber(amountToPay, {
                        useGrouping: 'always',
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}
                    </p>
                  </div>
                </>
              )}
            </div>
          </Card>
          {/* invoice */}
          <Card body className="mb-3">
            <CardTitle className="fw-bold">Proforma invoice</CardTitle>
            <Button
              onClick={() => {
                downloadAsPdf(row, row?.invoice?.pmToBuyer?.download, 'INVF')
              }}
              color="dark"
              outline
            >
              <FileText /> Unduh Proforma Invoice
            </Button>
          </Card>
          {/* rollover */}
          <Card
            body
            className="mb-3"
            style={{
              backgroundColor:
                row?.status !== 'DISBURSED' ||
                row?.previousUserPinjamanId ||
                new Date(row?.dueDate).setHours(0, 0, 0, 0) <
                  new Date(today).setHours(0, 0, 0, 0)
                  ? '#F9FAFB'
                  : '',
            }}
          >
            <CardTitle>
              {new Date(row?.dueDate).setHours(0, 0, 0, 0) <
              new Date(today).setHours(0, 0, 0, 0)
                ? 'Anda tidak dapat memperpanjang tempo pembayaran'
                : row?.previousUserPinjamanId
                  ? 'Anda sudah memperpanjang tempo pembayaran.'
                  : 'Anda dapat memperpanjang tempo pembayaran.'}
            </CardTitle>
            <Button
              style={{ backgroundColor: 'white', color: 'black' }}
              onClick={() => {
                navigate('/perpanjang-tempo-pembayaran')
              }}
              disabled={
                row?.status !== 'DISBURSED' ||
                row?.previousUserPinjamanId ||
                new Date(row?.dueDate).setHours(0, 0, 0, 0) <
                  new Date(today).setHours(0, 0, 0, 0)
              }
            >
              Perpanjang
            </Button>
          </Card>
          {row?.status === 'DISBURSED' && (
            <Button
              block
              color="primary"
              size="sm"
              onClick={() => {
                toggleModal()
              }}
            >
              Bayar Tagihan
            </Button>
          )}
        </Container>
      </SidebarDetails>
    </>
  )
}

export default SidebarTalanginInvoice
