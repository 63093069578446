import { Card, CardHeader, CardText, Container, Nav, NavItem } from 'reactstrap'
import { NavLink as NavLinkDom, Outlet } from 'react-router-dom'
import { useFetchSettingsQuery } from './settingApiSlice'
import { Can } from 'context/Acl'
import { settingRoutes } from 'routes/settingRoutes'

const SettingView = () => {
  const { data, isLoading, isSuccess } = useFetchSettingsQuery()
  return (
    <Container className="pb-4">
      <Card className="w-100 mt-4">
        <CardHeader className="border-bottom-0 bg-transparent">
          <CardText tag="h3" className="p-3">
            Pengaturan
          </CardText>
          <Nav>
            {settingRoutes.map((item, idx) => (
              <Can I={item.action} this={item.subject} key={idx}>
                <NavItem>
                  <NavLinkDom
                    to={item.path}
                    aria-current="page"
                    className={({ isActive }) => {
                      if (isActive) {
                        return `nav-link active-link`
                      } else {
                        return `nav-link`
                      }
                    }}
                  >
                    {item.name}
                  </NavLinkDom>
                </NavItem>
              </Can>
            ))}
          </Nav>
        </CardHeader>
        <Outlet context={[data, isLoading, isSuccess]} />
      </Card>
      <Card
        body
        className="border-0"
        style={{ backgroundColor: 'transparent' }}
      >
        <span className="text-xs text-center mb-1">
          Versi: {process.env.REACT_APP_VERSION}
        </span>
      </Card>
    </Container>
  )
}

export default SettingView
