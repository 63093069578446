import PropTypes from 'prop-types'
import { createContext } from 'react'
import { createMongoAbility } from '@casl/ability'
import { createContextualCan } from '@casl/react'
import { useSelector } from 'react-redux'

// ** Create Context
export const AbilityContext = createContext()

// ** Init Can Context
export const Can = createContextualCan(AbilityContext.Consumer)

export const AbilityProvider = ({ children }) => {
  const { abilityRole } = useSelector((s) => s.auth)
  const mergeRole = [...abilityRole]
  return (
    <AbilityContext.Provider value={createMongoAbility(mergeRole)}>
      {children}
    </AbilityContext.Provider>
  )
}

AbilityProvider.propTypes = {
  children: PropTypes.node,
}
