import { createAppSlice } from 'app/createAppSlice'
import { confirmTrx } from './requestTransactionAction'

const initialState = {
  approvedAt: null,
  canceledAt: null,
  createdAt: null,
  isLoading: false,
  isApproved: false,
  linkApproved: '',
}

export const requestTransactionSlice = createAppSlice({
  name: 'purchase/commodity',
  initialState,
  reducers: (create) => ({}),
  extraReducers: (builder) => {
    builder
      .addCase(confirmTrx.pending, (state) => {
        state.isLoading = true
      })
      .addCase(confirmTrx.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.isApproved = true
        state.linkApproved = payload.link
      })
      .addCase(confirmTrx.rejected, (state, { payload }) => {
        state.isLoading = false
        state.isApproved = false
        state.linkApproved = ''
      })
  },
})

export default requestTransactionSlice.reducer
