import { Button } from 'reactstrap'
import PropTypes from 'prop-types'

const ButtonIcon = ({ btnTitle, icon, btnTitleProps, btnProps, children }) => {
  return (
    <Button
      color="primary"
      outline
      className="flex-centered w-100"
      {...btnProps}
    >
      {icon && <span className="me-2">{icon}</span>}
      <span className="fw-medium" {...btnTitleProps}>
        {btnTitle}
      </span>
      {children && children}
    </Button>
  )
}

ButtonIcon.propTypes = {
  btnTitle: PropTypes.string,
  icon: PropTypes.object,
  children: PropTypes.node,
}

export default ButtonIcon
